import React from 'react';
import Thumbnail from '../../../../partials/Thumbnail/Thumbnail';
import { InviteeDTO, MemberDTO } from '../../../../../common/api/dtos/Member';
import ContextMenu from '../../../../controls/ContextMenu/ContextMenu';
import MemberMenu from '../../../../menus/Member/MemberMenu';
import { DEFAULT_AVATAR } from '../../../../../common/configs/appDefaults';
import RemoveMemberMenu from './partial/RemoveMemberMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  members: (MemberDTO | InviteeDTO)[];
  cardId: string;
  assigneeIds: string[];
  isArchived?: boolean;
}

class BoardCardMembers extends React.Component<Props> {
  getMember = (memberId: string) => {
    if (this.props.members.length > 0) {
      const members = [...this.props.members];
      const member = members.find(
        (member: MemberDTO | InviteeDTO) => member.id === memberId,
      )!;
      const updatedMember = {
        ...member,
        avatar: member ? (member.avatar ? member.avatar : DEFAULT_AVATAR) : '',
      };

      return updatedMember;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="flex-row fill">
        <div className="column py-0 flex-v-center">
          <ul className="control-list-component icon-list-component align-h-end">
            {this.props.assigneeIds &&
              this.props.assigneeIds.length > 0 &&
              this.props.assigneeIds.map((member) => {
                const memberObj = this.getMember(member)!;
                return (
                  // TODO: Change key to id
                  <li
                    className="mb-0"
                    key={member}
                  >
                    <ContextMenu
                      dept={0}
                      contextId={member}
                      contextMenuClassName="align-h-start"
                      triggerClassDefault="ghost-button"
                      triggerClassActive="secondary-button"
                      title={memberObj.name}
                      isDisabled={Boolean(this.props.isArchived)}
                      triggerContent={
                        <>
                          <Thumbnail
                            classes="thumbnail size-24 pe-none"
                            avatarData={memberObj.avatar}
                            title={memberObj.name}
                          />
                        </>
                      }
                      rightClickTrigger={true}
                    >
                      <RemoveMemberMenu
                        cardId={this.props.cardId}
                        memberId={memberObj.id}
                        memberName={memberObj.name}
                      />
                    </ContextMenu>
                  </li>
                );
              })}
            {!this.props.isArchived && (
              <li className="mb-0">
                <ContextMenu
                  dept={0}
                  contextId={this.props.cardId}
                  contextMenuClassName="align-h-start"
                  triggerClassDefault="ghost-button"
                  triggerClassActive="secondary-button"
                  title={t('assignUsers')}
                  isDisabled={Boolean(this.props.isArchived)}
                  triggerContent={
                    <>
                      <span className="fal fa-user-plus"></span>
                    </>
                  }
                >
                  <MemberMenu
                    manage={false}
                    selected={this.props.assigneeIds}
                    cardId={this.props.cardId}
                    userSearchOff={true}
                  />
                </ContextMenu>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('boardCardMembers')(BoardCardMembers);
