import { TagDTO } from '../dtos/Tag';
import requests from './baseRequest';

export async function addBoardTag(
  boardTagData: TagDTO,
  boardId: string,
): Promise<TagDTO> {
  const result = await requests.post(`board/${boardId}/tag`, {
    name: boardTagData.name,
    color: boardTagData.color,
  });
  return await result.json();
}

export async function updateBoardTag(boardTagData: TagDTO): Promise<TagDTO> {
  const result = await requests.patch(`tag/${boardTagData.id}`, {
    name: boardTagData.name,
    color: boardTagData.color,
  });
  return await result.json();
}

export async function removeBoardTag(tagId: string): Promise<void> {
  await requests.delete(`tag/${tagId}`);
}
