import { ValidationError } from 'joi';

export function processJoiFieldError(error: ValidationError, field: string) {
  let errorMessage = '';

  for (const err of error.details) {
    const key = err.path[0];

    // ignore errors for other fields; this approach is chosen over extracting a sub-schema
    // because we use a joi ref for the confirm password
    if (key === field) {
      errorMessage = err.message.replace(/['"]+/g, '');
    }
  }

  return errorMessage;
}
