import { AddPriorityDTO, PriorityDTO } from '../dtos/Priority';
import requests from './baseRequest';

export async function createBoardPriority(
  boardId: string,
  priorityData: AddPriorityDTO,
): Promise<PriorityDTO> {
  const result = await requests.post(`board/${boardId}/priority`, priorityData);
  return await result.json();
}

export async function reorderPriority(
  priorityId: string,
  index: number,
): Promise<void> {
  await requests.patch(`priority/${priorityId}/move`, { index });
}

export async function updatePriority(
  priority: PriorityDTO,
): Promise<PriorityDTO> {
  const result = await requests.patch(`priority/${priority.id}`, {
    name: priority.name,
    color: priority.color,
  });
  return await result.json();
}

export async function deletePriority(id: string): Promise<void> {
  await requests.delete(`priority/${id}`);
}
