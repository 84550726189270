import { IRole } from '../interfaces/Role';

export const ROLE_OPTIONS: IRole[] = [
  {
    value: 'owner',
    label: 'Owner',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'member',
    label: 'Member',
  },
];
