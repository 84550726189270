import React from 'react';
import BoardContext, {
  IBoardContext,
} from '../../../../../../common/contexts/BoardContext';
import { removeMemberFromCard } from '../../../../../../common/api/endpoints/card';
import { showErrorNotifications } from '../../../../../../common/helpers/showNotifications';
import { withContextAdapters } from '../../../../ContextAdapter/withContextAdapter';
import AppContext, {
  IAppContext,
  NotificationMessage,
} from '../../../../../../common/contexts/AppContext';
import { withStyledTranslation } from '../../../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface ExternalProps {
  cardId: string;
  memberId: string;
  memberName?: string;
}
interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  unassignMember: (cardId: string, memberId: string) => void;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

class RemoveMemberMenu extends React.Component<Props> {
  unassignMember = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await removeMemberFromCard(this.props.cardId, this.props.memberId);
      this.props.unassignMember(this.props.cardId, this.props.memberId);
    } catch (err) {
      console.log(err);
      showErrorNotifications(err, this.props.setMessages);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <li>
        <button
          className="ghost-button"
          onClick={this.unassignMember}
        >
          {t('remove')}{' '}
          {this.props.memberName ? `"${this.props.memberName}"` : 'member'}
        </button>
      </li>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      unassignMember: ctx.unassignMember,
    };
  },
};
export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('removeMemberMenu')(RemoveMemberMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
