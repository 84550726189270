import { LoggedUser } from '../contexts/AppContext';
import { normalizeOnboardingData } from './normalizeOnboardingData';

type TOnboarding = {
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  organizingWith: 'justMe' | 'team' | undefined;
  peopleCount: number;
  goal: 'personal' | 'work' | undefined;
  boardName: string;
  columns: string[];
  tasks: string[];
  emails: string[];
};

export function selectWelcome(loggedUser: LoggedUser) {
  const onboarding: TOnboarding = normalizeOnboardingData(
    loggedUser.onboarding,
  );

  if (typeof onboarding.familiarity === 'undefined') {
    return 'invited';
  }

  const familiarity = onboarding.familiarity;
  const organizingWith = onboarding.organizingWith;
  const goal = onboarding.goal;

  return { familiarity, organizingWith, goal };
}
