import { ErrorNotificationPayload } from '../../helpers/errorNotificationPayload';

export async function createResponseError(response: Response) {
  if (response.ok) {
    throw new Error(
      `Expected a 4xx or 5xx status code, got ${response.status}`,
    );
  }

  let data;
  try {
    data = await response.json();
  } catch (e) {
    return [createUnkownError()];
  }

  if (typeof data === 'object' && data.errors) {
    return data.errors.map((element: any) => {
      if (typeof element === 'object' && element.code) {
        return new ErrorNotificationPayload(
          element.code,
          element.path && element.path.length
            ? element.path.join('.')
            : undefined,
          element.validationCode ? element.validationCode : undefined,
          element.validationMessage ? element.validationMessage : undefined,
          element.message ? element.message : undefined,
        );
      }
      return createUnkownError();
    });
  }
  return [createUnkownError()];
}

function createUnkownError() {
  return new ErrorNotificationPayload(
    'unkown',
    'An unknown server error has occured.',
  );
}
