import { TFunction } from 'i18next';

export const customPasswordValidation = (t: TFunction, value: string) => {
  const errors = [];

  // Check for length
  if (value.length < 8) {
    errors.push({
      message: t('registration:useEightCharacters'),
      isValid: false,
    });
  } else {
    errors.push({
      message: t('registration:useEightCharacters'),
      isValid: true,
    });
  }

  // Check for both upper and lower case letters
  if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
    errors.push({
      message: t('registration:useUpperAndLowerCaseLetters'),
      isValid: false,
    });
  } else {
    errors.push({
      message: t('registration:useUpperAndLowerCaseLetters'),
      isValid: true,
    });
  }

  // Check for at least one digit
  if (!/\d/.test(value)) {
    errors.push({
      message: t('registration:useANumber'),
      isValid: false,
    });
  } else {
    errors.push({
      message: t('registration:useANumber'),
      isValid: true,
    });
  }

  // Check for at least one special character from the comprehensive list
  const specialCharacters = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;
  if (!specialCharacters.test(value)) {
    errors.push({
      message: t('registration:useASymbol'),
      isValid: false,
    });
  } else {
    errors.push({
      message: t('registration:useASymbol'),
      isValid: true,
    });
  }

  return errors;
};
