import { ChangeEvent, Component, FormEvent } from 'react';
import AppContext from '../../../../common/contexts/AppContext';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import TextBox from '../../../controls/TextBox/TextBox';
import { withTransitionEvent } from '../../../partials/Transition/TransitionEvent';
import Joi from 'joi';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import Button from '../../../controls/Button/Button';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import NumberControl from '../../../controls/NumberControl/NumberControl';

interface FormData {
  name: string;
  seats: number;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

type InputValue = ChangeEvent<HTMLInputElement> | number;

interface Props extends RouteComponentProps {}

interface State {
  formStatus: TRequestStatus;
  formData: FormData;
  formErrors: FormErrors;
}

class TeamAdd extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string()
      .pattern(/^[a-zA-Z0-9 ]+$/)
      .required()
      .trim(true)
      .messages({
        'string.pattern.base': FormErrorMsgs.teamName['string.pattern.base'],
        'string.empty': FormErrorMsgs.string['string.empty'],
        'any.required': FormErrorMsgs.string['any.required'],
      }),
    seats: Joi.number()
      .required()
      .min(2)
      .max(1000)
      .messages(FormErrorMsgs.number),
  });

  constructor(props: Props) {
    super(props);

    this.state = {
      formStatus: 'idle',
      formData: {
        name: '',
        seats: 2,
      },
      formErrors: {},
    };
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          [field]: value,
        },
      },
      () => {
        this.validateFormField(field);
      },
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('name', ev.target.value);

  setSeats = (value: InputValue) => {
    const seatsValue =
      typeof value === 'number' ? value : Number(value.target.value);
    this.updateForm('seats', seatsValue);
  };

  handleSubmit = async (e: FormEvent) => {
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      try {
        this.setState({
          formStatus: 'success',
        });
      } catch (err) {
        this.setState(
          {
            formStatus: 'error',
          },
          () => {
            showErrorNotifications(err, this.context.notifications.setMessages);
          },
        );
      }
    } else {
      e.preventDefault();
    }
  };

  render() {
    return (
      <div className="flex-row">
        <div className="column pt-0 large">
          <div className="flex-row fill">
            <div className="column pb-xs">
              <ul className="control-list-component">
                <li>
                  <NavLink
                    to="/"
                    exact
                    className={'secondary-button'}
                  >
                    <span className="fas fa-angle-left"></span>
                    <span className="sr-only">Back</span>
                  </NavLink>
                </li>
                <li>
                  <h1 className="primary-title h3 normalcase">
                    Creating team <strong>{this.state.formData.name}</strong>
                  </h1>
                </li>
              </ul>
            </div>
          </div>
          <form
            onSubmit={(e) => this.handleSubmit(e)}
            autoComplete="off"
            noValidate={true}
            method="POST"
            action={`${process.env.REACT_APP_API_BASE_URL}/team`}
          >
            <div className="flex-row">
              <div className="column large pt-0">
                <div className="card">
                  <fieldset>
                    <legend>General Information</legend>
                    <p>
                      Set a name for your team. This name can be changed later
                      on.
                    </p>
                    <TextBox
                      label="Name"
                      type="text"
                      name="name"
                      id="name"
                      maxLength={255}
                      disabled={this.state.formStatus === 'loading'}
                      onChange={this.setName}
                      value={this.state.formData.name}
                      required={true}
                      br={true}
                      placeholder="My awesome team"
                      onBlur={(ev) =>
                        this.validateFormField(ev.target.name as keyof FormData)
                      }
                      error={this.state.formErrors.name}
                    />
                    <NumberControl
                      label="Seats (min. 2)"
                      name="seats"
                      id="seats"
                      minValue={2}
                      maxValue={1000}
                      placeholder="Number of seats"
                      disabled={this.state.formStatus === 'loading'}
                      onChange={this.setSeats}
                      onBlur={(ev) =>
                        this.validateFormField(ev.target.name as keyof FormData)
                      }
                      value={this.state.formData.seats}
                      required={true}
                      br={true}
                      error={this.state.formErrors.seats}
                    />
                  </fieldset>
                  <Button
                    className="primary-button mt-sm"
                    type="submit"
                    disabled={this.state.formStatus === 'loading'}
                  >
                    <RequestStatus status={this.state.formStatus} />
                    <span className="text">Select billing options</span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTransitionEvent(TeamAdd);
TeamAdd.contextType = AppContext;
