import { TThumbnails } from '../types/Thumbnails';

export const THUMBNAILS: TThumbnails[] = [
  {
    value: '',
    label: '',
  },
  {
    value: 'apple.webp',
    label: 'apple',
  },
  {
    value: 'strawberry.webp',
    label: 'strawberry',
  },
  {
    value: 'heart.webp',
    label: 'heart',
  },
  {
    value: 'watermelon.webp',
    label: 'watermelon',
  },
  {
    value: 'orange.webp',
    label: 'orange',
  },
  {
    value: 'cat.webp',
    label: 'cat',
  },
  {
    value: 'rainbowbook.webp',
    label: 'rainbowbook',
  },
  {
    value: 'coffee.webp',
    label: 'coffee',
  },
  {
    value: 'book.webp',
    label: 'book',
  },
  {
    value: 'mug.webp',
    label: 'mug',
  },
  {
    value: 'notebook.webp',
    label: 'notebook',
  },
  {
    value: 'dog.webp',
    label: 'dog',
  },
  {
    value: 'star.webp',
    label: 'star',
  },
  {
    value: 'stapler.webp',
    label: 'stapler',
  },
  {
    value: 'butterfly.webp',
    label: 'butterfly',
  },
  {
    value: 'suitcase.webp',
    label: 'suitcase',
  },
  {
    value: 'screen.webp',
    label: 'screen',
  },
  {
    value: 'flower.webp',
    label: 'flower',
  },
  {
    value: 'rainbow.webp',
    label: 'rainbow',
  },
  {
    value: 'parrot.webp',
    label: 'parrot',
  },
];
