import React, { ChangeEvent, Component } from 'react';
import AppContext, * as Context from '../../../common/contexts/AppContext';
import ToggleSwitch from '../../controls/ToggleSwitch/ToggleSwitch';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';

type Props = WithTranslation & {
  history: any;
};

class Experiments extends Component<Props> {
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const loggedUserId = this.context.loggedUser?.id;
    if (loggedUserId) {
      const storedExperiments = this.getStoredExperiments();
      this.context.loggedUser?.setLocalSettings({
        ...this.context.loggedUser.localSettings,
        experiments: {
          ...this.context.loggedUser.localSettings.experiments,
          ...storedExperiments,
        },
      });
    }
  }

  setExperiment =
    (experiment: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const loggedUserId = this.context.loggedUser?.id;
      if (!loggedUserId) return;

      const experiments =
        this.context.loggedUser?.localSettings.experiments || {};
      if (experiments[experiment] !== event.target.checked) {
        experiments[experiment] = event.target.checked;

        const userExperiments = JSON.parse(
          localStorage.getItem('userExperiments') || '{}',
        );

        userExperiments[loggedUserId] = experiments;
        localStorage.setItem(
          'userExperiments',
          JSON.stringify(userExperiments),
        );

        this.context.loggedUser?.setLocalSettings({ experiments });
      }
    };

  getStoredExperiments = () => {
    const loggedUserId = this.context.loggedUser?.id;
    if (!loggedUserId) return {};

    const userExperiments = JSON.parse(
      localStorage.getItem('userExperiments') || '{}',
    );
    return userExperiments[loggedUserId] || {};
  };

  render() {
    const { t } = this.props;
    const loggedUser = this.context.loggedUser!;
    const localSettings = loggedUser.localSettings;

    const experiments = localSettings.experiments!;

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-2xs">
            <ToggleSwitch
              id="detachableFlyout"
              name="detachableFlyout"
              changeMethod={this.setExperiment('detachableFlyout')}
              isChecked={experiments.detachableFlyout}
              multiline={true}
              tight={true}
              isDisabled={false}
            >
              <label htmlFor="detachableFlyout">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column py-0">
                    <span className="link-text fal fa-book-spells icon"></span>
                  </div>
                  <div className="column py-0">
                    <span>Detachable card flyout</span> <br />
                    <small className="faint-text">
                      Drag and detach card flyout with the option to position it
                      where you like.
                    </small>
                  </div>
                  <span className="state">
                    {experiments.detachableFlyout ? 'On' : 'Off'}
                  </span>
                </div>
              </label>
            </ToggleSwitch>
            <ToggleSwitch
              id="showTranslucency"
              name="showTranslucency"
              changeMethod={this.setExperiment('showTranslucency')}
              isChecked={experiments.showTranslucency}
              multiline={true}
              tight={false}
              isDisabled={false}
            >
              <label htmlFor="showTranslucency">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column py-0">
                    <span className="link-text fal fa-ghost icon"></span>
                  </div>
                  <div className="column py-0">
                    <span>Translucency effects</span> <br />
                    <small className="faint-text">
                      Make the board cards translucent thus creating a board
                      ambient.
                    </small>
                  </div>
                  <span className="state">
                    {experiments.showTranslucency ? 'On' : 'Off'}
                  </span>
                </div>
              </label>
            </ToggleSwitch>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('Experiments')(Experiments);
Experiments.contextType = AppContext;
