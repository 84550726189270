import React, { ChangeEvent, Component, FormEvent } from 'react';
import Joi from 'joi';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import TextBox from '../../../controls/TextBox/TextBox';
import Button from '../../../controls/Button/Button';
import { restoreBoard } from '../../../../common/api/endpoints/board';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import { deleteBoard } from '../../../../common/api/endpoints/board';
import ErrorList from '../../../error/ErrorList/ErrorList';

interface FormData {
  boardName: string;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

interface Props {
  id: string;
  name: string;
  description: string;
  removeFromArchive: (id: string) => void;
  owned?: boolean;
}

interface State {
  showDelete: boolean;
  formData: FormData;
  formErrors: FormErrors;
  restoreStatus: TRequestStatus;
  deleteStatus: TRequestStatus;
  serverErrors: string[];
}

class BoardCardArchived extends Component<Props, State> {
  formSchema = Joi.object({
    boardName: Joi.string()
      .required()
      .equal(this.props.name)
      .label('Board name does not match')
      .trim(true)
      .messages(FormErrorMsgs.string),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      restoreStatus: 'idle',
      deleteStatus: 'idle',
      showDelete: false,
      formData: {
        boardName: '',
      },
      formErrors: {},
      serverErrors: [],
    };
  }

  toggleShowDelete = () => {
    this.setState((prevState) => {
      return {
        showDelete: !prevState.showDelete,
      };
    });
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors,
      });

      return false;
    }

    return true;
  };

  validateFormField = (field: string) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  updateFormError<K extends keyof FormData>(field: string, value: FormData[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          [field]: value,
        },
      },
      () => {
        this.validateFormField(field);
      },
    );
  }

  setBoardName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('boardName', ev.target.value);

  handleRestore = async () => {
    this.setState({
      restoreStatus: 'loading',
    });

    try {
      await restoreBoard(String(this.props.id));

      this.setState({
        restoreStatus: 'success',
      });

      this.props.removeFromArchive(this.props.id);
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];

      this.setState({
        restoreStatus: 'error',
        serverErrors: error,
      });
    }
  };

  handleDeleteBoard = async (e: FormEvent) => {
    e.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        deleteStatus: 'loading',
      });

      try {
        await deleteBoard(String(this.props.id));

        this.setState({
          deleteStatus: 'success',
        });

        this.props.removeFromArchive(this.props.id);
      } catch (err) {
        const error = Array.isArray(err) ? err : [err];

        this.setState({
          deleteStatus: 'error',
          serverErrors: error,
        });
      }
    }
  };

  handleRestoreBoard = async () => {
    try {
      await restoreBoard(String(this.props.id));
      this.props.removeFromArchive(this.props.id);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const disableConfirmButton =
      this.state.formData.boardName !== this.props.name ||
      this.state.deleteStatus === 'loading';

    return (
      <div className="card">
        <div>
          <div className="flex-row fill">
            <div className="column pt-0 pb-2xs">
              <h3>{this.props.name}</h3>
            </div>
          </div>
          <div className="flex-row fill">
            <div className="column pt-0">
              <p className="faint-text multiline-ellipsis l2">
                {this.props.description}
              </p>
            </div>
          </div>
          <div className="flex-row fill">
            <div className="column py-0">
              {this.state.showDelete ? (
                <>
                  <form
                    onSubmit={this.handleDeleteBoard}
                    noValidate={true}
                  >
                    <TextBox
                      label="Board name"
                      type="text"
                      name="boardName"
                      id="boardName"
                      onChange={this.setBoardName}
                      required={true}
                      br={true}
                      error={this.state.formErrors.boardName}
                      autofocus={true}
                      autoComplete="off"
                      ariaAutocomplete="none"
                    />
                    <ul className="control-list-component">
                      <li>
                        <Button
                          className="secondary-button"
                          type="submit"
                          disabled={disableConfirmButton}
                        >
                          <RequestStatus status={this.state.deleteStatus} />
                          <span className="negative-text fas fa-exclamation-circle icon">
                            <span className="sr-only">Danger</span>
                          </span>
                          <span className="text">Permanently delete</span>
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="ghost-button"
                          type="button"
                          onClick={this.toggleShowDelete}
                        >
                          Cancel deletion
                        </Button>
                      </li>
                    </ul>
                  </form>
                </>
              ) : (
                <ul className="control-list-component">
                  <li>
                    <Button
                      className="primary-button"
                      type="button"
                      onClick={this.handleRestoreBoard}
                      disabled={
                        this.state.restoreStatus === 'loading' ||
                        !this.props.owned
                      }
                    >
                      <RequestStatus status={this.state.restoreStatus} />
                      <span className="text">Restore board</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="ghost-button"
                      type="button"
                      onClick={this.toggleShowDelete}
                      disabled={
                        this.state.restoreStatus === 'loading' ||
                        !this.props.owned
                      }
                    >
                      Delete board
                    </Button>
                  </li>
                </ul>
              )}
              <ErrorList errors={this.state.serverErrors} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BoardCardArchived;
