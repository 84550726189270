import React from 'react';
import Button from '../../controls/Button/Button';
import { CARD_DATA_LABELS } from '../../../common/configs/CardDataLabels';
import { TCardData } from '../../../common/types/CardData';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  setSelectedCardData?: (generator: TCardData) => void;
  selectedCardData?: TCardData[];
}

interface State {
  cardData: TCardData[];
}

class CardDataMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cardData: Object.keys(CARD_DATA_LABELS) as TCardData[],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <li>
          <small>{this.props.t('showOnly')}</small>
        </li>
        {this.state.cardData.map((cardData: TCardData) => {
          const isActive = this.props.selectedCardData?.includes(cardData);

          return (
            <li
              className={[isActive ? 'active' : ''].join(' ')}
              key={cardData}
            >
              <Button
                className="ghost-button"
                value={cardData}
                onClick={() => this.props.setSelectedCardData!(cardData)}
              >
                <span className="text capitalized">
                  {t(CARD_DATA_LABELS[cardData])}
                </span>
              </Button>
            </li>
          );
        })}
      </>
    );
  }
}

export default withStyledTranslation('cardDataMenu')(CardDataMenu);
