import React from 'react';
import { TContextAlignment } from '../../../common/types/ContextAlignment';
import { TContextMenu } from '../../../common/types/ContextMenu';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import CardOptions from './CardOptions';
import Restore from './Restore';
import { IRestoreColumn } from '../../../common/interfaces/RestoreColumn';
import BoardContext from '../../../common/contexts/BoardContext';

interface Props extends WithTranslation {
  contextMenuClassName: TContextAlignment;
  contextId?: string;
  triggerClassDefault: string;
  triggerClassActive: string;
  iconClass?: string;

  restoreColumnId: string | null;
  restoreColumns: IRestoreColumn[];
  setRestoreColumnId: (
    _: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<Element>,
    restoreColumnId: number | string | IRestoreColumn | undefined,
  ) => void;
  updatingCard: boolean;
  handleRestoreCard: () => void;
  toggleShowConfirmDelete: () => void;
}

interface State {
  selectedContext: TContextMenu;
}

class RestoreCardContext extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedContext: 'cardOptions',
    };
  }

  setSelectedContext = (context: TContextMenu) => {
    this.setState({
      selectedContext: context,
    });
  };

  evaluateDept = (): number => {
    switch (this.state.selectedContext) {
      case 'cardOptions':
        return 0;
      case 'restore':
        return 1;
      default:
        return 0;
    }
  };

  render() {
    const { t } = this.props;

    const contextComponents: { [key: string]: React.ReactElement } = {
      cardOptions: (
        <CardOptions
          directionalButton={{
            text: t('restoreCard'),
            disabled: !['admin', 'owner'].includes(
              this.context.board.user.role,
            ),
            direction: 'right',
            onClick: () => this.setSelectedContext('restore'),
          }}
          setSelectedContext={this.setSelectedContext}
          toggleShowConfirmDelete={this.props.toggleShowConfirmDelete}
        />
      ),
      restore: (
        <Restore
          directionalButton={{
            text: t('backToCardOptions'),
            direction: 'left',
            onClick: () => this.setSelectedContext('cardOptions'),
          }}
          restoreColumnId={this.props.restoreColumnId}
          restoreColumns={this.props.restoreColumns}
          setRestoreColumnId={this.props.setRestoreColumnId}
          updatingCard={this.props.updatingCard}
          handleRestoreCard={this.props.handleRestoreCard}
        />
      ),
    };

    return (
      <ContextMenu
        dept={this.evaluateDept()}
        resetSelectedContext={() => this.setSelectedContext('cardOptions')}
        contextMenuClassName={this.props.contextMenuClassName}
        triggerClassActive={this.props.triggerClassActive}
        triggerClassDefault={this.props.triggerClassDefault}
        title={t('cardOptions')}
        triggerContent={
          <>
            <span
              className={`${
                this.props.iconClass
                  ? this.props.iconClass
                  : 'fas fa-ellipsis-h icon'
              }`}
            ></span>
          </>
        }
      >
        {contextComponents[this.state.selectedContext]}
      </ContextMenu>
    );
  }
}

export default withStyledTranslation('boardArchivedCardFlyout')(
  RestoreCardContext,
);
RestoreCardContext.contextType = BoardContext;
