import { ListActivitiesDTO } from '../dtos/Activity';
import requests from './baseRequest';

export async function listActivities(
  cardId: string,
  nextPage?: string | null,
): Promise<ListActivitiesDTO> {
  const result = await requests.get(
    `card/${cardId}/activity${nextPage ? `?nextPage=${nextPage}` : ''}`,
  );
  return await result.json();
}
