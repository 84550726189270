import { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { withTransitionEvent } from '../../../partials/Transition/TransitionEvent';
import AccountGeneral from '../../../partials/Account/AccountGeneral';
import { UserDTO } from '../../../../common/api/dtos/User';
import MenuTabs from '../../../controls/MenuTabs/MenuTabs';
import Subscriptions from '../../../partials/Billing/Subscriptions';
import { listTeams } from '../../../../common/api/endpoints/team';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import Membership from '../../../partials/Team/Membership';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';

interface RouteParams {
  id?: string;
}

interface Form {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface RequestPayload {
  statusCode: number;
  message: string;
}

interface State {
  form: Form;
  formStatus: TRequestStatus;
  invalidPasswordMatch: boolean;
  formPayload: RequestPayload;
  status: TRequestStatus;
  teams: TeamDTO[];
}

export interface Props
  extends RouteComponentProps<RouteParams>,
    WithTranslation {
  setLoggedUser: (loggedUser: UserDTO) => void;
  loggedUser?: UserDTO;
}

class Account extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      status: 'loading',
      invalidPasswordMatch: true,
      formStatus: 'idle',
      formPayload: {
        statusCode: -1,
        message: '',
      },
      teams: [],
    };
  }

  componentDidMount() {
    this.fetchAllTeams();
  }

  fetchAllTeams = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      const data = await listTeams();

      this.setState({
        status: 'success',
        teams: data.teams,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name]: event.target.checked,
    } as any);
    localStorage.setItem(
      event.target.name,
      JSON.stringify(event.target.checked),
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className="flex-row fill">
        <div className="column pt-0">
          <div className="flex-row">
            <div className="column pb-xs">
              <h2 className="primary-title h3 normalcase">
                {t('accountTitle')}
              </h2>
            </div>
          </div>
          <MenuTabs
            parentUrl={this.props.match.url}
            vertical={true}
            tabs={[
              {
                id: 'general',
                label: t('generalTabLabel'),
                icon: 'fal fa-circle',
                content: (
                  <AccountGeneral
                    setLoggedUser={this.props.setLoggedUser}
                    loggedUser={this.props.loggedUser!}
                    history={this.props.history}
                  />
                ),
              },
              {
                id: 'plans',
                label: t('plansTabLabel'),
                icon: 'fal fa-coins',
                content: <Subscriptions history={this.props.history} />,
                disabled: false,
                // iconGradient: 'linear-gradient(to top left, #ffde00, #ff6600)',
              },
              {
                id: 'membership',
                label: t('membershipTabLabel'),
                icon: 'fal fa-id-badge',
                content: <Membership history={this.props.history} />,
              },
            ]}
            defaultTab="general"
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('account')(
  withTransitionEvent(Account, {}),
);
