import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/ro';

const storedLanguage = localStorage.getItem('language') || 'en_US';
const languageCode = storedLanguage.split('_')[0]; // Extracts "en" from "en_US"
const dayjsHelper = dayjs;

dayjsHelper.extend(advancedFormat);
dayjsHelper.extend(localizedFormat);
dayjsHelper.extend(localeData);
dayjsHelper.locale(languageCode);

export default dayjsHelper;
