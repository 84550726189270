import { IClientData } from '../interfaces/ClientData';
import { getChecklistItems } from '../../components/partials/Onboarding/Tutorial/tutorialData';
import { OnboardDTO } from '../api/dtos/User';

function getProgressFromClientData(
  onBoardingData: OnboardDTO,
  clientData: IClientData,
  tutorialType: string,
): { [id: string]: { quantity: number; completed?: boolean } } {
  const defaultSteps = getChecklistItems(tutorialType).reduce((acc, step) => {
    let initialQuantity = step.quantity;

    if (step.id === 'create_first_board' || step.id === 'setup_team_board') {
      initialQuantity = onBoardingData.boardName ? 1 : 0;
    } else if (step.id === 'create_cards' || step.id === 'add_cards') {
      initialQuantity = onBoardingData.cardNames
        ? onBoardingData.cardNames.length
        : 0;
    }

    acc[step.id] = {
      quantity: initialQuantity,
      completed: initialQuantity >= step.requiredQuantity,
    };
    return acc;
  }, {} as { [id: string]: { quantity: number; completed?: boolean } });

  const tutorialKey = `tutorial_${tutorialType}`;
  const clientDataForTutorial = clientData[tutorialKey as keyof IClientData];

  // Type guard to check if clientDataForTutorial is an object with steps
  if (
    clientDataForTutorial &&
    typeof clientDataForTutorial === 'object' &&
    'steps' in clientDataForTutorial
  ) {
    // Merge client data with defaults
    const mergedSteps = { ...defaultSteps, ...clientDataForTutorial.steps };
    return mergedSteps;
  }

  return defaultSteps;
}

export default getProgressFromClientData;
