import React, { ChangeEvent, Component } from 'react';

interface Props {
  id: string;
  name: string;
  checked: boolean;
  changeMethod: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  srOnly?: boolean;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
}
class CheckBox extends Component<Props> {
  render() {
    return (
      <>
        <input
          id={this.props.id}
          type="checkbox"
          name={this.props.name}
          disabled={this.props.disabled}
          onChange={this.props.changeMethod}
          className={this.props.className}
          onBlur={this.props.onBlur}
          checked={this.props.checked}
        />
        {this.props.label && (
          <label
            htmlFor={this.props.id}
            className={this.props.srOnly ? 'sr-only' : ''}
          >
            {this.props.label}
            {this.props.required ? '*' : ''}
          </label>
        )}
      </>
    );
  }
}

export default CheckBox;
