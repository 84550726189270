import React from 'react';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { TContextAlignment } from '../../../common/types/ContextAlignment';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { TContextActionTag } from '../../../common/types/Tag';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import TagDetailMenu from './TagDetailMenu';
import TagMenu from './TagMenu';
import TagOperationsMenu from './TagOperationsMenu';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  disabled: boolean;
  amount: number;
  setSelectedTags?: (tags: string[]) => void;
  cardId?: string;
  selectedTags: string[];
  contextMenuClassName: TContextAlignment;
  action: TContextActionTag;
  contextId?: string;
  triggerClassDefault: string;
  triggerClassActive: string;
  hideCounter?: boolean;
  iconClass?: string;
}

interface State {
  selectedContext: TContextMenu;
  selectedTag: TagDTO | null;
}

class TagContext extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedContext: 'tags',
      selectedTag: null,
    };
  }

  setSelectedContext = (context: TContextMenu) => {
    this.setState({
      selectedContext: context,
    });
  };

  setSelectedTag = (tag: TagDTO) => {
    this.setState({
      selectedTag: tag,
    });
  };

  evaluateDept = (): number => {
    switch (this.state.selectedContext) {
      case 'tags':
        return 0;
      case 'TagManageMenus':
        return 1;
      case 'editTag':
      case 'addTag':
        return 2;
      default:
        return 0;
    }
  };

  render() {
    const { t } = this.props;

    const contextComponents: { [key: string]: React.ReactElement } = {
      tags: (
        <TagMenu
          selected={this.props.selectedTags}
          setSelectedTags={this.props.setSelectedTags}
          cardId={this.props.cardId}
          directionalButton={{
            text: t('manageTags'),
            direction: 'right',
            onClick: () => this.setSelectedContext('TagManageMenus'),
          }}
          action={this.props.action}
        />
      ),
      TagManageMenus: (
        <TagOperationsMenu
          directionalButton={{
            text: t('backToTags'),
            direction: 'left',
            onClick: () => this.setSelectedContext('tags'),
          }}
          setSelectedContext={this.setSelectedContext}
          setSelectedTag={this.setSelectedTag}
        />
      ),
      addTag: (
        <TagDetailMenu
          directionalButton={{
            text: t('backToManageTags'),
            direction: 'left',
            onClick: () => this.setSelectedContext('TagManageMenus'),
          }}
          selectedContext={this.state.selectedContext}
          setSelectedContext={this.setSelectedContext}
          legendText={t('addTag')}
        />
      ),
      editTag: (
        <TagDetailMenu
          directionalButton={{
            text: t('backToManageTags'),
            direction: 'left',
            onClick: () => this.setSelectedContext('TagManageMenus'),
          }}
          selectedContext={this.state.selectedContext}
          setSelectedContext={this.setSelectedContext}
          selectedTag={this.state.selectedTag}
          legendText={t('editTag')}
        />
      ),
    };

    return (
      <ContextMenu
        dept={this.evaluateDept()}
        context={this.state.selectedContext}
        contextId={this.props.contextId}
        resetSelectedContext={() => this.setSelectedContext('tags')}
        contextMenuClassName={this.props.contextMenuClassName}
        triggerClassActive={this.props.triggerClassActive}
        triggerClassDefault={this.props.triggerClassDefault}
        forceLTR={true}
        title={t('assignTags')}
        isDisabled={this.props.disabled}
        triggerContent={
          <>
            {!this.props.hideCounter && (
              <span className="text">{this.props.amount}</span>
            )}
            <span
              className={`${
                this.props.iconClass ? this.props.iconClass : 'fas fa-tags icon'
              }`}
            ></span>
          </>
        }
      >
        {contextComponents[this.state.selectedContext]}
      </ContextMenu>
    );
  }
}

export default withStyledTranslation('tagContext')(TagContext);
