import Joi from 'joi';
import React, { MouseEvent } from 'react';
import Button from '../../controls/Button/Button';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import MemberManageMenu from './MemberManageMenu';
import MemberSearchMenu from './MemberSearchMenu';
import { searchBoardUsers } from '../../../common/api/endpoints/member';
import AppContext, {
  IAppContext,
  NotificationMessage,
} from '../../../common/contexts/AppContext';
import { InviteeDTO, MemberDTO } from '../../../common/api/dtos/Member';
import {
  addMemberToCard,
  removeMemberFromCard,
} from '../../../common/api/endpoints/card';
import { showErrorNotifications } from '../../../common/helpers/showNotifications';
import { DEFAULT_AVATAR } from '../../../common/configs/appDefaults';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import BoardContext, {
  IBoardContext,
} from '../../../common/contexts/BoardContext';
import { IBoardCurrentUser } from '../../../common/interfaces/BoardCurrentUser';
import { inviteMemberToBoard } from '../../../common/api/endpoints/member';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import selectTutorial from '../../../common/helpers/selectTutorial';
import { getChecklistItems } from '../../partials/Onboarding/Tutorial/tutorialData';
import { updateTutorialStep } from '../../../common/helpers/tutorialHelper';
import { UserDTO } from '../../../common/api/dtos/User';
import { IClientData } from '../../../common/interfaces/ClientData';
import { customEmailValidation } from '../../pages/Auth/Registration/helper/customEmailValidation';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  updateClientData: (clientData: Partial<IClientData>) => void;
  loggedUser: UserDTO;
}
interface BoardContextProps {
  boardId: string;
  currentBoardUserRole: string;
  members: (MemberDTO | InviteeDTO)[];
  loadingMemberIds: string[];
  currentBoardUser: IBoardCurrentUser;
  setLoadingMemberIds: (tags: string[]) => void;
  assignMember: (cardId: string, memberId: string) => void;
  unassignMember: (cardId: string, memberId: string) => void;
  addMember: (member: MemberDTO | InviteeDTO) => void;
}
interface ExternalProps {
  manage: boolean; //helps to decide if we can change a member prop (like role) or just render the list of members to add them to a card
  onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
  selected?: string[];
  setSelectedMembers?: (members: string[]) => void;
  cardId?: string;
  userSearchOff?: boolean;
  focusableItem?: HTMLElement;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

interface State {
  memberQuery: string;
  isValidEmail: boolean;
  members: (MemberDTO | InviteeDTO)[];
  isValidHandle: boolean;
  searchUsers: (MemberDTO | InviteeDTO)[] | null;
  pagination: Pagination;
  isValidEmailMember: boolean;
  isValidHandleMember: boolean;
  status: TRequestStatus;
}

interface Pagination {
  step: number;
  currentStep: number;
}

class MemberMenu extends React.Component<Props, State> {
  static contextType = AppContext;
  emailSchema = Joi.string()
    .required()
    .trim(true)
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .custom(customEmailValidation);

  constructor(props: Props) {
    super(props);
    this.state = {
      memberQuery: '',
      isValidEmail: false,
      members: [],
      isValidHandle: false,
      searchUsers: null,
      pagination: {
        step: 5,
        currentStep: 0,
      },
      isValidEmailMember: false,
      isValidHandleMember: false,
      status: 'idle',
    };
  }

  componentDidMount(): void {
    this.setState({
      members: this.props.members,
    });

    if (this.state.memberQuery.length > 2) {
      this.searchMembers();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.memberQuery !== this.state.memberQuery) {
      if (this.state.memberQuery.length <= 2) {
        this.setState({
          searchUsers: null,
        });
      }
    }
  }

  setIsValidEmail = () => {
    const result = this.emailSchema.validate(this.state.memberQuery, {
      abortEarly: false,
    });

    this.setState({
      isValidEmail:
        this.props.currentBoardUserRole === 'owner' ||
        this.props.currentBoardUserRole === 'admin'
          ? !Boolean(result.error)
          : false,
      isValidEmailMember:
        this.props.currentBoardUserRole === 'member'
          ? !Boolean(result.error)
          : false,
    });
  };

  setMemberQuery = (value: string) => {
    this.setState(
      {
        memberQuery: value,
      },
      () => {
        this.setIsValidEmail();
        if (this.state.memberQuery.length > 2) {
          this.searchMembers();
        }
      },
    );
  };

  searchMembers = async () => {
    try {
      const search = await searchBoardUsers(
        this.props.boardId,
        this.state.memberQuery,
      );
      let searchMembers: (MemberDTO | InviteeDTO)[] = [];

      if (search.hasOwnProperty('invitees')) {
        for (const invitee of search.invitees) {
          invitee.invited = true;
        }
      }

      if (
        (search.hasOwnProperty('invitees') &&
          search.hasOwnProperty('members')) ||
        search.hasOwnProperty('team_members') ||
        search.hasOwnProperty('team_invitees')
      ) {
        searchMembers = [
          ...search.invitees,
          ...search.members,
          ...search.team_members,
          ...search.team_invitees,
        ];
      }

      // TODO: search board users endpoint should return user with full email if it's already a board member/invitee (check)
      if (search.isValidHandle) {
        this.setState({
          isValidHandle:
            this.props.currentBoardUserRole === 'owner' ||
            this.props.currentBoardUserRole === 'admin'
              ? true
              : false,
          isValidHandleMember:
            this.props.currentBoardUserRole === 'member' ? true : false,
          searchUsers: [],
        });
      } else {
        this.setState({
          isValidHandle: false,
          isValidHandleMember: false,
          searchUsers: searchMembers,
        });
      }
    } catch (err) {
      this.setState({
        isValidHandle: false,
        isValidHandleMember: false,
        searchUsers: [],
      });
    }
  };

  showMoreMembers = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          step: prevState.pagination.step + prevState.pagination.step,
        },
      };
    });
  };

  handleCardMembers = (memberId: string, e?: React.MouseEvent) => {
    // used for new card selected members
    if (this.props.setSelectedMembers) {
      this.setNewCardMembers(memberId);
      return;
    }

    // used for existing card member assign
    this.setExistingCardMembers(memberId);
  };

  setNewCardMembers = async (memberId: string) => {
    const tempMembers = [...(this.props.selected as string[])];
    const existingMemberIndex = tempMembers.indexOf(memberId);

    if (existingMemberIndex === -1) {
      tempMembers.push(memberId);
    } else {
      tempMembers.splice(existingMemberIndex, 1);
    }

    this.props.setSelectedMembers && this.props.setSelectedMembers(tempMembers);
  };

  setExistingCardMembers = async (memberId: string) => {
    const tempLoadingIds = this.props.loadingMemberIds
      ? [...this.props.loadingMemberIds]
      : [];

    // disable button on click
    this.props.setLoadingMemberIds &&
      this.props.setLoadingMemberIds([memberId, ...tempLoadingIds]);

    if (this.props.selected && this.props.selected.includes(memberId)) {
      await this.unassignMember(memberId);
    }

    if (this.props.selected && !this.props.selected.includes(memberId)) {
      await this.assignMember(memberId);
    }

    // enable button after response
    const idToRemove = tempLoadingIds.findIndex((id) => id === memberId);
    tempLoadingIds.splice(idToRemove, 1);
    this.props.setLoadingMemberIds &&
      this.props.setLoadingMemberIds(tempLoadingIds);
  };

  assignMember = async (memberId: string) => {
    if (this.props.cardId) {
      try {
        await addMemberToCard(this.props.cardId, memberId);
        this.props.assignMember(this.props.cardId, memberId);
        this.tutorialAction_assignMembers();
      } catch (err) {
        const errors = Array.isArray(err) ? err : [err];
        showErrorNotifications(errors, this.props.setMessages);
      }
    }
  };

  unassignMember = async (memberId: string) => {
    if (this.props.cardId) {
      try {
        await removeMemberFromCard(this.props.cardId, memberId);
        this.props.unassignMember(this.props.cardId, memberId);
      } catch (err) {
        const errors = Array.isArray(err) ? err : [err];
        showErrorNotifications(errors, this.props.setMessages);
      }
    }
  };

  tutorialAction_assignMembers = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'teamOwner') return;
      const checklistItems = getChecklistItems(userType);
      const stepId = 'assign_members';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  tutorialAction_inviteMembers = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'teamOwner') return;
      const checklistItems = getChecklistItems(userType);
      const stepId = 'invite_board_members';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  getSearchLabel = () => {
    let label = 'name';

    if (this.state.memberQuery.startsWith('@')) {
      label = 'handle';
    } else if (this.state.isValidEmail) {
      label = 'email';
    }

    return label;
  };

  setSearchUsers = (users: (MemberDTO | InviteeDTO)[] | null) => {
    this.setState({
      searchUsers: users,
    });
  };

  inviteMember = async (memberId: string) => {
    this.setState({
      status: 'loading',
    });

    try {
      const member = await inviteMemberToBoard(this.props.boardId, memberId);
      member.invited = true;
      this.tutorialAction_inviteMembers();
      this.props.addMember(member);
      this.setMemberQuery('');

      this.setState({
        status: 'success',
      });
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      showErrorNotifications(error, this.props.setMessages);

      this.setState({
        status: 'error',
      });
    }
  };

  render() {
    const { t } = this.props;
    let members: (MemberDTO | InviteeDTO)[] = this.state.searchUsers
      ? this.state.searchUsers
      : this.props.members;
    const isAlreadyInBoard = members.some((member) => {
      if ('email' in member) {
        return member.email === this.state.memberQuery;
      }

      return Boolean(member.avatar);
    });

    // const paginatedMembers = members.slice(this.state.pagination.currentStep, this.state.pagination.step);

    let isValidQueryMember =
      (this.state.isValidEmailMember || this.state.isValidHandleMember) &&
      !isAlreadyInBoard;
    return (
      <>
        <MemberSearchMenu
          memberQuery={this.state.memberQuery}
          setMemberQuery={this.setMemberQuery}
          isValidQueryMember={isValidQueryMember}
          userSearchOff={this.props.userSearchOff}
          status={this.state.status}
        />
        {this.props.manage ? (
          members.map((member, index) => {
            return (
              <MemberManageMenu
                key={member.id}
                member={member}
                searchUsers={this.state.searchUsers}
                setSearchUsers={this.setSearchUsers}
                inviteMember={this.inviteMember}
                focusableItem={this.props.focusableItem}
              />
            );
          })
        ) : (
          <>
            <li>
              <Button
                className="secondary-button fill"
                onClick={(e) =>
                  this.handleCardMembers(this.props.currentBoardUser.id, e)
                }
                value={this.props.currentBoardUser.id}
                disabled={
                  this.props.loadingMemberIds &&
                  this.props.loadingMemberIds.includes(
                    this.props.currentBoardUser.id,
                  )
                }
              >
                {this.props.selected?.includes(this.props.currentBoardUser.id)
                  ? t('leaveCard')
                  : t('joinCard')}
              </Button>
            </li>
            <li>
              <div className="fill">
                <hr />
              </div>
            </li>
            {members.map((member, index) => {
              const isLoading = this.props.loadingMemberIds
                ? this.props.loadingMemberIds.includes(member.id)
                : false;

              return (
                <li
                  key={member.id}
                  className={`${
                    this.props.selected &&
                    this.props.selected.includes(member.id!)
                      ? 'active'
                      : ''
                  }`}
                >
                  <Button
                    className="ghost-button py-2xs"
                    onClick={(e) => this.handleCardMembers(member.id, e)}
                    value={member.id}
                    disabled={isLoading}
                  >
                    <Thumbnail
                      classes="size-24 mr-2xs"
                      avatarData={
                        member.avatar ? member.avatar : DEFAULT_AVATAR
                      }
                      title={member.name}
                    />
                    {'email' in member && (
                      <span className="text">{member.email}</span>
                    )}
                    {'name' in member && (
                      <span className="text">{member.name}</span>
                    )}
                    {'invited' in member && (
                      <span className="text faint-text">
                        {member.invited ? t('invited') : ''}
                      </span>
                    )}
                  </Button>
                </li>
              );
            })}
          </>
        )}
        {/* <li>
          {this.state.pagination.step <= filteredMembers.length && (
            <Button class="secondary-button fill flex-h-center" onClick={this.showMoreMembers}>Show More</Button>
          )}
        </li> */}
        {!members.length && !isValidQueryMember && (
          <li>
            <div className="fill">
              <small className="faint-text separator text-chunk">
                {t('noMembersFound')}
              </small>
            </div>
          </li>
        )}
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages!,
      updateClientData: ctx.updateClientData!,
      loggedUser: ctx.loggedUser!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      boardId: ctx.board.id,
      currentBoardUserRole: ctx.board.user.role,
      members: ctx.board.members,
      loadingMemberIds: ctx.loadingMemberIds,
      currentBoardUser: ctx.board.user,
      setLoadingMemberIds: ctx.setLoadingMemberIds,
      assignMember: ctx.assignMember,
      unassignMember: ctx.unassignMember,
      addMember: ctx.addMember,
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('memberMenu')(MemberMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
