import { Component } from 'react';
import PageMessage from '../../partials/PageMessage/PageMessage';
import { NavLink } from 'react-router-dom';

class ErrorNotFound extends Component<{}> {
  render() {
    return (
      <>
        <PageMessage>
          <h1 className="primary-title normalcase pb-xs">Error 404</h1>
          <br />
          <p>Resource not found.</p>
          <ul className="control-list-component flex-h-center">
            <li>
              <NavLink
                to="/"
                className={'secondary-button'}
              >
                Return to homepage
              </NavLink>
            </li>
          </ul>
        </PageMessage>
      </>
    );
  }
}

export default ErrorNotFound;
