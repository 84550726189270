export const CARD_FILTER_LABELS = {
  all: 'all',
  priority: 'priority',
  cardNumber: 'number',
  title: 'title',
  description: 'description',
  member: 'assignees',
  tag: 'tags',
  none: 'none',
};
