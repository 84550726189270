import { Component } from 'react';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import MemberMenu from '../../menus/Member/MemberMenu';
import BoardOperationsMenu from '../../menus/Board/BoardOperationsMenu';
import Toolbar from '../Toolbar/Toolbar';
import PriorityOperationsMenu from '../../menus/Priority/PriorityOperationsMenu';
import TagOperationsMenu from '../../menus/Tag/TagOperationsMenu';
import { TContextMenu } from '../../../common/types/ContextMenu';
import CardFilterMenu from '../../menus/Board/CardFilterMenu';
import PriorityDetailMenu from '../../menus/Priority/PriorityDetailMenu';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import { TagDTO } from '../../../common/api/dtos/Tag';
import TagDetailMenu from '../../menus/Tag/TagDetailMenu';
import LinkButton from '../../controls/LinkButton/LinkButton';
import LeaveOperationsMenu from '../../menus/Leave/LeaveOperationsMenu';
import CardDataContext from '../../menus/CardData/CardDataContext';
import { TCardData } from '../../../common/types/CardData';
import { withContextAdapter } from '../ContextAdapter/withContextAdapter';
import BoardContext, {
  CardFilterField,
  IBoardContext,
} from '../../../common/contexts/BoardContext';
import { RouteComponentProps } from 'react-router-dom';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface RouteParams {
  routeBoardId: string;
}

interface ContextProps {
  isLoaded: boolean;
  isOwner: boolean;
  membersCount: number;

  filter: (value: string | null, fields: CardFilterField[]) => void;
  filterValue: string | null;
  filterFields: CardFilterField[];
  filteredCount: number;
}
interface ExternalProps extends RouteComponentProps<RouteParams> {
  boardName: string;
  toggleFavorite: () => void;
  favorite?: boolean;
  routeBoardId: string;
  routeUrl?: any;
  boardSlug?: string;

  enableFilter: boolean;

  setSelectedCardData?: (cardData?: TCardData) => void;
  selectedCardData?: TCardData[];
}
interface Props extends ContextProps, ExternalProps, WithTranslation {}

interface State {
  selectedContext: TContextMenu;
  selectedPriority: PriorityDTO | null;
  selectedTag: TagDTO | null;
  focusableItem: HTMLElement | null;
}

class BoardTopbar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedContext: 'operations',
      selectedPriority: null,
      selectedTag: null,
      focusableItem: null,
    };
  }

  setFocusableItem = (element: HTMLElement) => {
    this.setState({
      focusableItem: element,
    });
  };

  setSelectedContext = (context: TContextMenu) => {
    this.setState({
      selectedContext: context,
    });
  };

  setSelectedPriority = (priority: PriorityDTO) => {
    this.setState({
      selectedPriority: priority,
    });
  };

  setSelectedTag = (tag: TagDTO) => {
    this.setState({
      selectedTag: tag,
    });
  };

  handleFilter = (value: string | null, fields: CardFilterField[]) => {
    if (!this.props.enableFilter) return;

    if (value === '') {
      this.props.filter(null, fields);
    } else {
      this.props.filter(value, fields);
    }
  };

  getFilterTitle = (): string => {
    const { t } = this.props;
    if (this.props.filterValue) {
      return t('cardsAreFilteredFound', { count: this.props.filteredCount });
    } else {
      return t('filterCards');
    }
  };

  evaluateDept = (): number => {
    switch (this.state.selectedContext) {
      case 'managePriorities':
      case 'leaveBoard':
      case 'TagManageMenus':
        return 1;
      case 'addPriority':
      case 'editPriority':
      case 'addTag':
      case 'editTag':
        return 2;
      default:
        return 0;
    }
  };

  render() {
    const { t } = this.props;
    const contextComponents: { [key: string]: React.ReactElement } = {
      operations: (
        <BoardOperationsMenu
          favorite={this.props.favorite}
          toggleFavorite={this.props.toggleFavorite}
          setSelectedContext={this.setSelectedContext}
          routeBoardId={this.props.routeBoardId}
        />
      ),
      managePriorities: (
        <PriorityOperationsMenu
          directionalButton={{
            text: t('backToBoardOptions'),
            direction: 'left',
            onClick: () => this.setSelectedContext('operations'),
          }}
          fixedPositionParent
          setSelectedContext={this.setSelectedContext}
          setSelectedPriority={this.setSelectedPriority}
        />
      ),
      leaveBoard: this.props.isLoaded ? (
        <LeaveOperationsMenu
          isOwner={this.props.isOwner}
          directionalButton={{
            text: t('backToBoardOptions'),
            direction: 'left',
            onClick: () => this.setSelectedContext('operations'),
          }}
          fixedPositionParent
          setSelectedContext={this.setSelectedContext}
          setSelectedPriority={this.setSelectedPriority}
          routeBoardId={this.props.routeBoardId}
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
        />
      ) : (
        <></>
      ),
      TagManageMenus: (
        <TagOperationsMenu
          directionalButton={{
            text: t('backToBoardOptions'),
            direction: 'left',
            onClick: () => this.setSelectedContext('operations'),
          }}
          setSelectedContext={this.setSelectedContext}
          setSelectedTag={this.setSelectedTag}
        />
      ),
      addPriority: (
        <PriorityDetailMenu
          directionalButton={{
            text: t('backToManagePriorities'),
            direction: 'left',
            onClick: () => this.setSelectedContext('managePriorities'),
          }}
          selectedContext={this.state.selectedContext}
          setSelectedContext={this.setSelectedContext}
          legendText={t('addPriority')}
        />
      ),
      editPriority: (
        <PriorityDetailMenu
          directionalButton={{
            text: t('backToManagePriorities'),
            direction: 'left',
            onClick: () => this.setSelectedContext('managePriorities'),
          }}
          selectedContext={this.state.selectedContext}
          setSelectedContext={this.setSelectedContext}
          selectedPriority={this.state.selectedPriority}
          legendText={t('editPriority')}
        />
      ),
      addTag: (
        <TagDetailMenu
          directionalButton={{
            text: t('backToManageTags'),
            direction: 'left',
            onClick: () => this.setSelectedContext('TagManageMenus'),
          }}
          selectedContext={this.state.selectedContext}
          setSelectedContext={this.setSelectedContext}
          legendText={t('addTag')}
        />
      ),
      editTag: (
        <TagDetailMenu
          directionalButton={{
            text: t('backToManageTags'),
            direction: 'left',
            onClick: () => this.setSelectedContext('TagManageMenus'),
          }}
          selectedContext={this.state.selectedContext}
          setSelectedContext={this.setSelectedContext}
          selectedTag={this.state.selectedTag}
          legendText={t('editTag')}
        />
      ),
    };

    return (
      <>
        <Toolbar>
          {this.props.isLoaded && (
            <>
              <div className="form-group">
                <ContextMenu
                  context={this.state.selectedContext}
                  resetSelectedContext={() =>
                    this.setSelectedContext('operations')
                  }
                  contextMenuClassName="align-h-start"
                  title={t('boardOptions')}
                  triggerClassDefault="ghost-button reveal-up-1"
                  triggerContent={
                    <>
                      <span className="fas fa-ellipsis-h"></span>
                    </>
                  }
                  triggerClassActive="secondary-button reveal-up-1"
                  dept={this.evaluateDept()}
                >
                  {contextComponents[this.state.selectedContext]}
                </ContextMenu>
              </div>
              <div className="form-group reveal-up-2">
                <LinkButton
                  to={`/board/${this.props.routeBoardId}/view/`}
                  className="ghost-button"
                  activeClassName="disabled"
                >
                  <h1 className="primary-title h3 normalcase">
                    {this.props.boardName}
                  </h1>
                </LinkButton>
              </div>
              <div className="form-group">
                <ContextMenu
                  contextMenuClassName="align-h-start"
                  triggerClassDefault="link-button reveal-up-3"
                  title={this.props.t('manageMembers')}
                  dept={0}
                  triggerContent={
                    <span className="text pe-none">
                      {this.props.t('memberCount', {
                        count: this.props.membersCount,
                      })}
                    </span>
                  }
                  setFocusableItem={this.setFocusableItem}
                >
                  <MemberMenu
                    manage={true}
                    focusableItem={this.state.focusableItem!}
                  />
                </ContextMenu>
              </div>
              {this.props.enableFilter && (
                <div className="form-group">
                  <ContextMenu
                    contextMenuClassName="align-h-start"
                    title={this.getFilterTitle()}
                    name="filterMenu"
                    triggerClassActive="secondary-button reveal-up-3"
                    dept={0}
                    triggerClassDefault={`${
                      this.props.filterValue === null
                        ? 'ghost-button'
                        : 'secondary-button'
                    } reveal-up-3`}
                    triggerContent={
                      <>
                        <span
                          className={`pe-none icon fas fa-filter ${
                            this.props.filterValue === null
                              ? 'mr-0'
                              : 'accent-text'
                          }`}
                        ></span>
                        {this.props.filterValue !== null && (
                          <span className="text">
                            {this.props.filteredCount}
                          </span>
                        )}
                        <span className="pe-none icon fas fa-angle-down"></span>
                      </>
                    }
                  >
                    <CardFilterMenu
                      filter={this.handleFilter}
                      initialValue={this.props.filterValue}
                      filteredCount={this.props.filteredCount}
                      fields={this.props.filterFields}
                    />
                  </ContextMenu>
                </div>
              )}
              {this.props.selectedCardData &&
                this.props.setSelectedCardData && (
                  <div className="column text-right reveal-up-3">
                    <CardDataContext
                      disabled={false}
                      contextMenuClassName="align-h-start"
                      setSelectedCardData={this.props.setSelectedCardData}
                      selectedCardData={this.props.selectedCardData}
                    />
                  </div>
                )}
              {/* <div className="colored-bar"></div> */}
            </>
          )}
        </Toolbar>
      </>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  withStyledTranslation('boardTopbar')(BoardTopbar),
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      isLoaded: true,
      isOwner: ctx.board.user.role === 'owner',
      membersCount: ctx.board.members.length || 0,
      filter: ctx.filterCards,
      filterValue: ctx.board.cardFilter.value,
      filterFields: ctx.board.cardFilter.fields,
      filteredCount: ctx.board.cardFilter.count,
    };
  },
);
