import React, { ChangeEvent, Component } from 'react';
import AppContext, * as Context from '../../../common/contexts/AppContext';
import ComboBox from '../../controls/ComboBox/ComboBox';
import ToggleSwitch from '../../controls/ToggleSwitch/ToggleSwitch';
import { THEME_OPTIONS } from '../../../common/configs/ThemeOptions';
import Accordion from '../../controls/Accordion/Accordion';
import { WithTranslation } from 'react-i18next';
import { LANGUAGE_OPTIONS } from '../../../common/configs/LanguageOptions';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { HOUR_OPTIONS } from '../../../common/configs/HourOptions';
import dayjsHelper from '../../../common/helpers/dayjsHelper';

interface Props extends WithTranslation {
  history: any;
}

interface State {
  language: string;
  timeFormat: string;
}

class GlobalScope extends Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      language: 'en_US',
      timeFormat: '12',
    };
  }

  setAccountTheme = (_: unknown, value: unknown) => {
    this.context.loggedUser?.setAccountSettings({ theme: String(value) });
  };

  setAccountHighContrast = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setAccountSettings({
      highContrast: event.target.checked,
    });
  };

  setAccountReducedMotion = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setAccountSettings({
      reducedMotion: event.target.checked,
    });
  };

  setAccountColorBlindHelpers = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setAccountSettings({
      colorBlindHelpers: event.target.checked,
    });
  };

  setAccountLanguage = (_: unknown, value: unknown) => {
    const newLang = String(value);
    this.props.i18n.changeLanguage(newLang);

    this.setState({
      language: newLang,
    });

    this.context.loggedUser?.setAccountSettings({
      language: newLang,
    });
  };

  setAccountTimeFormat = (_: unknown, value: unknown) => {
    const newTimeFormat = String(value);

    this.setState({
      timeFormat: newTimeFormat,
    });

    this.context.loggedUser?.setAccountSettings({
      timeFormat: Number(newTimeFormat),
    });
  };

  restoreDefaults = () => {
    // Remove all data from local storage by going over every account setting property
    // and setting the respective field to `null`.
    //
    // We do it this way to make sure that if new fields get added, they get set to `null` as well.
    const localSettings: Context.LocalSettings = {
      ...this.context.loggedUser!.accountSettings,
    };
    for (const k in localSettings) {
      if (Object.prototype.hasOwnProperty.call(localSettings, k)) {
        const key = k as keyof Context.LocalSettings;
        localSettings[key] = null;
      }
    }
    this.context.loggedUser?.setLocalSettings(localSettings);
  };

  hasOverrides = (): boolean => {
    const localSettings = this.context.loggedUser!.localSettings;

    // As long as one property is not null, it means we have overrides
    return Object.values(localSettings).some(
      (value: unknown) => value !== null,
    );
  };

  render() {
    const { t, i18n } = this.props;
    const loggedUser = this.context.loggedUser!;
    const accountSettings = loggedUser.accountSettings;
    const localSettings = loggedUser.localSettings;

    const hasLocalTheme = localSettings.theme !== null;
    const hasLocalHighContrast = localSettings.highContrast !== null;
    const hasLocalReducedMotion = localSettings.reducedMotion !== null;
    const hasLocalLanguage = localSettings.language !== null;
    const hasLocalTimeFormat = localSettings.timeFormat !== null;
    const hasLocalColorBlindHelpers = localSettings.colorBlindHelpers !== null;

    const translatedThemeOptions = THEME_OPTIONS.map((option) => ({
      ...option,
      label: t(`themeOptions.${option.value}`),
    }));

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-2xs">
            <Accordion
              accordionSlug="account-language"
              isOpen={false}
              iconClasses="fal fa-globe icon"
              title={t('language.title')}
              subheading={t('language.subtitle')}
              history={this.props.history}
              disabled={false}
            >
              <div className="accordion-row">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column pb-xs">
                    <span className="fal fa-language icon"></span>
                  </div>
                  <div className="column fill pb-xs">
                    <span>{t('language.displayLanguage')}</span>
                    {hasLocalLanguage && (
                      <>
                        <br />
                        <small className="faint-text">
                          &nbsp;
                          <span className="fas fa-info-circle accent-text-yellow"></span>{' '}
                          <span>{t('language.chooseLanguageNotice')}</span>
                        </small>
                      </>
                    )}
                  </div>
                  <div className="column pb-2xs">
                    <ComboBox
                      id="language-select"
                      label={t('language.chooseLanguage')}
                      value={accountSettings.language}
                      options={LANGUAGE_OPTIONS} // Assume this is defined elsewhere in your code
                      onChange={this.setAccountLanguage}
                      formGroupClassname="pb-0 mb-0"
                      getValue={(option) => option.value}
                      getLabel={(option) => option.label}
                      disabled={false}
                      srOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-row">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column pb-xs">
                    <span className="fal fa-clock icon"></span>
                  </div>
                  <div className="column fill pb-xs">
                    <span>{t('language.timeFormat')}</span>
                    {hasLocalTimeFormat && (
                      <>
                        <br />
                        <small className="faint-text">
                          &nbsp;
                          <span className="fas fa-info-circle accent-text-yellow"></span>{' '}
                          <span>{t('language.chooseTimeFormatNotice')}</span>
                        </small>
                      </>
                    )}
                  </div>
                  <div className="column pb-2xs">
                    <ComboBox
                      id="language-select"
                      label={t('language.chooseTimeFormat')}
                      value={accountSettings.timeFormat}
                      options={HOUR_OPTIONS} // Assume this is defined elsewhere in your code
                      onChange={this.setAccountTimeFormat}
                      formGroupClassname="pb-0 mb-0"
                      getValue={(option) => option.value}
                      getLabel={(option) =>
                        dayjsHelper(new Date()).format(
                          option && option.value == '12' ? 'hh:mm A' : 'HH:mm',
                        )
                      }
                      disabled={false}
                      srOnly={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              accordionSlug="theme"
              isOpen={true}
              iconClasses="fal fa-brush icon"
              title={t('theme.title')}
              subheading={t('theme.subtitle')}
              history={this.props.history}
            >
              <div className="accordion-row">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column pb-xs">
                    <span className="fal fa-adjust icon"></span>
                  </div>
                  <div className="column fill pb-xs">
                    <span>{t('theme.interfaceMode')}</span>
                    <br />
                    {hasLocalTheme && (
                      <small className="faint-text">
                        <span className="fas fa-info-circle accent-text-yellow"></span>{' '}
                        <span>{t('theme.overrideNotice')}</span>
                      </small>
                    )}
                  </div>
                  <div className="column pb-xs">
                    <ComboBox
                      id="toggle-theme"
                      label={t('theme.theme')}
                      value={accountSettings.theme}
                      disabled={false}
                      options={translatedThemeOptions} // Assume this is defined elsewhere in your code
                      onChange={this.setAccountTheme}
                      formGroupClassname="pb-0 mb-0"
                      getValue={(op) => op.value}
                      getLabel={(op) => op?.label}
                      srOnly={true}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="accordion-row">
                <div className="flex-row squeeze flex-v-center">
                  <div className="column fill pl-2xs pb-xs">
                    <CheckBox
                      id={'useHighContrast'}
                      name={'useHighContrast'}
                      checked={accountSettings.highContrast}
                      label={'Enforce high contrast'}
                      className={'mr-sm'}
                      disabled={false}
                      changeMethod={this.setAccountHighContrast}
                    />
                    {hasLocalHighContrast && (
                      <>
                        <br />
                        <small className="faint-text pl-md">
                          &nbsp;
                          <span className="fas fa-info-circle accent-text-yellow"></span>{' '}
                          <span>
                            "High Contrast" is overridden on this device.
                          </span>
                        </small>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
            </Accordion>
            <Accordion
              accordionSlug="accessibility"
              isOpen={false}
              iconClasses="fal fa-universal-access icon"
              title={t('accessibility.title')}
              subheading={t('accessibility.subtitle')}
              disabled={false}
              history={this.props.history}
            >
              <ToggleSwitch
                id="accountReducedMotion"
                name="accountReducedMotion"
                changeMethod={this.setAccountReducedMotion}
                isChecked={accountSettings.reducedMotion}
                multiline={true}
                tight={true}
              >
                <label htmlFor="accountReducedMotion">
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column py-0">
                      <span className="fal fa-stars icon"></span>
                    </div>
                    <div className="column py-0">
                      <span>{t('accessibility.reduceMotion')}</span>
                      <br />
                      <small className="faint-text">
                        {t('accessibility.reduceMotionDesc')}
                      </small>
                      {hasLocalReducedMotion && (
                        <>
                          <br />
                          <small className="faint-text">
                            &nbsp;
                            <span className="fas fa-info-circle accent-text-yellow"></span>{' '}
                            <span>{t('accessibility.reduceMotionNotice')}</span>
                          </small>
                        </>
                      )}
                    </div>
                  </div>

                  <span className="state">
                    {accountSettings.reducedMotion ? t('On') : t('Off')}
                  </span>
                </label>
              </ToggleSwitch>
              <ToggleSwitch
                id="accountColorBlindHelpers"
                name="accountColorBlindHelpers"
                changeMethod={this.setAccountColorBlindHelpers}
                isChecked={accountSettings.colorBlindHelpers}
                multiline={true}
                tight={true}
                isDisabled={false}
              >
                <label htmlFor="accountColorBlindHelpers">
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column py-0">
                      <span className="fal fa-tint-slash icon"></span>
                    </div>
                    <div className="column py-0">
                      <span>{t('accessibility.colorBlindHelpers')}</span>
                      <br />
                      <small className="faint-text">
                        {t('accessibility.colorBlindHelpersDesc')}
                      </small>
                      {hasLocalColorBlindHelpers && (
                        <>
                          <br />
                          <small className="faint-text">
                            &nbsp;
                            <span className="fas fa-info-circle accent-text-yellow"></span>{' '}
                            <span>
                              {t('accessibility.colorBlindHelpersNotice')}
                            </span>
                          </small>
                        </>
                      )}
                    </div>
                  </div>
                  <span className="state">
                    {accountSettings.colorBlindHelpers ? t('On') : t('Off')}
                  </span>
                </label>
              </ToggleSwitch>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('globalScope')(GlobalScope);
GlobalScope.contextType = AppContext;
