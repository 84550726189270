export function cn(...values: (string | Record<string, boolean>)[]): string {
  return values.map(cnInternal).join(' ');
}

function cnInternal(value: string | Record<string, boolean>): string {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'object') {
    return Object.keys(value)
      .filter((key) => value[key])
      .join(' ');
  }
  throw new Error(`Unexpected type for cn: ${typeof value}`);
}
