import React, { ChangeEvent, Component } from 'react';
import { BoardDTO } from '../../../../common/api/dtos/Board';
import { listBoards } from '../../../../common/api/endpoints/board';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import BoardCardArchived from '../../../partials/board/BoardListing/BoardCardArchived';
import ErrorList from '../../../error/ErrorList/ErrorList';
import { withTransitionEvent } from '../../../partials/Transition/TransitionEvent';
import TextBox from '../../../controls/TextBox/TextBox';
import { getFilteredItems } from '../../../../common/helpers/getFilteredItems';
import ContentLoader from '../../../partials/ContentLoader/ContentLoader';

export interface Props {
  teamId?: string | null;
}

interface State {
  filterValue: string;
  status: TRequestStatus;
  restoreStatus: TRequestStatus;
  deleteStatus: TRequestStatus;
  boards: BoardDTO[];
  serverErrors: string[];
}

class BoardArchive extends Component<Props, State> {
  availableFilters: string[] = ['name', 'description'];

  constructor(props: Props) {
    super(props);

    this.state = {
      filterValue: '',
      status: 'idle',
      restoreStatus: 'idle',
      deleteStatus: 'idle',
      boards: [],
      serverErrors: [],
    };
  }

  componentDidMount(): void {
    this.fetchArchivedBoards();
  }

  fetchArchivedBoards = async () => {
    this.setState({ status: 'loading' });

    try {
      const archivedBoards = await listBoards({
        name: 'boards',
        params: [
          { param: 'teamId', value: this.props.teamId || null },
          { param: 'archived', value: true },
          { param: 'owned', value: true },
        ],
      });

      this.setState({ status: 'success', boards: archivedBoards.boards });
    } catch (err) {
      const error = Array.isArray(err) ? err : [err as string];

      this.setState({ status: 'error', serverErrors: error });
    }
  };

  removeFromArchive = (id: string) => {
    this.setState((prevState) => ({
      boards: prevState.boards.filter((board) => board.id !== id),
    }));
  };

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterValue: ev.target.value });
  };

  renderFilter() {
    const { filterValue, boards } = this.state;

    if (boards.length === 0) return null;

    return (
      <div className="flex-row fill">
        <div className="column">
          <TextBox
            label="Filter archive"
            type="text"
            name="filterBox"
            id="filterBox"
            onChange={this.setFilterValue}
            placeholder="Filter archive"
            srOnly={true}
            tight={true}
            autoComplete="off"
            ariaAutocomplete="none"
          />
        </div>
      </div>
    );
  }

  renderContent() {
    const { status, boards, filterValue, serverErrors } = this.state;
    const filteredArchivedBoards = getFilteredItems(
      filterValue,
      this.availableFilters,
      boards,
    );

    if (status === 'loading') {
      return (
        <ContentLoader
          width="50%"
          height="111px"
        />
      );
    }

    if (status === 'success' && boards.length === 0) {
      return (
        <div className="flex-row">
          <div className="column pt-0 flex-v-stretch">
            <div className="card">
              <p className="faint-text">
                Nothing to see here right now. Archive some boards and you'll
                have them listed.
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (filteredArchivedBoards.length > 0) {
      return filteredArchivedBoards.map((board) => (
        <div
          className="flex-row"
          key={board.id}
        >
          <div className="column medium pb-2xs pt-0">
            <BoardCardArchived
              id={board.id}
              name={board.name}
              description={board.description}
              removeFromArchive={this.removeFromArchive}
              owned={board.owned}
            />
          </div>
        </div>
      ));
    }

    if (status === 'success') {
      return (
        <div className="flex-row">
          <div className="column smaller flex-v-stretch">
            <div className="card">
              <p className="faint-text">
                There are no archived boards for the given criteria.
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { serverErrors } = this.state;

    return (
      <div className="fade-in-grow">
        <div className="flex-row fill">
          <div className="column">
            <h3 className="text-sm text-500">
              <span>Archived boards</span>
            </h3>
            <ErrorList errors={serverErrors} />
            {this.renderFilter()}
          </div>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

export default BoardArchive;
