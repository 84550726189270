import { TColor } from '../types/Color';

export const TAG_COLORS: TColor[] = [
  {
    value: 'pink',
    label: 'pink',
    symbol: 'fas fa-umbrella',
  },
  {
    value: 'red',
    label: 'red',
    symbol: 'fas fa-apple-alt',
  },
  {
    value: 'orange',
    label: 'orange',
    symbol: 'fas fa-fire',
  },
  {
    value: 'yellow',
    label: 'yellow',
    symbol: 'fas fa-lemon',
  },
  {
    value: 'green',
    label: 'green',
    symbol: 'fas fa-leaf',
  },
  {
    value: 'cyan',
    label: 'cyan',
    symbol: 'fas fa-cloud',
  },
  {
    value: 'blue',
    label: 'blue',
    symbol: 'fas fa-tint',
  },
  {
    value: 'purple',
    label: 'purple',
    symbol: 'fas fa-rocket',
  },
];
