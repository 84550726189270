import React, { memo, RefObject } from 'react';
import {
  DraggableProvided,
  DraggableStateSnapshot,
} from '../../../../vendors/wavemyth/react-beautiful-dnd/src';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { ICard } from '../../../../common/contexts/BoardContext';
import { cn } from '../../../../common/helpers/cn';
import { getDropStyle } from '../../../../common/helpers/getDropStyle';
import { TCardData } from '../../../../common/types/CardData';
import BoardCardDescription from './BoardCardContent/BoardCardDescription';
import BoardCardMembers from './BoardCardContent/BoardCardMembers';
import BoardCardTags from './BoardCardContent/BoardCardTags';
import scrollToCard from '../../../../common/helpers/scrollToCard';
import { getScrollBehavior } from '../../../../common/helpers/getScrollBehavior';

interface BoardCardProps {
  selected: boolean;
  disabled: boolean;
  cardNrPrefix: string;
  boardCard: ICard;
  columnId: string | null;
  tags: TagDTO[];
  priorities: PriorityDTO[];
  members: (MemberDTO | InviteeDTO)[];
  selectCard: (columnId: string | null, cardId: string) => void;
  setCardRef: (cardId: string, cardRef: RefObject<HTMLDivElement>) => void;
  provided?: DraggableProvided;
  innerRef?: (element: HTMLElement | null) => void;
  snapshot?: DraggableStateSnapshot;
  selectedCardData: TCardData[];
  isClone?: boolean;
}

const BoardCard: React.FC<BoardCardProps> = memo(
  (props) => {
    const {
      boardCard,
      selected,
      disabled,
      provided,
      innerRef,
      snapshot,
      selectedCardData,
      members,
      tags,
      priorities,
      cardNrPrefix,
      selectCard,
      columnId,
      setCardRef,
    } = props;

    const cardRef = React.useRef<HTMLDivElement>(null);

    const handleCardClick = () => {
      const contextMenu = document.querySelector(
        '.context-menu-component',
      ) as HTMLElement;
      if (contextMenu) {
        const contextMenuId = contextMenu.dataset.contextId;
        if (contextMenuId === boardCard.id) return;
      }
      selectCard(columnId, boardCard.id);
    };

    const handleCardEnter = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleCardClick();
      }
    };

    const handleCardFocus = (e: KeyboardEvent) => {
      if (
        e.key === 'Tab' &&
        !selected &&
        e.target === cardRef.current?.parentNode
      ) {
        scrollToCard(cardRef.current, getScrollBehavior());
      }
    };

    React.useEffect(() => {
      setCardRef(boardCard.id, cardRef);
      document.addEventListener('keyup', handleCardFocus);
      return () => {
        document.removeEventListener('keyup', handleCardFocus);
      };
    }, [boardCard.id, setCardRef]);

    const selectedTags = boardCard.tagIds.map((tagId) => {
      return tags.find((tag) => tag.id === tagId);
    }) as TagDTO[];

    const priority = priorities.find((p) => p.id === boardCard.priorityId);

    return (
      <div
        ref={innerRef}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}
        style={getDropStyle(provided?.draggableProps.style, snapshot)}
        onClick={handleCardClick}
        onKeyDown={handleCardEnter}
        className="card-drag-helper"
      >
        <div
          className={cn('form-group', 'card-board-button', 'tight', {
            dragging: snapshot?.isDragging,
            'flashing-border': selected,
          })}
          ref={cardRef}
          role="button"
          tabIndex={0}
        >
          <BoardCardDescription
            priority={priority}
            cardId={boardCard.id}
            prefix={cardNrPrefix}
            number={boardCard.number}
            title={boardCard.title}
            description={boardCard.description}
            selectedCardData={selectedCardData}
            numberOfAttachments={boardCard.numberOfAttachments}
            numberOfComments={boardCard.numberOfComments}
          />
          {selectedCardData.includes('member') && (
            <BoardCardMembers
              cardId={boardCard.id}
              assigneeIds={boardCard.assigneeIds}
              members={members}
              isArchived={disabled}
            />
          )}
          {selectedCardData.includes('tag') && (
            <BoardCardTags
              tags={selectedTags}
              cardId={boardCard.id}
              tagIds={boardCard.tagIds}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.boardCard === nextProps.boardCard &&
      prevProps.selected === nextProps.selected &&
      prevProps.disabled === nextProps.disabled &&
      prevProps.snapshot === nextProps.snapshot &&
      prevProps.selectedCardData === nextProps.selectedCardData &&
      prevProps.members === nextProps.members &&
      prevProps.tags === nextProps.tags &&
      prevProps.priorities === nextProps.priorities &&
      prevProps.cardNrPrefix === nextProps.cardNrPrefix &&
      prevProps.columnId === nextProps.columnId &&
      prevProps.provided === nextProps.provided
    );
  },
);

export default BoardCard;
