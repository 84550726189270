import { Component } from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { TeamDTO } from '../../../common/api/dtos/Team';
import Accordion from '../../controls/Accordion/Accordion';
import LinkButton from '../../controls/LinkButton/LinkButton';
import Button from '../../controls/Button/Button';
import { leaveTeam, listTeams } from '../../../common/api/endpoints/team';
import Dialog from '../../controls/Dialog/Dialog';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  history: any;
}

interface State {
  showLeavePrompt: boolean;
  selectedTeamId: string | null;
  status: TRequestStatus;
  teams: TeamDTO[];
  loadingMore: boolean;
  nextPage: string | null;
}

export class Membership extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showLeavePrompt: false,
      selectedTeamId: null,
      status: 'idle',
      teams: [],
      loadingMore: false,
      nextPage: null,
    };
  }

  componentDidMount() {
    this.fetchAllTeams();

    window.addEventListener('scroll', this.handleScroll); // Add scroll listener
    window.addEventListener('resize', this.handleResize); // Add resize listener

    // Check if we need to load more teams on mount
    this.checkIfMoreContentNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up scroll listener
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  }

  checkIfMoreContentNeeded = () => {
    if (
      document.documentElement.scrollHeight - 100 <= window.innerHeight &&
      this.state.nextPage
    ) {
      this.fetchMoreTeams();
    }
  };

  handleResize = () => {
    this.checkIfMoreContentNeeded(); // Trigger content check on resize
  };

  handleScroll = () => {
    const { loadingMore, nextPage } = this.state;

    if (loadingMore || !nextPage) return;

    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      this.fetchMoreTeams();
    }
  };

  fetchMoreTeams = async () => {
    const { nextPage, teams } = this.state;

    this.setState({ loadingMore: true });

    try {
      const { teams: newTeams, nextPage: newNextPage } = await listTeams(
        1,
        nextPage,
      );

      this.setState(
        (prevState) => ({
          teams: [...prevState.teams, ...newTeams],
          loadingMore: false,
          nextPage: newNextPage,
        }),
        () => {
          // Check if more content is needed after updating state
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        loadingMore: false,
        status: 'error',
      });
    }
  };

  fetchAllTeams = async () => {
    this.setState({ status: 'loading' });
    try {
      const { teams, nextPage } = await listTeams(1);

      this.setState(
        {
          teams,
          status: 'success',
          nextPage,
        },
        () => {
          // Check if more content is needed after the first fetch
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        status: 'error',
        teams: [],
      });
    }
  };

  setShowLeavePrompt = (
    showLeavePrompt: boolean,
    teamId: string | null = null,
  ) => {
    this.setState({
      showLeavePrompt,
      selectedTeamId: teamId,
      status: 'idle',
    });
  };

  handleSubmit = async (teamId: string) => {
    this.setState({
      status: 'loading',
    });

    try {
      await leaveTeam(teamId);
      this.setState({
        status: 'success',
        teams: this.state.teams.filter((team) => team.id !== teamId),
      });
    } catch (err) {
      this.setState({
        status: 'error',
      });
    }
  };

  render() {
    const { t } = this.props;
    const { teams, showLeavePrompt, selectedTeamId, status, loadingMore } =
      this.state;

    return (
      <div className="flex-row fill">
        <div className="column pt-2xs">
          <Accordion
            accordionSlug="leave-teams"
            isOpen={true}
            iconClasses="fal fa-backspace icon"
            title={t('leaveTeamsTitle')}
            subheading={t('leaveTeamsSubheading')}
            loading={status === 'loading'}
            history={this.props.history}
            disabled={true}
            softDisabled={true}
            hideArrow={true}
          >
            {teams.map((team) => {
              return (
                <div key={team.id}>
                  {team.owned ? (
                    <div className="accordion-row py-sm">
                      <div className="flex-row">
                        <div className="column medium">
                          <h2 className="text-md pb-sm">{team.name}</h2>
                          <p className="text-sm">
                            <span className="fas fa-info-circle accent-text-blue"></span>
                            <span>
                              {t('ownerInfo')} <br />
                              <LinkButton
                                to={`../team/${team.id}/housekeeping/delete-team`}
                                className="link-button"
                              >
                                <span className="text">
                                  {t('housekeepingLinkText')}
                                </span>{' '}
                                <span>&rsaquo;</span>{' '}
                                <span className="text">
                                  {t('deleteTeamSection')}
                                </span>
                              </LinkButton>
                              <span>.</span>
                            </span>
                          </p>
                          <Button
                            disabled
                            className="secondary-button"
                          >
                            {t('leaveTeamButton', { teamName: team.name })}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="accordion-row py-sm">
                      <div className="flex-row">
                        <div className="column medium">
                          <p className="text-sm">
                            <span className="fas fa-exclamation-circle accent-text-yellow"></span>
                            <span>{t('leavingTeamInfo')}</span>
                          </p>
                          <Button
                            className="secondary-button"
                            onClick={() =>
                              this.setShowLeavePrompt(true, team.id)
                            }
                          >
                            {t('leaveTeamButton', { teamName: team.name })}
                          </Button>
                          {showLeavePrompt && selectedTeamId === team.id && (
                            <Dialog
                              title={t('leavingTeamDialogTitle')}
                              message={t('leavingTeamDialogMessage', {
                                teamName: team.name,
                              })}
                              confirmText={t('leavingTeamDialogConfirmText')}
                              cancelText={t('leavingTeamDialogCancelText')}
                              info={
                                <p className="text-sm faint-text">
                                  <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
                                  <span>{t('leavingTeamDialogInfo')}</span>
                                </p>
                              }
                              status={status}
                              onConfirm={() => this.handleSubmit(team.id)}
                              onCancel={() => this.setShowLeavePrompt(false)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </Accordion>
        </div>
        {/* Infinite Scroll Loader */}
        {loadingMore && <div className="loader"></div>}
      </div>
    );
  }
}

export default withStyledTranslation('membership')(Membership);
