import React, { Component, KeyboardEvent } from 'react';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import {
  DraggableProvided,
  DraggableStateSnapshot,
} from '../../../../vendors/wavemyth/react-beautiful-dnd/src';
import { getDropStyle } from '../../../../common/helpers/getDropStyle';
import { createSlug } from '../../../../common/helpers/createSlug';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import Thumbnail from '../../Thumbnail/Thumbnail';

interface Props extends WithTranslation {
  id: string;
  name: string;
  color: string;
  className?: string;
  small?: boolean;
  description?: string;
  archived?: boolean;
  favorite: boolean;
  setFavorite?: (
    ev: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
    id: string,
  ) => void;
  thumbnail: string;
  provided?: DraggableProvided;
  innerRef?: (element: HTMLElement | null) => void;
  snapshot?: DraggableStateSnapshot;
  showReorder?: boolean;
}

interface State {
  isFavoriteDisabled: boolean;
}

class BoardListingCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFavoriteDisabled: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (prevProps.favorite !== this.props.favorite) {
      this.setState({ isFavoriteDisabled: false });
    }
  }

  handleSetFavorite = (
    ev: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (ev.type === 'keydown' && (ev as KeyboardEvent).key !== 'Tab') {
      ev.preventDefault();
    }
    if (ev.type === 'keydown' && (ev as KeyboardEvent).key !== 'Enter') return;
    if (
      typeof this.props.setFavorite !== 'undefined' &&
      !this.state.isFavoriteDisabled
    ) {
      this.props.setFavorite(ev, this.props.id);
      this.setState({ isFavoriteDisabled: true });
    }
  };

  evaluateURL() {
    const cleanedName = this.props.name.replaceAll(/%/g, '');
    const boardSlug = createSlug(cleanedName);
    return `/board/${this.props.id}/view/${boardSlug}`;
  }

  render() {
    return (
      <LinkButton
        to={this.evaluateURL()}
        className={['card-button', this.props.className].join(' ')}
        disabled={this.props.showReorder}
      >
        <div
          className={[
            'thumbnail-frame-component',
            this.props.color ? `accent-${this.props.color}` : '',
            this.props.small ? `small` : ``,
            this.props.small ? `mr-xs` : `mr-sm`,
          ].join(' ')}
          ref={this.props.innerRef}
          {...this.props.provided?.draggableProps}
          {...this.props.provided?.dragHandleProps}
          style={getDropStyle(
            this.props.provided?.draggableProps.style,
            this.props.snapshot!,
          )}
        >
          <Thumbnail
            thumbnailData={this.props.thumbnail}
            classes={`radius ${this.props.small ? 'size-48' : 'size-64'}`}
            title={this.props.name}
          />
        </div>
        <div>
          <div className="flex-row fill">
            <div className="column py-0">
              <h3 className="primary-title normalcase multiline-ellipsis l1 pr-md">
                {this.props.name}
              </h3>
            </div>
          </div>
          {this.props.description && (
            <div className="flex-row fill">
              <div className="column pb-0">
                <p
                  className="faint-text multiline-ellipsis l2"
                  title={this.props.description}
                >
                  {this.props.description}
                </p>
              </div>
            </div>
          )}
        </div>
      </LinkButton>
    );
  }
}

export default withStyledTranslation('boardListingCard')(BoardListingCard);
