import React from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from '../../../../vendors/wavemyth/react-beautiful-dnd/src';
import { CardDTO } from '../../../../common/api/dtos/Card';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import { getDropStyle } from '../../../../common/helpers/getDropStyle';
import Button from '../../../controls/Button/Button';
import BoardColumnHeader from '../BoardColumn/BoardColumnLayout/BoardColumnHeader';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';

export interface ColumnInfo extends ColumnDTO {
  // All cards in the column
  cards: CardDTO[];

  // Visible cards on the board
  visibleCards: CardDTO[];
}

interface Props extends WithTranslation {
  boardColumn: ColumnInfo;
  index: number;
  handleExpandColumn: (columnId: string) => void;
  boardFilterValue?: string | null;
  columnCardLimitState: 'disabled' | 'enabled' | 'enforced';
}

class BoardColumnCollapsed extends React.Component<Props> {
  addButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: Props) {
    super(props);
    this.addButtonRef = React.createRef();
  }

  componentDidMount(): void {
    // stop propagation of the horizontal drag
    // ref is occupied, that's why we use the query selector here
    const columnHead = document.querySelector(
      `.card-board-list[data-rfd-draggable-id='${this.props.boardColumn.id}'] .list-drag-head`,
    ) as HTMLDivElement;
    if (columnHead) {
      columnHead.addEventListener('mousedown', (e: MouseEvent) =>
        e.stopPropagation(),
      );
    }
  }

  componentWillUnmount(): void {
    // stop propagation of the horizontal drag
    // ref is occupied, that's why we use the query selector here
    const columnHead = document.querySelector(
      `.card-board-list[data-rfd-draggable-id='${this.props.boardColumn.id}'] .list-drag-head`,
    ) as HTMLDivElement;
    if (columnHead) {
      columnHead.removeEventListener('mousedown', (e: MouseEvent) =>
        e.stopPropagation(),
      );
    }
  }
  renderColumnCount = () => {
    const { boardColumn, t } = this.props;
    const { cardLimit } = boardColumn;

    const totalCards = boardColumn.cards.length;
    const visibleCards = boardColumn.visibleCards.length;
    const hasFilter = !!this.props.boardFilterValue;
    const hasLimit =
      this.props.columnCardLimitState !== 'disabled' && cardLimit;

    return (
      <sup className="pe-none no-wrap text-sm">
        <small>
          {hasFilter ? (
            <span>
              {visibleCards}
              {totalCards > 0 && (
                <span>
                  {' ('}
                  {totalCards}
                  {hasLimit && `/${cardLimit}`}
                  {')'}
                </span>
              )}
            </span>
          ) : hasLimit ? (
            <span>
              {totalCards}/{cardLimit}
            </span>
          ) : (
            <span>{totalCards}</span>
          )}
        </small>
      </sup>
    );
  };

  handleExpandColumn = () =>
    this.props.handleExpandColumn(this.props.boardColumn.id);

  render() {
    const { t, boardColumn } = this.props;
    return (
      <Draggable
        draggableId={this.props.boardColumn.id}
        index={this.props.index}
      >
        {(
          draggableProvided: DraggableProvided,
          draggableSnapshot: DraggableStateSnapshot,
        ) => (
          <Droppable droppableId={this.props.boardColumn.id}>
            {(
              droppableProvided: DroppableProvided,
              droppableSnapshot: DroppableStateSnapshot,
            ) => (
              <div
                className={['column', 'card-board-list collapsed-list'].join(
                  ' ',
                )}
                {...draggableProvided.draggableProps}
                ref={draggableProvided.innerRef}
                style={getDropStyle(
                  draggableProvided.draggableProps.style,
                  draggableSnapshot,
                )}
              >
                <div
                  className={[
                    'list-drag-helper',
                    draggableSnapshot.isDragging ? 'dragging' : '',
                    this.props.columnCardLimitState !== 'disabled' &&
                    boardColumn.cardLimit &&
                    boardColumn.cardLimit < boardColumn.cards.length
                      ? 'static-border-danger'
                      : '',
                  ].join(' ')}
                >
                  <BoardColumnHeader provided={draggableProvided}>
                    <div className="column pt-2xs large">
                      <div
                        className="collapsed-handle"
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        <small className="fas fa-grip-lines icon"></small>
                        <Button
                          className={[
                            droppableSnapshot.isDraggingOver
                              ? 'primary-button'
                              : 'ghost-button',
                          ].join(' ')}
                          title={t('expandColumn', {
                            columnTitle: this.props.boardColumn.title,
                          })}
                          onClick={this.handleExpandColumn}
                        >
                          <span className="pe-none card-board-list-title">
                            {this.props.boardColumn.title}
                          </span>
                        </Button>
                        &nbsp;
                        <span className="inline-block mt-xs">
                          {this.renderColumnCount()}
                        </span>
                      </div>
                    </div>
                  </BoardColumnHeader>
                </div>
              </div>
            )}
          </Droppable>
        )}
      </Draggable>
    );
  }
}

export default withStyledTranslation('boardColumnCollapsed')(
  BoardColumnCollapsed,
);
