export default function scrollToCard(
  element: HTMLElement | null,
  behavior: ScrollBehavior | undefined,
  listRef?: React.RefObject<any>, // Add optional listRef for virtualized lists
  cardIndex?: number, // Add optional cardIndex for virtualized lists
  cardId?: string, // Add optional cardIndex for virtualized lists
) {
  // If dealing with a virtualized list and element isn't available
  if (!element && listRef && cardIndex !== undefined) {
    if (listRef.current && typeof listRef.current.scrollToRow === 'function') {
      try {
        // Scroll the virtualized list to the card's index
        listRef.current.scrollToRow(cardIndex);

        // Set a timeout to allow the list to render, then retry scrolling
        setTimeout(() => {
          const newElement = document.querySelector(
            `[data-card-id="${cardIndex}${cardId}"]`,
          );
          scrollToCard(newElement as HTMLElement, behavior, listRef, cardIndex);
        }, 100); // Delay to let the virtualized list render the card
      } catch (error) {
        console.error('Error scrolling to row in virtualized list:', error);
      }
    }
    return;
  }

  // Continue with the original logic if the element exists
  if (!element) return;

  // Helper function to find the first scrollable parent
  function findScrollableParent(el: HTMLElement): HTMLElement | null {
    let parent = el.parentElement;
    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY;
      if (overflowY === 'auto' || overflowY === 'scroll') {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  }

  // Horizontal handling for cards (for flyout behavior)
  let flyoutRect = document
    .querySelector('.card-flyout-component')
    ?.getBoundingClientRect();
  const flyoutOffset = (window.innerWidth / 100) * 51;
  const elementPositionX =
    element.getBoundingClientRect().left + window.scrollX;
  let posLeft = elementPositionX - flyoutOffset + element.offsetWidth;
  const elementRect = element.getBoundingClientRect();
  let shouldHorizontalScroll = false;
  const visibleBoardScreenWidth =
    window.innerWidth - (flyoutRect ? flyoutRect.width : flyoutOffset);
  const isMobile = window.innerWidth < 767;

  if (
    elementPositionX + elementRect.width >
    window.scrollX + visibleBoardScreenWidth
  ) {
    shouldHorizontalScroll = true;
  }

  if (elementPositionX < window.scrollX) {
    shouldHorizontalScroll = true;
    posLeft = elementPositionX;
  }

  if (isMobile) {
    posLeft =
      elementPositionX - (window.innerWidth / 2 - elementRect.width / 2);
  }

  if (shouldHorizontalScroll) {
    window.scrollTo({
      left: posLeft,
      behavior,
    });
  }

  // Vertical handling
  const listBody = findScrollableParent(element);
  const elementPos = element.getBoundingClientRect();
  const offsetTop = listBody ? listBody.getBoundingClientRect().top : 0;
  const offsetBottom = listBody ? listBody.getBoundingClientRect().bottom : 0;
  const elementPositionY =
    element.getBoundingClientRect().top + (listBody ? listBody.scrollTop : 0);
  let posTop = 0;
  let shouldScroll = false;

  if (elementPos.top < offsetTop) {
    posTop = elementPositionY - offsetTop;
    shouldScroll = true;
  }

  if (elementPos.top + elementPos.height > offsetBottom) {
    posTop = elementPositionY + elementPos.height - offsetBottom;
    shouldScroll = true;
  }

  if (shouldScroll) {
    listBody?.scrollTo({
      top: posTop,
      behavior,
    });
  }
}
