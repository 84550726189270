import React, { ChangeEvent, Component } from 'react';
import AppContext, * as Context from '../../../common/contexts/AppContext';
import ComboBox from '../../controls/ComboBox/ComboBox';
import ToggleSwitch from '../../controls/ToggleSwitch/ToggleSwitch';
import Accordion from '../../controls/Accordion/Accordion';
import Button from '../../controls/Button/Button';
import { THEME_OPTIONS } from '../../../common/configs/ThemeOptions';
import { WithTranslation } from 'react-i18next';
import { LANGUAGE_OPTIONS } from '../../../common/configs/LanguageOptions';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { HOUR_OPTIONS } from '../../../common/configs/HourOptions';
import dayjsHelper from '../../../common/helpers/dayjsHelper';

type Props = WithTranslation & {
  history: any;
};

class DeviceOverrides extends Component<Props> {
  context!: React.ContextType<typeof AppContext>;
  constructor(props: Props) {
    super(props);
  }

  restoreDefaults = () => {
    // Remove all data from local storage by going over every account setting property
    // and setting the respective field to `null`.
    //
    // We do it this way to make sure that if new fields get added, they get set to `null` as well.
    const localSettings: Context.LocalSettings = {
      ...this.context.loggedUser!.accountSettings,
    };
    for (const k in localSettings) {
      if (Object.prototype.hasOwnProperty.call(localSettings, k)) {
        const key = k as keyof Context.LocalSettings;
        localSettings[key] = null;
      }
    }
    this.context.loggedUser?.setLocalSettings(localSettings);
  };

  hasOverrides = (): boolean => {
    const localSettings = this.context.loggedUser!.localSettings;

    // Filter out the 'experiments' key and check for non-null values
    return Object.entries(localSettings).some(
      ([key, value]) => key !== 'experiments' && value !== null,
    );
  };

  setLocalLanguage = (_: unknown, value: unknown) => {
    this.context.loggedUser?.setLocalSettings({ language: String(value) });
  };

  setLocalHourFormat = (_: unknown, value: unknown) => {
    this.context.loggedUser?.setLocalSettings({ timeFormat: Number(value) });
  };

  setLocalTheme = (_: unknown, value: unknown) => {
    this.context.loggedUser?.setLocalSettings({ theme: String(value) });
  };

  setLocalHighContrast = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setLocalSettings({
      highContrast: event.target.checked,
    });
  };

  setLocalReducedMotion = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setLocalSettings({
      reducedMotion: event.target.checked,
    });
  };

  setLocalColorBlindHelpers = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setLocalSettings({
      colorBlindHelpers: event.target.checked,
    });
  };

  changeLanguage = (_: unknown, value: unknown) => {
    const newLang = String(value);
    this.props.i18n.changeLanguage(newLang);
    this.setState(
      {
        language: newLang,
      },
      () => {
        this.setLocalLanguage(_, value);
      },
    );
  };

  changeChangeHourFormat = (_: unknown, value: unknown) => {
    const newHourFormat = String(value);

    this.setState(
      {
        hourFormat: newHourFormat,
      },
      () => {
        this.setLocalHourFormat(_, value);
      },
    );
  };

  render() {
    const { t } = this.props;
    const loggedUser = this.context.loggedUser!;
    const localSettings = loggedUser.localSettings;
    const appSettings = this.context.appSettings;

    const translatedThemeOptions = THEME_OPTIONS.map((option) => ({
      ...option,
      label: t(`themeOptions.${option.value}`),
    }));

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-2xs">
            <Accordion
              accordionSlug="device-language"
              isOpen={false}
              iconClasses="fal fa-globe icon"
              title={t('language.title')}
              subheading={t('language.subtitle')}
              history={this.props.history}
            >
              <div className="accordion-row">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column pb-xs">
                    <span className="fal fa-language icon"></span>
                  </div>
                  <div className="column fill pb-xs">
                    <span>{t('language.displayLanguage')}</span> <br />
                  </div>
                  <div className="column pb-2xs">
                    <ComboBox
                      id="language-select"
                      label={t('language.chooseLanguage')}
                      title={t('language.chooseLanguage')}
                      value={appSettings.language}
                      options={LANGUAGE_OPTIONS}
                      onChange={this.changeLanguage}
                      formGroupClassname="pb-0 mb-0"
                      getValue={(option) => option.value}
                      getLabel={(option) => option.label}
                      srOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-row">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column pb-xs">
                    <span className="fal fa-clock icon"></span>
                  </div>
                  <div className="column fill pb-xs">
                    <span>{t('language.hourFormat')}</span> <br />
                  </div>
                  <div className="column pb-2xs">
                    <ComboBox
                      id="hour-format"
                      label={t('language.chooseHourFormat')}
                      title={t('language.chooseHourFormat')}
                      value={appSettings.timeFormat}
                      options={HOUR_OPTIONS} // Assume this is defined elsewhere in your code
                      onChange={this.changeChangeHourFormat}
                      formGroupClassname="pb-0 mb-0"
                      getValue={(option) => option.value}
                      getLabel={(option) =>
                        dayjsHelper(new Date()).format(
                          option && option.value == '12' ? 'hh:mm A' : 'HH:mm',
                        )
                      }
                      srOnly={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              accordionSlug="device-theme"
              isOpen={false}
              iconClasses="fal fa-brush icon"
              title={t('theme.title')}
              subheading={t('theme.subtitle')}
              history={this.props.history}
            >
              <div className="accordion-row">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column pb-xs">
                    <span className="fal fa-adjust icon"></span>
                  </div>
                  <div className="column fill pb-xs">
                    <span>{t('theme.interfaceMode')}</span>{' '}
                    {/* Translated text */}
                    <br />
                  </div>
                  <div className="column pb-2xs">
                    <ComboBox
                      id="toggle-theme"
                      label={t('theme.theme')}
                      value={appSettings.theme}
                      disabled={appSettings.highContrast}
                      options={translatedThemeOptions}
                      onChange={this.setLocalTheme}
                      formGroupClassname="pb-0 mb-0"
                      getValue={(op) => op.value}
                      getLabel={(op) => op?.label}
                      srOnly={true}
                    />
                  </div>
                </div>
              </div>
              {/* Repeat similar pattern for other translatable strings */}
              {/* <div className="accordion-row">
                <div className="flex-row squeeze flex-v-center">
                  <div className="column fill pl-2xs pb-xs">
                    <CheckBox
                      id={'useHighContrastLocal'}
                      name={'useHighContrastLocal'}
                      checked={appSettings.highContrast}
                      label={'Enforce high contrast'}
                      className={'mr-sm'}
                      disabled={false}
                      changeMethod={this.setLocalHighContrast}
                    />
                  </div>
                </div>
              </div> */}
            </Accordion>
            <Accordion
              accordionSlug="device-accessibility"
              isOpen={false}
              iconClasses="fal fa-universal-access icon"
              title={t('accessibility.title')}
              subheading={t('accessibility.subtitle')}
              history={this.props.history}
            >
              <ToggleSwitch
                id="reducedMotionDevice"
                name="reducedMotionDevice"
                changeMethod={this.setLocalReducedMotion}
                isChecked={appSettings.reducedMotion}
                multiline={true}
                tight={true}
              >
                <label htmlFor="reducedMotionDevice">
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column py-0">
                      <span className="fal fa-stars icon"></span>
                    </div>
                    <div className="column py-0">
                      <span>{t('accessibility.reduceMotion')}</span>{' '}
                      {/* Translated label */}
                      <br />
                      <small className="faint-text">
                        {t('accessibility.reduceMotionDesc')}{' '}
                        {/* Translated description */}
                      </small>
                    </div>
                    <span className="state">
                      {appSettings.reducedMotion ? t('On') : t('Off')}{' '}
                      {/* Translated state */}
                    </span>
                  </div>
                </label>
              </ToggleSwitch>
              <ToggleSwitch
                id="colorBlindHelpersDevice"
                name="colorBlindHelpersDevice"
                changeMethod={this.setLocalColorBlindHelpers}
                isChecked={appSettings.colorBlindHelpers}
                multiline={true}
                tight={true}
              >
                <label htmlFor="colorBlindHelpersDevice">
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column py-0">
                      <span className="fal fa-tint-slash icon"></span>
                    </div>
                    <div className="column py-0">
                      <span>{t('accessibility.colorBlindHelpers')}</span>{' '}
                      {/* Translated label for color blind helpers */}
                      <br />
                      <small className="faint-text">
                        {t('accessibility.colorBlindHelpersDesc')}{' '}
                        {/* Translated description for color blind helpers */}
                      </small>
                    </div>
                  </div>
                  <span className="state">
                    {appSettings.colorBlindHelpers ? t('On') : t('Off')}{' '}
                    {/* Translated state */}
                  </span>
                </label>
              </ToggleSwitch>
            </Accordion>
            {this.hasOverrides() && (
              <Accordion
                accordionSlug="active-overrides"
                isOpen={true}
                disabled={true}
                softDisabled={true}
                hideArrow={true}
                iconClasses="fal fa-exclamation-circle icon"
                title={t('overrides.activeOverrides')}
                subheading={t('overrides.oneOverride')}
                history={this.props.history}
              >
                <div className="accordion-row">
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column fill pb-xs">
                      <Button
                        className="secondary-button"
                        onClick={this.restoreDefaults}
                      >
                        {t('overrides.clearOverrides')}{' '}
                        {/* Translated button text */}
                      </Button>
                    </div>
                  </div>
                </div>
              </Accordion>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('deviceOverrides')(DeviceOverrides);
DeviceOverrides.contextType = AppContext;
