import React from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';
import { NotificationMessage } from '../../../../common/contexts/AppContext';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import ctrlDetect from '../../../../common/helpers/ctrlDetect';
import ContextMenu from '../../../controls/ContextMenu/ContextMenu';

interface Props extends WithTranslation {
  cardId: string;
  disabled?: boolean;
  droppedAttachments: File[] | null;
  handleNewAttachment: (newAttachment: AttachmentDTO) => void;
  uploadClipboardData: boolean;
  clipboardData: File;
  setUploadClipboardData: (value: boolean) => void;
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  clearDroppedAttachments: () => void;
}

interface State {
  formStatus: TRequestStatus;
  loader: boolean;
  isLarge: boolean;
}

class FileInput extends React.Component<Props, State> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      formStatus: 'idle',
      loader: false,
      isLarge: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (this.props.uploadClipboardData && !prevProps.uploadClipboardData) {
      this.uploadAttachment(this.props.clipboardData);
      this.props.setUploadClipboardData(false);
    }
    if (this.props.droppedAttachments && !prevProps.droppedAttachments) {
      this.uploadDroppedAttachments(this.props.droppedAttachments);
      this.props.clearDroppedAttachments();
    }
  }

  handleClick = (event: React.MouseEvent) => {
    // Using the method listed here: https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications#using_hidden_file_input_elements_using_the_click_method
    // if this does not work on some browsers, there is another method listed there using a label which might be worth testing.
    this.inputRef.current!.click();
  };

  handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      this.setState({
        formStatus: 'loading',
      });

      Array.from(event.currentTarget.files).map((entry, index) => {
        this.uploadAttachment(entry);
      });

      event.currentTarget.value = '';
    }
  };

  uploadDroppedAttachments = async (files: File[]) => {
    Array.from(files).map((entry, index) => {
      this.uploadAttachment(entry);
    });
  };

  uploadAttachment = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response: any = await this.xmlHttpRequest(
        this.props.cardId,
        formData,
      );
      if (response.status === 413 || response.errors) {
        this.setState({ isLarge: true });
        showErrorNotifications(response.errors, this.props.setMessages);
      } else if (response && !response.errors) {
        let newAttachment = JSON.parse(response.responseText) as AttachmentDTO;
        this.props.handleNewAttachment(newAttachment);
        this.setState({ isLarge: false });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        formStatus: 'error',
      });
      showErrorNotifications(error, this.props.setMessages);
    }

    this.setState({
      formStatus: 'success',
      loader: false,
    });
  };

  xmlHttpRequest(cardId: string, formData: FormData) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.withCredentials = true;

      req.addEventListener('progress', (event) => {
        console.log('download progress', event);
      });
      req.addEventListener('load', (event) => {
        console.log('download load', event);
        resolve(req);
      });
      req.addEventListener('error', (event) => {
        console.log('download error', event);
        reject(event);
      });
      req.addEventListener('abort', (event) => {
        console.log('download abort', event);
        reject(event);
      });

      // todo: use upload progress events to inform the user how much of his attachment
      // has been sent to the server.
      //
      // Note! When the upload is finished, the request is still in progress, until the
      // server returns a response.
      req.upload.addEventListener('progress', (event) => {
        console.log('upload progress', event);
        this.setState({
          loader: true,
        });
      });
      req.upload.addEventListener('load', (event) => {
        console.log('upload load', event);
      });
      req.upload.addEventListener('error', (event) => {
        console.log('upload error', event);
        reject(event);
      });
      req.upload.addEventListener('abort', (event) => {
        console.log('upload abort', event);
        reject(event);
      });

      req.open(
        'POST',
        `${process.env.REACT_APP_API_BASE_URL}/card/${cardId}/attachment`,
      );
      const conId = sessionStorage.getItem('borddo-wsconid');
      if (conId) {
        req.setRequestHeader('borddo-wsconid', conId);
      }
      req.send(formData);
    });
  }

  render() {
    const { formStatus, loader } = this.state;
    const { t, disabled } = this.props;

    return (
      <div className="fill">
        <input
          ref={this.inputRef}
          onChange={this.handleFileChange}
          style={{ display: 'none' }}
          type="file"
          multiple
        />
        <div className="flex-v-center pl-2xs py-2xs">
          {loader ? (
            <div>
              <span className="loader" />{' '}
              <small className="faint-text">{t('processing')}</small>
            </div>
          ) : (
            <>
              <button
                className="primary-button mr-2xs"
                type="button"
                onClick={this.handleClick}
                disabled={disabled || formStatus === 'loading'}
              >
                <span className="fas fa-paperclip icon"></span>
                <span>{t('attachFiles')}</span>
              </button>
              <ContextMenu
                dept={0}
                title="Info"
                triggerContent={
                  <>
                    <span className="fas fa-info-circle"></span>
                  </>
                }
                triggerClassDefault="ghost-button"
                triggerClassActive="secondary-button"
              >
                <li>
                  <p
                    className="faint-text text-sm"
                    style={{ maxWidth: '200px', whiteSpace: 'normal' }}
                  >
                    <span>{t('dragFile')}</span>{' '}
                    <span className="text-700">{ctrlDetect()}+V</span>{' '}
                    <span>{t('toAttach')}</span>
                  </p>
                </li>
              </ContextMenu>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('fileInput')(FileInput);
