import React, { Component } from 'react';
import { errorImage } from '../../../common/helpers/errorImage';

export interface Props {
  thumbnailBackground?: string;
  classes?: string;
  avatarData?: string;
  thumbnailData?: string;
  title?: string;
}

interface State {
  hasError: boolean;
}

class Thumbnail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Props) {
    if (this.props.avatarData !== prevProps.avatarData) {
      this.setState({ hasError: false }); // Reset error state when avatarData changes
    }
    if (this.props.thumbnailData !== prevProps.thumbnailData) {
      this.setState({ hasError: false }); // Reset error state when avatarData changes
    }
  }

  errorPlaceholder = (event: HTMLImageElement) => {
    if (!this.state.hasError) {
      this.setState({
        hasError: true,
      });
      event.src = this.generateSVG();
    }
  };

  hashStringToColor = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).slice(-2);
    }
    return color;
  };

  generateSVG = (): string => {
    if (!this.props.title) {
      return 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100%" height="100%" fill="none"/></svg>';
    }

    const title = this.props.title!;
    const initial = this.getValidInitialCharacter(title);

    // Default background and text colors
    let bgColor = this.hashStringToColor(title);
    let textColor = '#ffffff'; // Default text color is white

    // Function to determine if a color is light
    const isLightColor = (color: string): boolean => {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luminance > 180; // Adjust this threshold as needed
    };

    // Adjust background and text color if initial is a surrogate pair (emoji)
    if (initial.length > 1) {
      bgColor = '#111111';
      textColor = '#ffffff';
    } else if (isLightColor(bgColor)) {
      textColor = '#111111';
    }

    const svgContent = `
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" style="background-color:${bgColor};">
        <text x="50%" y="50%" font-size="50" font-family="Arial, Helvetica, sans-serif" font-weight="700" dy=".35em" text-anchor="middle" fill="${textColor}">${initial}</text>
      </svg>
    `;

    try {
      return `data:image/svg+xml,${encodeURIComponent(svgContent)}`;
    } catch (error) {
      return 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100%" height="100%" fill="none"/></svg>';
    }
  };

  // Function to get a valid initial character, including handling of surrogate pairs
  getValidInitialCharacter = (str: string): string => {
    let initial = str.charAt(0);
    const firstCodePoint = initial.codePointAt(0);

    // If the first character is a high surrogate, and the next one is a low surrogate, combine them
    if (
      firstCodePoint !== undefined &&
      firstCodePoint >= 0xd800 &&
      firstCodePoint <= 0xdbff
    ) {
      const secondChar = str.charAt(1);
      const secondCodePoint = secondChar.codePointAt(0);

      if (
        secondCodePoint !== undefined &&
        secondCodePoint >= 0xdc00 &&
        secondCodePoint <= 0xdfff
      ) {
        // Combine the high and low surrogates into a single character
        initial = str.slice(0, 2);
      }
    }

    return initial;
  };

  avatarURLBuilder = (): string => {
    if (this.props.avatarData) {
      return '/assets/avatars/' + this.props.avatarData;
    }
    return this.generateSVG();
  };

  thumbnailURLBuilder = (): string => {
    if (this.props.thumbnailData) {
      return '/assets/thumbnails/' + this.props.thumbnailData;
    }
    return this.generateSVG();
  };

  render() {
    return (
      <React.Fragment>
        <img
          className={['thumbnail', this.props.classes].join(' ')}
          src={
            this.props.avatarData
              ? this.avatarURLBuilder()
              : this.thumbnailURLBuilder()
          }
          onError={(e) => this.errorPlaceholder(e.currentTarget)}
          title={this.props.title}
          alt={this.props.title || 'thumbnail'}
        />
      </React.Fragment>
    );
  }
}

export default Thumbnail;
