import { UserDTO } from '../api/dtos/User';
import { normalizeOnboardingData } from './normalizeOnboardingData';

type TOnboarding = {
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  organizingWith: 'justMe' | 'team' | undefined;
  peopleCount: number;
  goal: 'personal' | 'work' | undefined;
  boardName: string;
  columns: string[];
  tasks: string[];
  emails: string[];
};

export default function selectTutorial(loggedUser: UserDTO) {
  const normalizedOnboarding: TOnboarding = normalizeOnboardingData(
    loggedUser.onboarding,
  );

  switch (normalizedOnboarding.organizingWith) {
    case 'justMe':
      return 'solo';
    case 'team':
      return 'teamOwner';
    default:
      return 'invited';
  }
}
