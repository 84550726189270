export const getFilteredItems = <T>(
  filterValue: string,
  availableFilters: string[],
  itemList: T[],
) => {
  let filteredItems = itemList;
  const normalizedValue = (string: string) =>
    string
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '');
  filterValue = normalizedValue(filterValue);

  if (filterValue !== '') {
    filteredItems = itemList.filter((entity: T) => {
      return availableFilters.some((filterEntry) => {
        const nestedEntry = filterEntry.split('.');

        if (nestedEntry.length === 1) {
          let entityPropertyValue = entity[filterEntry as keyof T];
          return normalizedValue(String(entityPropertyValue)).includes(
            filterValue,
          );
        } else if (
          nestedEntry.length === 2 &&
          entity[nestedEntry[0] as keyof T]
        ) {
          let entityPropertyValue =
            entity[nestedEntry[0] as keyof T][
              nestedEntry[1] as keyof T[keyof T]
            ];
          return normalizedValue(String(entityPropertyValue)).includes(
            filterValue,
          );
        } else {
          return false;
        }
      });
    });
  }

  return filteredItems;
};
