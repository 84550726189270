import Joi from 'joi';

export const customEmailValidation = (
  value: string,
  helpers: Joi.CustomHelpers,
) => {
  // Split the email to extract the domain part
  const domain = value.split('@')[1];
  if (!domain) {
    return helpers.error('string.email');
  }

  // Split the domain to extract the TLD
  const tld = domain.split('.').pop();
  if (!tld || tld.length < 2) {
    return helpers.error('string.email');
  }

  return value; // Validation passes
};
