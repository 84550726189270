import {
  AddCardDTO,
  CardDTO,
  ListCardActivitiesAndCommentsDTO,
  ListCommentsDTO,
} from '../dtos/Card';
import { TAssignActionTag } from '../../types/Tag';
import requests from './baseRequest';

export async function addCard(
  columnId: string,
  card: AddCardDTO,
): Promise<CardDTO> {
  const result = await requests.post(`column/${columnId}/card`, card);
  return await result.json();
}

export async function archiveCard(id: string): Promise<void> {
  await requests.patch(`card/${id}/archive`);
}

export async function deleteCard(id: string): Promise<void> {
  await requests.delete(`card/${id}`);
}

export async function restoreCard(
  cardId: string,
  columnId: string,
): Promise<void> {
  await requests.patch(`card/${cardId}/restore`, { columnId });
}

export async function listArchivedCards(
  boardId: string,
): Promise<{ cards: CardDTO[] }> {
  const result = await requests.get(`board/${boardId}/archivedCards`);
  return await result.json();
}

export async function moveCard(id: string, columnId: string, index: number) {
  await requests.patch(`card/${id}/move`, { columnId, index });
}

export async function addMemberToCard(
  cardId: string,
  userId: string,
): Promise<void> {
  await requests.patch(`card/${cardId}/assign`, { userId });
}

export async function removeMemberFromCard(
  cardId: string,
  userId: string,
): Promise<void> {
  await requests.patch(`card/${cardId}/unassign`, { userId });
}

export async function updateCard(
  id: string,
  propToUpdate: { [key: string]: string | null | number },
): Promise<CardDTO> {
  const result = await requests.patch(`card/${id}`, propToUpdate);
  return await result.json();
}

export async function setCardTag(
  id: string,
  tagId: string,
  action: TAssignActionTag,
): Promise<void> {
  await requests.patch(`card/${id}/${action}`, { tagId });
}

export async function listCardActivitiesAndComments(
  cardId: string,
  nextPage?: string | null,
): Promise<ListCardActivitiesAndCommentsDTO> {
  const result = await requests.get(
    `card/${cardId}/activityAndComment${
      nextPage ? `?nextPage=${nextPage}` : ''
    }`,
  );
  return await result.json();
}

export async function listCardComments(
  cardId: string,
  nextPage?: string | null,
): Promise<ListCommentsDTO> {
  const result = await requests.get(
    `card/${cardId}/comment${nextPage ? `?nextPage=${nextPage}` : ''}`,
  );
  return await result.json();
}

type Attachment = {
  id: string;
  authorId: string | null;
  filename: string;
  size: number;
  contentType: string;
  createdAt: string;
};

export async function listAttachments(id: string): Promise<Attachment[]> {
  const result = await requests.get(`card/${id}/attachment`);
  return (await result.json()).cardAttachments;
}

export async function deleteAttachment(id: string): Promise<void> {
  await requests.delete(`attachment/${id}`);
}
