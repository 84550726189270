import Joi from 'joi';
import { Component, ChangeEvent, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import Button from '../../../controls/Button/Button';
import TextBox from '../../../controls/TextBox/TextBox';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import { forgotPassword } from '../../../../common/api/endpoints/user';
import PageMessage from '../../../partials/PageMessage/PageMessage';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import AppContext from '../../../../common/contexts/AppContext';
import LegalFooter from '../../../partials/Legal/LegalFooter';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import { customEmailValidation } from '../Registration/helper/customEmailValidation';

interface Props extends RouteComponentProps {}

interface State {
  status: TRequestStatus;
  errorMessage: string;
  formData: FormData;
  formErrors: FormErrors;
}

type FormErrors = {
  email?: string;
};

interface FormData {
  email: string;
}

class ForgotPass extends Component<Props, State> {
  formSchema = Joi.object({
    email: Joi.string()
      .required()
      .trim(true)
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .custom(customEmailValidation)
      .messages(FormErrorMsgs.string),
  });
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      errorMessage: '',
      formData: {
        email: '',
      },
      formErrors: {},
    };
  }

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = this.state.formData;

    this.setState({
      formData: {
        ...formData,
        [event.target.name]: event.target.value,
      },
    });
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors,
      });

      return false;
    }

    return true;
  };

  validateFormField = (field: string) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  updateFormError<K extends keyof FormData>(field: string, value: FormData[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isValid = this.validateForm();
    if (!isValid) return;

    this.setState({
      status: 'loading',
    });

    try {
      await forgotPassword(this.state.formData.email);
      this.setState({
        status: 'success',
      });
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      showErrorNotifications(error, this.context.notifications.setMessages);
      this.setState({
        status: 'error',
      });
    }
  };

  render() {
    return this.state.status !== 'success' ? (
      <div className="oobe-component">
        <div className="oobe-inner">
          <div className="login-component will-transition">
            <div className="login-container">
              <div className="flex-row fill">
                <div className="column">
                  <div className="special-card fade-in-1 p-large">
                    <div
                      className="oobe-content px-sm py-md"
                      style={{
                        minHeight: '0',
                      }}
                    >
                      <div>
                        <img
                          className="thumbnail large radius px-sm"
                          style={{
                            borderRadius: '5px',
                            background:
                              'radial-gradient(circle at bottom right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 85%)',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          }}
                          src={'/assets/borddo_onboarding.svg'}
                          alt="Borddo"
                        />
                      </div>
                      <br />
                      <ul className="error-list"></ul>
                      <form
                        onSubmit={this.handleSubmit}
                        noValidate={true}
                      >
                        <TextBox
                          label="Email"
                          type="email"
                          name="email"
                          id="email"
                          onChange={this.handleInput}
                          required={true}
                          br={true}
                          onBlur={(ev) =>
                            this.validateFormField(ev.target.name)
                          }
                          error={this.state.formErrors.email}
                        />
                        <Button className="primary-button">
                          <RequestStatus status={this.state.status} />
                          <span className="text">Reset password</span>
                        </Button>
                      </form>
                      <br />
                      <LinkButton
                        to="/"
                        className="link-button"
                      >
                        <span className="text">Cancel</span>
                      </LinkButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-row fill">
                <div className="column">
                  <LegalFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <PageMessage>
        <h1 className="primary-title normalcase pb-xs">
          Reset Password Request
        </h1>
        <p>A reset password link has been sent to your email.</p>
        <ul className="control-list-component flex-h-center">
          <li>
            <Button
              className="primary-button"
              onClick={() => this.props.history.push('/')}
            >
              <span className="text">Return to login</span>
            </Button>
          </li>
        </ul>
      </PageMessage>
    );
  }
}

export default ForgotPass;
