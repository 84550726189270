export default function convertBytes(sizeInBytes: number): {
  value: number;
  unit: string;
} {
  let value: number;
  let unit: string;

  if (sizeInBytes >= 1000 * 1000 * 1000) {
    // Convert to GB if the size is at least one GB
    value = Math.floor((sizeInBytes / (1024 * 1024 * 1024)) * 100) / 100;
    unit = 'GB';
  } else if (sizeInBytes >= 1000 * 1000) {
    // Convert to MB if the size is at least one MB
    value = Math.floor((sizeInBytes / (1024 * 1024)) * 100) / 100;
    unit = 'MB';
  } else {
    // Convert to KB for anything smaller than one MB
    value = Math.floor((sizeInBytes / 1024) * 100) / 100;
    unit = 'KB';
  }

  return { value, unit };
}
