import React from 'react';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggableRubric,
  DroppableProvided,
} from '../../../vendors/wavemyth/react-beautiful-dnd/src';
import { dndReorder } from '../../../common/helpers/dnd';
import { withContextAdapter } from '../ContextAdapter/withContextAdapter';
import BoardContext, {
  IBoardContext,
} from '../../../common/contexts/BoardContext';

interface ContextProps {
  priorities: PriorityDTO[];
}
interface ExternalProps {
  reorderPriorities: (
    priorities: PriorityDTO[],
    priorityId: string,
    destinationIndex: number,
  ) => void;
  fixedPositionParent?: boolean;
}
interface Props extends ContextProps, ExternalProps {}

interface State {
  renderKey: number;
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
  marginRight: `5px`,
  ...draggableStyle,
});

class PrioritiesListOrderable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      renderKey: 0,
    };
  }

  onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = dndReorder(
      this.props.priorities,
      result.source.index,
      result.destination.index,
    );

    const priorityId = this.props.priorities[result.source.index].id;
    this.props.reorderPriorities(items, priorityId, result.destination.index);
  };

  onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(10);
    }
  };

  render() {
    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
      >
        <Droppable
          droppableId="droppable"
          renderClone={
            this.props.fixedPositionParent
              ? (
                  provided: DraggableProvided,
                  snapshot: DraggableStateSnapshot,
                  rubric: DraggableRubric,
                ) => (
                  <ul
                    className="control-list-component vertical dragged-item-list"
                    style={{ zIndex: 9999999, position: 'fixed' }}
                  >
                    <li
                      className="fill"
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <div className="ghost-button">
                        <span className="fas icon fa-grip-lines"></span>
                        <span
                          className={`icon fas fa-circle accent-text-${
                            this.props.priorities[rubric.source.index].color
                          }`}
                        ></span>{' '}
                        <span className="text">
                          {this.props.priorities[rubric.source.index].name}
                        </span>
                      </div>
                    </li>
                  </ul>
                )
              : undefined
          }
          getContainerForClone={
            this.props.fixedPositionParent
              ? () => document.querySelector('.main-component') as HTMLElement
              : undefined
          }
        >
          {(provided: DroppableProvided) => (
            <ul
              id="orderablePriorities"
              className="control-list-component vertical"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {this.props.priorities.map(
                (priority: PriorityDTO, index: number) => {
                  return (
                    <Draggable
                      key={priority.id}
                      draggableId={priority.id!}
                      index={index}
                    >
                      {(
                        provided: DraggableProvided,
                        snapshot: DraggableStateSnapshot,
                      ) => {
                        return (
                          <li
                            className="fill"
                            key={priority.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="ghost-button">
                              <span className="fas icon fa-grip-lines"></span>
                              <span
                                className={`icon fas fa-circle accent-text-${priority.color}`}
                              ></span>{' '}
                              <span className="text">{priority.name}</span>
                            </div>
                          </li>
                        );
                      }}
                    </Draggable>
                  );
                },
              )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  PrioritiesListOrderable,
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      priorities: ctx.board.priorities,
    };
  },
);
