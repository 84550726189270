import React from 'react';

export default class PageMessage extends React.Component {
  render() {
    return (
      <div className="flex-row pt-md mx-0">
        <div className="column flex-h-center-self px-sm">
          <div className="card text-center">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
