export default function isSupportedImageMimeType(mimeType: string): boolean {
  const supportedMimeTypes: Set<string> = new Set([
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/bmp',
  ]);

  return supportedMimeTypes.has(mimeType);
}
