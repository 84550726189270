import { TColor } from '../types/Color';

export const PRIORITY_COLORS: TColor[] = [
  {
    value: 'pink',
    label: 'Pink',
    symbol: 'fas fa-umbrella',
  },
  {
    value: 'red',
    label: 'Red',
    symbol: 'fas fa-apple-alt',
  },
  {
    value: 'orange',
    label: 'Orange',
    symbol: 'fas fa-fire',
  },
  {
    value: 'yellow',
    label: 'Yellow',
    symbol: 'fas fa-lemon',
  },
  {
    value: 'green',
    label: 'Green',
    symbol: 'fas fa-leaf',
  },
  {
    value: 'cyan',
    label: 'Cyan',
    symbol: 'fas fa-cloud',
  },
  {
    value: 'blue',
    label: 'Blue',
    symbol: 'fas fa-tint',
  },
  {
    value: 'purple',
    label: 'Purple',
    symbol: 'fas fa-rocket',
  },
];
