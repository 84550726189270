//Touched by copilot
import { LexicalEditor } from 'lexical';
import React, { Component } from 'react';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { DEFAULT_AVATAR } from '../../../../common/configs/appDefaults';
import { TAG_COLORS } from '../../../../common/configs/tag';
import { NotificationMessage } from '../../../../common/contexts/AppContext';
import { copyToClipboard } from '../../../../common/helpers/copyToClipboard';
import { getColorSymbol } from '../../../../common/helpers/getColorSymbol';
import Button from '../../../controls/Button/Button';
import ContextMenu from '../../../controls/ContextMenu/ContextMenu';
import TextArea from '../../../controls/TextArea/TextArea';
import ErrorList from '../../../error/ErrorList/ErrorList';
import MemberMenu from '../../../menus/Member/MemberMenu';
import PriorityContext from '../../../menus/Priority/PriorityContext';
import TagContext from '../../../menus/Tag/TagContext';
import Thumbnail from '../../Thumbnail/Thumbnail';
import RemoveMemberMenu from '../BoardCard/BoardCardContent/partial/RemoveMemberMenu';
import RemoveTagMenu from '../BoardCard/BoardCardContent/partial/RemoveTagMenu';
import CardActivity from './CardActivity';
import AddCardComment from './AddCardComment';
import { CommentDTO } from '../../../../common/api/dtos/Comment';
import { ActivityDTO } from '../../../../common/api/dtos/Activity';
import { THistory } from '../../../../common/types/Activity';
import { BoardDTO } from '../../../../common/api/dtos/Board';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';
import { IBoardCurrentUser } from '../../../../common/interfaces/BoardCurrentUser';
import {
  MsgCreateComment,
  MsgUpdateCard,
  MsgUpdateComment,
  MsgDeleteComment,
  MsgWs,
} from '../../../pages/Board/BoardProvider/wsHandlers';
import {
  listAttachments,
  listCardActivitiesAndComments,
  listCardComments,
  deleteAttachment,
} from '../../../../common/api/endpoints/card';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import FileInput from './FileInput';
import ImageViewer from './ImageViewer';
import isSupportedImageMimeType from '../../../../common/helpers/isSupportedImageMimeType';
import convertBytes from '../../../../common/helpers/convertBytes';
import Dialog from '../../../controls/Dialog/Dialog';
import AddCardDescription from './AddCardDescription';
import { listActivities } from '../../../../common/api/endpoints/activity';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { Trans, WithTranslation } from 'react-i18next';
import dayjsHelper from '../../../../common/helpers/dayjsHelper';

type DisabledProps = {
  disabled: true;
  onTitleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  saveTitle?: () => Promise<void>;
  onDescriptionChange?: (value: string, cardId?: string) => void;
  columnId?: string | null;
};

type EnabledProps = {
  disabled: false;
  onTitleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  saveTitle: () => Promise<void>;
  onDescriptionChange: (value: string) => void;
  columnId: string | null;
  handleAddAttachment?: () => void;
  handleRemoveAttachment?: () => void;
  handleAddComment?: () => void;
  handleDeleteComment?: () => void;
};

type Props = {
  cardNrPrefix: string;
  boardCard: BoardCard;
  tags: TagDTO[];
  priorities: PriorityDTO[];
  members: (MemberDTO | InviteeDTO)[];
  serverErrors: string[];
  descriptionEditor: LexicalEditor | null;
  handleFlyoutClose: () => void;
  handleCloseButtonKeyDown: (
    event: React.KeyboardEvent<HTMLButtonElement>,
  ) => void;
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  setDescriptionEditor: (editor: LexicalEditor | null) => void;
  columnTitles: Partial<BoardDTO>[];
  boardUser: IBoardCurrentUser;
  handleAddAttachment?: () => void;
  handleRemoveAttachment?: () => void;
  handleAddComment?: () => void;
  handleDeleteComment?: () => void;

  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;

  pasteAttachment?: boolean;
  clipboardData?: File;
  setPasteAttachment?: (value: boolean) => void;
  setUploadClipboardData?: (value: boolean) => void;
  uploadClipboardData?: boolean;
} & (DisabledProps | EnabledProps) &
  WithTranslation;

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];

  description: string;
  tagIds: string[];
  priorityId: string | null;
}

export interface IActivity extends ActivityDTO {
  key: string;
}

export interface IComment extends CommentDTO {
  key: string;
}

export interface GroupedActivityItem {
  date: string;
  items: (IActivity | IComment)[];
}

export interface State {
  groupedActivityItems: GroupedActivityItem[];
  activityNextPage: string | null;
  commentsNextPage: string | null;
  allNextPage: string | null;
  rawActivityItems: (IActivity | IComment)[];
  activityStatus: TRequestStatus;
  updateDescription: boolean;
  cancelDescription: boolean;
  showUpdatingBtns: boolean;
  historyType: THistory;
  scrollToTop: boolean;
  attachments: AttachmentDTO[];
  comments: CommentDTO[];
  viewedAttachment: null | AttachmentDTO;
  viewedDeletionAttachment: null | AttachmentDTO;
  newCard: Boolean;
  showDeletionPrompt: boolean;
  activitiesItems: (IActivity | IComment)[];
  commentsItems: IComment[];
  groupedActivities: GroupedActivityItem[];
  groupedComments: GroupedActivityItem[];
  isDraggingFileOver: boolean;
  droppedAttachments: File[] | null;
  cardNameHeight: number;
}

class BoardCardFlyoutTemplate extends Component<Props, State> {
  dragTarget: React.RefObject<HTMLDivElement>;
  flyoutCloseButtonRef: React.RefObject<HTMLButtonElement>;
  reachedBottom: boolean;
  timeoutId: NodeJS.Timeout | null = null;
  timeoutIdNo: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.dragTarget = React.createRef();
    this.flyoutCloseButtonRef = React.createRef();
    this.reachedBottom = false;
    let storageHistoryType = localStorage.getItem(
      `historyType-${this.props.boardUser.id}`,
    );

    this.state = {
      groupedActivityItems: [],
      rawActivityItems: [],
      activityStatus: 'idle',
      updateDescription: false,
      cancelDescription: false,
      showUpdatingBtns: false,
      historyType: storageHistoryType ? JSON.parse(storageHistoryType) : 'all',
      scrollToTop: false,
      attachments: [],
      comments: [],
      viewedAttachment: null,
      viewedDeletionAttachment: null,
      newCard: false,
      showDeletionPrompt: false,
      activitiesItems: [],
      commentsItems: [],
      groupedActivities: [],
      groupedComments: [],
      isDraggingFileOver: false,
      droppedAttachments: null,
      activityNextPage: null,
      commentsNextPage: null,
      allNextPage: null,
      cardNameHeight: 0,
    };
  }

  componentDidMount(): void {
    this.getCardActivityCommentsItems();
    this.getCardActivityItems();
    this.getCardComments();

    this.focusFlyoutCloseButton();
    this.getCardAttachments();

    this.props.addWsListener('add_comment', this.handleWsAddComment);
    this.props.addWsListener('update_comment', this.handleWsUpdateComment);
    this.props.addWsListener('delete_comment', this.handleWsDeleteComment);
    this.props.addWsListener('add_attachment', this.handleWsUpdateAttachment);
    this.props.addWsListener(
      'remove_attachment',
      this.handleWsUpdateAttachment,
    );
    document.addEventListener('dragover', this.handleDragOver, false);
    document.addEventListener('dragenter', this.handleGlobalDragEnter, false);
    document.addEventListener('dragleave', this.handleGlobalDragLeave, false);
    document.addEventListener('drop', this.handleDrop, false);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    if (prevProps.boardCard.id !== this.props.boardCard.id) {
      this.focusFlyoutCloseButton();
      this.getCardAttachments();
      this.setState(
        {
          rawActivityItems: [],
          groupedActivityItems: [],
          allNextPage: null,
          activityNextPage: null,
          commentsNextPage: null,
          scrollToTop: true,
          showUpdatingBtns: false,
          newCard: true,
        },
        () => {
          this.getCardActivityCommentsItems();
          this.getCardActivityItems();
          this.getCardComments();
          this.reachedBottom = false;
        },
      );
    } else {
      if (
        (prevProps.boardCard.id !== this.props.boardCard.id ||
          (this.props.columnId &&
            prevProps.columnId !== this.props.columnId)) &&
        this.state.historyType !== 'comments'
      ) {
        this.updateCardActivityItems();
      }
    }
    if (
      prevState.attachments.length !== this.state.attachments.length ||
      prevProps.boardCard.assigneeIds !== this.props.boardCard.assigneeIds ||
      prevProps.boardCard.tagIds !== this.props.boardCard.tagIds ||
      prevProps.boardCard.description !== this.props.boardCard.description ||
      prevProps.boardCard.title !== this.props.boardCard.title
    ) {
      this.updateCardActivityItems();
    }

    if (
      prevState.rawActivityItems.length !== this.state.rawActivityItems.length
    ) {
      this.updateCardActivityItems();
    }
    if (prevState.newCard) {
      this.setState(
        {
          newCard: false,
        },
        () => {},
      );
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('dragover', this.handleDragOver, false);
    document.removeEventListener(
      'dragenter',
      this.handleGlobalDragEnter,
      false,
    );
    document.removeEventListener(
      'dragleave',
      this.handleGlobalDragLeave,
      false,
    );
    document.removeEventListener('drop', this.handleDrop, false);
    this.props.removeWsListener('add_comment', this.handleWsAddComment);
    this.props.removeWsListener('update_comment', this.handleWsUpdateComment);
    this.props.removeWsListener('delete_comment', this.handleWsDeleteComment);
    this.props.removeWsListener(
      'add_attachment',
      this.handleWsUpdateAttachment,
    );
    this.props.removeWsListener(
      'remove_attachment',
      this.handleWsUpdateAttachment,
    );
  }

  handleWsAddComment = (message: MsgWs) => {
    const addCommentMessage = message as MsgCreateComment;

    if (addCommentMessage.data.cardId === this.props.boardCard.id) {
      this.addComment(addCommentMessage.data);
    }
  };

  handleWsUpdateComment = (message: MsgWs) => {
    const updateCommentMessage = message as MsgUpdateComment;

    if (updateCommentMessage.data.cardId === this.props.boardCard.id) {
      this.updateComment(updateCommentMessage.data);
    }
  };

  handleWsDeleteComment = (message: MsgWs) => {
    const deleteCommentMessage = message as MsgDeleteComment;
    if (
      deleteCommentMessage.data.cardId === this.props.boardCard.id &&
      deleteCommentMessage.data.deletedAt
    ) {
      this.removeComment(deleteCommentMessage.data);
    }
  };

  handleWsUpdateTitleActivity = (message: MsgWs) => {
    const updateCardMessage = message as MsgUpdateCard;

    if (updateCardMessage.data.title !== this.props.boardCard.title) {
      this.updateCardActivityItems();
    }
  };

  handleWsUpdateAttachment = () => {
    this.getCardAttachments();
    this.updateCardActivityItems();
  };

  addComment = (commentData: CommentDTO) => {
    const newCommentObj = {
      id: commentData.id,
      content: commentData.content,
      authorId: commentData.authorId,
      createdAt: commentData.createdAt,
      updatedAt: commentData.updatedAt,
      key: crypto.randomUUID(),
    };
    const newRawActivityItems = [newCommentObj, ...this.state.commentsItems];
    const newGroupedActivityItems =
      this.groupActivitiesByDate(newRawActivityItems);

    const newRawActivityAndComments = [
      newCommentObj,
      ...this.state.rawActivityItems,
    ];
    const newGroupedActivityAndComments = this.groupActivitiesByDate(
      newRawActivityAndComments,
    );

    this.setState({
      groupedComments: newGroupedActivityItems,
      commentsItems: newRawActivityItems,
      rawActivityItems: newRawActivityAndComments,
      groupedActivityItems: newGroupedActivityAndComments,
    });
  };

  updateComment = (commentData: Partial<CommentDTO>) => {
    const rawActivityItems = [...this.state.commentsItems];
    const rawActivityAndCommentsItems = [...this.state.rawActivityItems];

    const comment = rawActivityItems.find(
      (c) => c.id === commentData.id,
    ) as CommentDTO;

    const commentInAll = rawActivityAndCommentsItems.find(
      (c) => c.id === commentData.id,
    ) as CommentDTO;

    commentInAll.content = commentData.content!;

    if (comment && commentData.content && commentData.updatedAt) {
      comment.content = commentData.content;
      comment.updatedAt = commentData.updatedAt;

      this.setState({
        groupedComments: this.groupActivitiesByDate(rawActivityItems),
        commentsItems: rawActivityItems,
      });
    }

    if (commentInAll && commentData.content && commentData.updatedAt) {
      commentInAll.content = commentData.content;
      commentInAll.updatedAt = commentData.updatedAt;

      this.setState({
        groupedActivityItems: this.groupActivitiesByDate(
          rawActivityAndCommentsItems,
        ),
        rawActivityItems: rawActivityAndCommentsItems,
      });
    }
  };

  removeComment = (commentData: Partial<CommentDTO>) => {
    const rawActivityItems = [...this.state.commentsItems];
    const rawActivityAndCommentsItems = [...this.state.rawActivityItems];
    const comment = rawActivityItems.find(
      (c) => c.id === commentData.id,
    ) as CommentDTO;

    const commentInAll = rawActivityAndCommentsItems.find(
      (c) => c.id === commentData.id,
    ) as CommentDTO;

    if (comment && commentData) {
      comment.deletedAt = commentData.deletedAt;
      comment.deletedBy = commentData.deletedBy;
      comment.content = '';

      this.setState({
        groupedComments: this.groupActivitiesByDate(rawActivityItems),
        commentsItems: rawActivityItems,
      });
    }

    if (commentInAll && commentData) {
      commentInAll.deletedAt = commentData.deletedAt;
      commentInAll.deletedBy = commentData.deletedBy;
      commentInAll.content = '';

      this.setState({
        groupedActivityItems: this.groupActivitiesByDate(
          rawActivityAndCommentsItems,
        ),
        rawActivityItems: rawActivityAndCommentsItems,
      });
    }
  };

  updateViewedAttachment(attachment: null | AttachmentDTO) {
    this.setState({
      viewedAttachment: attachment,
    });
    this.onOpenImageViewer();
  }

  updateViewedDeletionAttachment(attachment: null | AttachmentDTO) {
    this.setState({
      viewedDeletionAttachment: attachment,
    });
  }

  onOpenImageViewer = () => {
    const HTMLElement: HTMLDivElement | null =
      document.querySelector('.CONDUIT');
    HTMLElement!.style.overflowX = 'hidden';
  };

  onCloseImageViewer = () => {
    const HTMLElement: HTMLDivElement | null =
      document.querySelector('.CONDUIT');
    this.updateViewedAttachment(null);
    HTMLElement!.style.overflowX = '';
  };

  updateCardActivityItems = async () => {
    try {
      const data = await listCardActivitiesAndComments(
        this.props.boardCard.id,
        null,
      );
      let newRawActivityItems = [...this.state.rawActivityItems];

      for (let i = 0; i < data.items.length; i++) {
        const index = newRawActivityItems.findIndex(
          (item) => item.id === data.items[i].id,
        );

        if (index >= 0) {
          break;
        } else {
          newRawActivityItems = [
            { ...data.items[i], key: crypto.randomUUID() },
            ...newRawActivityItems,
          ];
        }
      }
      if (
        this.state.rawActivityItems[0] &&
        this.state.rawActivityItems[0].id !== newRawActivityItems[0].id
      ) {
        const groupedActivitiesAndComments =
          this.groupActivitiesByDate(newRawActivityItems);

        this.setState({
          groupedActivityItems: groupedActivitiesAndComments,
          rawActivityItems: newRawActivityItems,
        });
      }

      const newItem = { ...data.items[0], key: crypto.randomUUID() };

      const newActivitiesItems = [newItem, ...this.state.activitiesItems];

      if (
        this.state.activitiesItems[0] &&
        this.state.activitiesItems[0].id !== newItem.id &&
        !newItem.content
      ) {
        this.setState({
          groupedActivities: this.groupActivitiesByDate(newActivitiesItems),
          activitiesItems: newActivitiesItems,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getCardActivityCommentsItems = async () => {
    this.setState({
      activityStatus: 'loading',
    });
    try {
      const data = await listCardActivitiesAndComments(
        this.props.boardCard.id,
        this.state.allNextPage,
      );

      const updatedDataItemsWithKey = data.items.map((item) => {
        return {
          ...item,
          key: crypto.randomUUID(),
        };
      });

      const newRawActivityItems = this.state.allNextPage
        ? [...this.state.rawActivityItems, ...updatedDataItemsWithKey]
        : [...updatedDataItemsWithKey];

      const groupedActivitiesAndComments =
        this.groupActivitiesByDate(newRawActivityItems);

      this.setState(
        {
          groupedActivityItems: groupedActivitiesAndComments,
          rawActivityItems: newRawActivityItems,
          activityStatus: 'success',
          allNextPage: data.nextPage,
        },
        () => {
          this.resetReachedBottom();
        },
      );
    } catch (err) {
      console.log(err);
      this.setState({
        activityStatus: 'error',
      });
    }
  };

  getCardActivityItems = async () => {
    this.setState({
      activityStatus: 'loading',
    });
    try {
      const data = await listActivities(
        this.props.boardCard.id,
        this.state.activityNextPage,
      );

      const updatedDataItemsWithKey = data.activities.map((item) => {
        return {
          ...item,
          key: crypto.randomUUID(),
        };
      });

      const newRawActivityItems = this.state.activityNextPage
        ? [...this.state.activitiesItems, ...updatedDataItemsWithKey]
        : [...updatedDataItemsWithKey];

      const groupedActivities = this.groupActivitiesByDate(newRawActivityItems);

      this.setState(
        {
          activityStatus: 'success',
          activitiesItems: newRawActivityItems,
          groupedActivities: groupedActivities,
          activityNextPage: data.nextPage,
        },
        () => {
          this.resetReachedBottom();
        },
      );
    } catch (err) {
      console.log(err);
      this.setState({
        activityStatus: 'error',
      });
    }
  };

  getCardComments = async () => {
    this.setState({
      activityStatus: 'loading',
    });
    try {
      const data = await listCardComments(
        this.props.boardCard.id,
        this.state.commentsNextPage,
      );

      const updatedDataItemsWithKey = data.comments.map((item) => {
        return {
          ...item,
          key: crypto.randomUUID(),
        };
      });

      const newRawActivityItems = this.state.commentsNextPage
        ? [...this.state.commentsItems, ...updatedDataItemsWithKey]
        : [...updatedDataItemsWithKey];

      const groupedComments = this.groupActivitiesByDate(newRawActivityItems);

      this.setState(
        {
          groupedComments: groupedComments,
          commentsItems: newRawActivityItems,
          activityStatus: 'success',
          commentsNextPage: data.nextPage,
        },
        () => {
          this.resetReachedBottom();
        },
      );
    } catch (err) {
      console.log(err);
      this.setState({
        activityStatus: 'error',
      });
    }
  };

  groupActivitiesByDate = (items: (IActivity | CommentDTO)[]) => {
    const newGroupedArray: any = [];

    items.forEach((item) => {
      const date = new Date(item.createdAt);
      const formattedDate = dayjsHelper(date).format('MMMM Do, YYYY');
      const indexDateObj = newGroupedArray.findIndex(
        (i: any) => i.date === formattedDate,
      );

      if (indexDateObj === -1) {
        const newItemsArray = [];
        newItemsArray.push(item);
        newGroupedArray.push({ date: formattedDate, items: newItemsArray });
      } else {
        newGroupedArray[indexDateObj].items.push(item);
      }
    });

    return newGroupedArray;
  };

  getCardAttachments = async () => {
    this.setState({
      activityStatus: 'loading',
    });
    try {
      const attachments = await listAttachments(this.props.boardCard.id);

      this.setState({
        attachments,
        activityStatus: 'success',
      });
    } catch (err) {
      console.log(err);
      this.setState({
        activityStatus: 'error',
      });
    }
  };

  handleNewAttachment = (newAttachment: AttachmentDTO) => {
    this.setState((prevState) => {
      return {
        attachments: [...prevState.attachments, newAttachment],
      };
    });
    this.props.handleAddAttachment && this.props.handleAddAttachment();
  };

  deleteCardAttachment = async (attachmentId: string) => {
    this.setState({
      activityStatus: 'loading',
    });
    try {
      await deleteAttachment(attachmentId);

      this.setState((prevState) => {
        return {
          activityStatus: 'success',
          attachments: prevState.attachments.filter(
            (attachment) => attachment.id !== attachmentId,
          ),
        };
      });
      this.props.handleRemoveAttachment && this.props.handleRemoveAttachment();
    } catch (err) {
      console.log(err);
      this.setState({
        activityStatus: 'error',
      });
    }
  };

  focusFlyoutCloseButton = () => {
    requestAnimationFrame(() => {
      this.flyoutCloseButtonRef.current?.focus();
    });
  };

  renderSize = (size: number) => {
    let temp = convertBytes(size);
    return temp.value + ' ' + temp.unit;
  };

  onTitleBlur = () => {
    setTimeout(() => {
      this.updateCardActivityItems();
    }, 100);
    this.props.saveTitle!();
  };

  handleScroll = (e: any) => {
    const element = e.target;
    const threshold = element.scrollHeight - 100;

    if (
      element.scrollTop + element.clientHeight >= threshold &&
      !this.reachedBottom &&
      this.state.commentsNextPage
    ) {
      this.getCardComments();
      this.reachedBottom = true;
    }

    if (
      element.scrollTop + element.clientHeight >= threshold &&
      !this.reachedBottom &&
      this.state.allNextPage
    ) {
      this.getCardActivityCommentsItems();
      this.reachedBottom = true;
    }

    if (
      element.scrollTop + element.clientHeight >= threshold &&
      !this.reachedBottom &&
      this.state.activityNextPage
    ) {
      this.getCardActivityItems();
      this.reachedBottom = true;
    }

    if (element.scrollTop !== 0 && this.state.scrollToTop) {
      element.scrollTop = 0;
      element.scrollIntoView({ behavior: 'smooth' });
      this.setState({
        scrollToTop: false,
      });
    }
  };

  resetReachedBottom = () => {
    this.reachedBottom = false;
  };

  setShowUpdatingBtns = (value: boolean) => {
    this.setState({
      showUpdatingBtns: value,
    });
  };

  setHistoryType = (historyType: THistory) => {
    if (this.state.historyType === historyType) {
      return;
    }
    this.setState(
      (prevState) => {
        return {
          historyType,
        };
      },
      () => {
        localStorage.setItem(
          `historyType-${this.props.boardUser.id}`,
          JSON.stringify(this.state.historyType),
        );
      },
    );
  };

  handleGlobalDragEnter = (e: React.DragEvent<HTMLDivElement> | DragEvent) => {
    if (
      this.dragTarget.current &&
      this.dragTarget.current.contains(e.target as Node)
    ) {
      this.setState({ isDraggingFileOver: true });
    }
  };

  handleGlobalDragLeave = (e: React.DragEvent<HTMLDivElement> | DragEvent) => {
    if (
      this.dragTarget.current &&
      !this.dragTarget.current.contains(e.target as Node)
    ) {
      this.setState({ isDraggingFileOver: false });
    }
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement> | DragEvent) => {
    e.preventDefault();
  };

  createFileFromUrl = async (url: string): Promise<File> => {
    try {
      const response = await fetch(url);
      const contentType = response.headers.get('content-type');
      const blob = await response.blob();

      return new File([blob], 'image', { type: contentType! });
    } catch (error) {
      console.error('Failed to create file from URL:', error);
      this.setState({
        isDraggingFileOver: false,
      });
      throw error;
    }
  };

  handleDrop = async (e: React.DragEvent<HTMLDivElement> | DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const url = e.dataTransfer?.getData('text/uri-list');
    const dataTransfer = new DataTransfer();

    if (url) {
      const file = await this.createFileFromUrl(url);
      dataTransfer.items.add(file);
    }

    this.setState({
      isDraggingFileOver: false,
      droppedAttachments: e.dataTransfer
        ? Array.from(e.dataTransfer.files)
        : null,
    });

    if (dataTransfer.files) {
      this.setState({
        isDraggingFileOver: false,
        droppedAttachments: Array.from(dataTransfer.files),
      });
    }

    if (e.dataTransfer == null) return;
    const files = e.dataTransfer.files;

    if (files.length) {
      Array.from(files).forEach((file) => {
        console.log(file);
      });
      this.setState({ droppedAttachments: Array.from(files) });
    }
  };

  clearDroppedAttachments = () => {
    this.setState({
      droppedAttachments: null,
    });
  };

  renderCardNrPrefix = () => {
    const { cardNrPrefix, boardCard } = this.props;
    const cardNumber = boardCard.number;
    const prefix = cardNrPrefix
      ? `#${cardNrPrefix}-${cardNumber}`
      : `#${cardNumber}`;
    return prefix;
  };

  renderPriority = () => {
    const { priorities, boardCard } = this.props;
    const priority = priorities.find((p) => p.id === boardCard.priorityId);
    return priority;
  };

  renderCurrentCardTags = () => {
    const { boardCard, tags } = this.props;
    const tagIds = boardCard.tagIds;
    const currentCardTags = tagIds.map((tagId) =>
      tags.find((tag) => tag.id === tagId),
    ) as TagDTO[];
    return currentCardTags;
  };

  renderCurrentCardMembers = () => {
    const { boardCard, members } = this.props;
    const assigneeIds = boardCard.assigneeIds;
    const currentCardMembers = assigneeIds.map((id) => {
      const member = members.find((member) => member.id === id)!;
      return {
        ...member,
        avatar: member.avatar || DEFAULT_AVATAR,
      };
    });
    return currentCardMembers;
  };
  renderAttachments = () => {
    const { t } = this.props;
    return (
      <>
        <ul className="card-list-component flex-row px-2xs">
          {!Boolean(this.props.disabled) && (
            <li className="column medium flex-v-stretch pt-2xs px-2xs pb-0">
              <div className="card flex-v-center flex-h-spread py-2xs px-2xs">
                <FileInput
                  cardId={this.props.boardCard.id}
                  handleNewAttachment={this.handleNewAttachment}
                  uploadClipboardData={this.props.uploadClipboardData!}
                  clipboardData={this.props.clipboardData!}
                  setMessages={this.props.setMessages}
                  droppedAttachments={this.state.droppedAttachments}
                  setUploadClipboardData={this.props.setUploadClipboardData!}
                  clearDroppedAttachments={this.clearDroppedAttachments}
                />
              </div>
            </li>
          )}
          {this.state.attachments.map((attachment) => {
            return (
              <li
                key={attachment.id}
                className="column medium flex-v-stretch pt-2xs px-2xs pb-0"
              >
                <div className="card flex-v-center flex-h-spread py-2xs px-2xs">
                  <div className="fill">
                    <div className="flex-v-center">
                      {isSupportedImageMimeType(attachment.contentType) ? (
                        <div>
                          <Button
                            onClick={() =>
                              this.updateViewedAttachment(attachment)
                            }
                            className="ghost-button"
                            title={t('viewAttachment', {
                              filename: attachment.filename,
                            })}
                          >
                            <img
                              className="pe-none thumbnail cover smaller release radius no-bg"
                              src={`${process.env.REACT_APP_API_BASE_URL}/attachment/${attachment.id}`}
                            />
                          </Button>
                        </div>
                      ) : (
                        <>
                          <button
                            className="ghost-button"
                            disabled
                          >
                            <div className="thumbnail cover smaller release radius"></div>
                          </button>
                        </>
                      )}
                      <div>
                        <small
                          className="faint-text mx-2xs"
                          style={{ wordBreak: 'break-all' }}
                          title={attachment.filename}
                        >
                          {attachment.filename}
                        </small>
                      </div>
                      <ul className="control-list-component horizontal flex-h-end">
                        <li>
                          <ContextMenu
                            dept={0}
                            title="Attachment options"
                            triggerContent={
                              <>
                                <span className="fas fa-ellipsis-h"></span>
                              </>
                            }
                            triggerClassDefault="ghost-button"
                            triggerClassActive="secondary-button"
                          >
                            <li>
                              <a
                                download
                                target="_blank"
                                rel="noreferrer"
                                className="ghost-button"
                                href={`${process.env.REACT_APP_API_BASE_URL}/attachment/${attachment.id}`}
                              >
                                <span className="fal fa-download icon" />
                                <span className="text">{t('download')}</span>
                              </a>
                            </li>
                            {['admin', 'owner'].includes(
                              this.props.boardUser.role,
                            ) ||
                            this.props.boardUser.id === attachment.authorId ? (
                              <li>
                                <Button
                                  className="ghost-button"
                                  disabled={Boolean(this.props.disabled)}
                                  onClick={() => {
                                    this.setState({
                                      showDeletionPrompt: true,
                                    });
                                    this.updateViewedDeletionAttachment(
                                      attachment,
                                    );
                                  }}
                                >
                                  <span className="fal fa-trash icon" />
                                  <span className="text">{t('delete')}</span>
                                </Button>
                              </li>
                            ) : null}
                            <li>
                              <hr />
                            </li>
                            <li>
                              <small
                                className="pe-none faint-text fill"
                                style={{
                                  maxWidth: '120px',
                                  display: 'block',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {attachment.contentType}
                                <br />
                                {this.renderSize(attachment.size)}
                              </small>
                            </li>
                          </ContextMenu>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>

        {this.props.pasteAttachment && (
          <Dialog
            title={t('uploadTitle')}
            message={t('uploadDescription')}
            cancelText={t('cancel')}
            confirmText={t('upload')}
            onCancel={() => this.props.setPasteAttachment!(false)}
            onConfirm={() => {
              this.props.setPasteAttachment!(false);
              this.props.setUploadClipboardData!(true);
            }}
          />
        )}

        {this.state.showDeletionPrompt && (
          <Dialog
            title={t('attachmentDeletion')}
            message={
              <>
                <Trans
                  i18nKey="boardCardFlyoutTemplate:deleteAttachmentConfirmation"
                  values={{
                    filename: this.state.viewedDeletionAttachment?.filename,
                  }}
                  components={[<strong key="0"></strong>]}
                />
              </>
            }
            info={
              <p className="text-sm faint-text">
                <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
                <span>{t('irreversibleOperation')}</span>
              </p>
            }
            cancelText={t('cancel')}
            confirmText={t('permanentlyDelete')}
            onCancel={() => this.setState({ showDeletionPrompt: false })}
            onConfirm={() => {
              this.setState({
                showDeletionPrompt: false,
              });
              this.deleteCardAttachment(
                this.state.viewedDeletionAttachment!.id,
              );
            }}
          />
        )}
      </>
    );
  };

  renderMembers = () => {
    const { t } = this.props;
    const currentCardMembers = this.renderCurrentCardMembers();
    return (
      <>
        <div className="flex-row fill">
          <div className="column pb-2xs">
            <ul className="control-list-component">
              <li>
                <ContextMenu
                  contextMenuClassName="align-h-start"
                  dept={0}
                  triggerClassDefault="secondary-button"
                  isDisabled={Boolean(this.props.disabled)}
                  title={t('assignUsers')}
                  forceLTR={true}
                  triggerContent={
                    <>
                      <span className="text">
                        {this.props.boardCard.assigneeIds.length ?? 0}
                      </span>
                      <span className="fas fa-user-plus icon"></span>
                    </>
                  }
                >
                  <MemberMenu
                    manage={false}
                    selected={this.props.boardCard.assigneeIds}
                    cardId={this.props.boardCard.id}
                    userSearchOff={true}
                  />
                </ContextMenu>
              </li>
              <>
                {currentCardMembers.map((member, index) => {
                  return (
                    <li key={member.id}>
                      <ContextMenu
                        contextId={member.id}
                        dept={0}
                        contextMenuClassName="align-h-start"
                        triggerClassDefault="ghost-button"
                        triggerClassActive="secondary-button"
                        title={member.name}
                        isDisabled={Boolean(this.props.disabled)}
                        triggerContent={
                          <>
                            <Thumbnail
                              classes="pe-none thumbnail size-24"
                              avatarData={member.avatar}
                              title={member.name}
                            />
                          </>
                        }
                        rightClickTrigger={true}
                      >
                        <RemoveMemberMenu
                          cardId={this.props.boardCard.id}
                          memberId={member.id}
                          memberName={member.name}
                        />
                      </ContextMenu>
                    </li>
                  );
                })}
              </>
            </ul>
          </div>
        </div>
      </>
    );
  };

  renderTags = () => {
    const currentCardTags = this.renderCurrentCardTags();
    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-0 pb-2xs">
            <ul className="control-list-component">
              <li>
                <TagContext
                  disabled={this.props.disabled}
                  selectedTags={this.props.boardCard.tagIds}
                  amount={this.props.boardCard.tagIds.length}
                  triggerClassDefault="secondary-button"
                  triggerClassActive="secondary-button"
                  contextMenuClassName="align-h-start"
                  action="assign"
                  cardId={this.props.boardCard.id}
                />
              </li>
              <li>
                <ul className="control-list-component">
                  {currentCardTags.map((tag) => {
                    const symbol = getColorSymbol(TAG_COLORS, tag.color);
                    return (
                      <li key={tag.id}>
                        <ContextMenu
                          contextId={tag.id}
                          dept={0}
                          contextMenuClassName="align-h-start"
                          triggerClassDefault="ghost-button"
                          triggerClassActive="secondary-button"
                          isDisabled={this.props.disabled}
                          triggerContent={
                            <>
                              <span
                                className={`cb-none badge-text uppercase accent-${tag.color}`}
                              >
                                {tag.name}
                              </span>
                              <span
                                className={`cb-only flag-text flag-text-${tag.color} uppercase`}
                              >
                                <span
                                  className={`${symbol} accent-text-${tag.color}`}
                                ></span>{' '}
                                {tag.name}
                              </span>
                            </>
                          }
                          rightClickTrigger={true}
                        >
                          <RemoveTagMenu
                            cardId={this.props.boardCard.id}
                            tagId={tag.id}
                            tagName={tag.name}
                          />
                        </ContextMenu>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  handleCopyStringToClipboard = (value: string) => {
    const { t } = this.props;
    if (!this.timeoutIdNo) {
      copyToClipboard(
        value,
        'boardCardFlyoutTemplate:noCopiedToClipboard',
        this.props.setMessages,
      );
      this.timeoutIdNo = setTimeout(() => {
        if (this.timeoutIdNo) {
          clearTimeout(this.timeoutIdNo);
          this.timeoutIdNo = null;
        }
      }, 5300);
    }
  };

  handleCopyToClipboard = () => {
    const { t } = this.props;
    if (!this.timeoutId) {
      copyToClipboard(
        window.location.href,
        'boardCardFlyoutTemplate:permalinkCopiedToClipboard',
        this.props.setMessages,
      );
      this.timeoutId = setTimeout(() => {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }
      }, 5300);
    }
  };

  onTextAreaHeightChange = (height: number | undefined) => {
    this.setState({
      cardNameHeight: height ?? 0,
    });
  };

  render() {
    const { t } = this.props;

    const { cardNameHeight } = this.state;

    const cardNrPrefix = this.renderCardNrPrefix();
    const priority = this.renderPriority();

    return (
      <div
        onDragOver={this.handleDragOver}
        onDragEnter={this.handleGlobalDragEnter}
        onDragLeave={this.handleGlobalDragLeave}
        onDrop={this.handleDrop}
        ref={this.dragTarget}
        className={`file-zone ${
          this.state.isDraggingFileOver ? 'file-dragging-over' : ''
        }`}
      >
        {this.state.isDraggingFileOver && (
          <div className="file-dragging-over">{t('dropFilesHere')}</div>
        )}
        {this.state.viewedAttachment !== null && (
          <ImageViewer
            onClose={() => this.onCloseImageViewer()}
            activeAttachment={this.state.viewedAttachment}
            attachments={this.state.attachments.filter((attachment) =>
              isSupportedImageMimeType(attachment.contentType),
            )}
          />
        )}
        <ul className="control-list-component flex-h-spread">
          <li>
            <ul className="control-list-component">
              <li>
                <Button
                  className="ghost-button close-flyout-button"
                  higherRef={this.flyoutCloseButtonRef}
                  onClick={this.props.handleFlyoutClose}
                  onKeyDown={this.props.handleCloseButtonKeyDown}
                  title={t('closeButton')}
                >
                  <span className="fal fa-times pe-none text-xl"></span>
                </Button>
              </li>
              <li>
                <button
                  className="ghost-button"
                  title={t('copyNo')}
                  onClick={() => this.handleCopyStringToClipboard(cardNrPrefix)}
                >
                  <span className="faint-text pe-none">{cardNrPrefix}</span>
                </button>
              </li>
              <li>
                <Button
                  className="ghost-button"
                  title={t('copyPermalink')}
                  onClick={this.handleCopyToClipboard}
                >
                  <span className="far fa-link pe-none icon"></span>
                </Button>
              </li>
            </ul>
          </li>
          {/* PLACEHOLDER */}
          {this.props.children}
        </ul>
        <div className="flex-row squeeze flex-v-center no-reflow hidden-mobile">
          <div className="column pt-0 pb-0 flex-v-center">
            <PriorityContext
              disabled={Boolean(this.props.disabled)}
              contextMenuClassName="align-h-start"
              selectedPriority={priority}
              cardId={this.props.boardCard.id}
            />
          </div>
          <div className="column pt-0 pb-0 fill pl-0">
            {this.props.disabled ? (
              <>
                <h2 className="h2 primary-title normalcase secondary-button-like">
                  {this.props.boardCard.title}
                </h2>
              </>
            ) : (
              <>
                <TextArea
                  className="h2 primary-title normalcase secondary-button-like"
                  value={this.props.boardCard.title}
                  disabled={this.props.disabled}
                  name="title"
                  autoHeight={true}
                  noMargin={true}
                  onChange={this.props.onTitleChange}
                  onBlur={this.onTitleBlur}
                  maxLength={255}
                  showCounter={true}
                  onTextAreaHeightChange={this.onTextAreaHeightChange}
                />
              </>
            )}
          </div>
        </div>
        <hr
          style={{
            marginTop: '16px',
            marginLeft: '-16px',
            marginRight: '-16px',
            width: 'auto',
          }}
        />
        <div
          className="flex-row fill flyout-content"
          style={{
            maxHeight: `calc(100dvh - ${cardNameHeight + 142}px)`,
          }}
          onWheel={this.handleScroll}
          onScroll={this.handleScroll}
        >
          <div className="column pt-0">
            {/*
              not sure where exacly to put the error, this seems like a good place for both archive and delete card request
              to revisit when we have the APIs
            */}
            <ErrorList errors={this.props.serverErrors} />
            <div className="flex-row squeeze no-reflow visible-mobile">
              <div className="column pt-0 pb-0">
                <PriorityContext
                  disabled={Boolean(this.props.disabled)}
                  contextMenuClassName="align-h-start"
                  selectedPriority={priority}
                  cardId={this.props.boardCard.id}
                />
              </div>
              <div className="column pt-0 pb-0 fill pl-0">
                {this.props.disabled ? (
                  <>
                    <h2 className="h2 primary-title normalcase secondary-button-like">
                      {this.props.boardCard.title}
                    </h2>
                  </>
                ) : (
                  <>
                    <TextArea
                      className="h2 primary-title normalcase secondary-button-like"
                      value={this.props.boardCard.title}
                      disabled={this.props.disabled}
                      name="title"
                      autoHeight={true}
                      noMargin={true}
                      onChange={this.props.onTitleChange}
                      onBlur={this.onTitleBlur}
                      maxLength={255}
                      showCounter={true}
                    />
                  </>
                )}
              </div>
            </div>
            {this.renderMembers()}
            {this.renderTags()}
            {!this.props.disabled && (
              <AddCardDescription
                disabled={this.props.disabled}
                boardCard={this.props.boardCard}
                onDescriptionChange={this.props.onDescriptionChange!}
                descriptionEditor={this.props.descriptionEditor}
                setDescriptionEditor={this.props.setDescriptionEditor}
                showUpdatingBtns={this.state.showUpdatingBtns}
                setShowUpdatingBtns={this.setShowUpdatingBtns}
                newCard={this.state.newCard}
              />
            )}
            <div className="mt-0 mb-xs">{this.renderAttachments()}</div>
            {!this.props.disabled && (
              <AddCardComment
                disabled={this.props.disabled}
                boardCard={this.props.boardCard}
                addComment={this.addComment}
                handleAddComment={this.props.handleAddComment}
                newCard={this.state.newCard}
              />
            )}
            <CardActivity
              disabled={this.props.disabled}
              members={this.props.members}
              tags={this.props.tags}
              columnTitles={this.props.columnTitles}
              boardCard={this.props.boardCard}
              boardUser={this.props.boardUser}
              reachedBottom={this.reachedBottom}
              resetReachedBottom={this.resetReachedBottom}
              addWsListener={this.props.addWsListener}
              removeWsListener={this.props.removeWsListener}
              priorities={this.props.priorities}
              groupedActivityItems={this.state.groupedActivityItems}
              groupedComments={this.state.groupedComments}
              groupedActivities={this.state.groupedActivities}
              activityStatus={this.state.activityStatus}
              updateComment={this.updateComment}
              historyType={this.state.historyType}
              setHistoryType={this.setHistoryType}
              attachments={this.state.attachments}
              removeComment={this.removeComment}
              handleDeleteComment={this.props.handleDeleteComment}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('boardCardFlyoutTemplate')(
  BoardCardFlyoutTemplate,
);
