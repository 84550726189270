import React from 'react';

export default class HorizontalDrag extends React.Component {
  isDragging: boolean;
  lastX: number;
  lastY: number;
  scrollLeft: number;
  scrollTop: number;

  constructor(props: {}) {
    super(props);
    this.isDragging = false;
    this.lastX = 0;
    this.lastY = 0;
    this.scrollLeft = 0;
    this.scrollTop = 0;
  }

  handleMouseDown = (e: MouseEvent, element: HTMLElement) => {
    if (e.buttons !== 1) return;

    let HTMLTag = document.querySelector('html') as HTMLElement;

    this.isDragging = true;
    this.lastX = e.clientX;
    this.lastY = e.clientY;
    this.scrollLeft = HTMLTag.scrollLeft;
    this.scrollTop = HTMLTag.scrollTop;
  };

  handleMouseUp = () => {
    this.isDragging = false;
  };

  handleMouseLeave = () => {
    this.isDragging = false;
  };

  handleMouseMove = (e: MouseEvent) => {
    if (!this.isDragging) return;
    let HTMLTag = document.querySelector('html') as HTMLElement;
    const deltaX = e.clientX - this.lastX;
    const deltaY = e.clientY - this.lastY;
    // Set the new scroll position based on the mouse movement
    HTMLTag.scrollLeft = this.scrollLeft - deltaX;
    HTMLTag.scrollTop = this.scrollTop - deltaY;
  };

  componentDidMount(): void {
    const scrollElement = document.querySelector(
      '.fe-scroll-container',
    ) as HTMLDivElement;
    const mainElement = document.querySelector(
      '.main-component',
    ) as HTMLDivElement;

    if (scrollElement) {
      scrollElement.addEventListener('mousedown', (e: MouseEvent) => {
        const elements = Array.from(
          document.querySelectorAll(
            '.board-column-card > [data-rbd-draggable-id], .list-drag-head',
          ),
        );

        // Check if the target element or any of its ancestors have the class 'drag-card-button'
        let isDraggingCard = elements.some((element) =>
          element.contains(e.target as Node),
        );

        // Prevent horizontal drag if dragging a card
        if (!isDraggingCard) {
          this.handleMouseDown(e, scrollElement);
        }
      });
    }

    if (mainElement) {
      mainElement.addEventListener('mouseup', this.handleMouseUp);
      mainElement.addEventListener('mouseleave', this.handleMouseLeave);

      // Add an event listener for when the user moves the mouse while dragging
      mainElement.addEventListener('mousemove', this.handleMouseMove);
    }
  }

  componentWillUnmount() {
    const scrollElement = document.querySelector(
      '.fe-scroll-container',
    ) as HTMLDivElement;
    const mainElement = document.querySelector(
      '.main-component',
    ) as HTMLDivElement;

    if (scrollElement) {
      scrollElement.removeEventListener('mousedown', (e: MouseEvent) =>
        this.handleMouseDown(e, scrollElement),
      );
    }
    if (mainElement) {
      mainElement.removeEventListener('mouseup', this.handleMouseUp);
      mainElement.removeEventListener('mouseleave', this.handleMouseLeave);
      mainElement.removeEventListener('mousemove', this.handleMouseMove);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}
