export class InfoNotificationPayload {
  id: string;
  message: string;

  constructor(message: string) {
    // TODO: need unique id for the React.Component key prop, Date might not be enough if users spam errors really fast
    this.id = crypto.randomUUID();
    this.message = message;
  }
}
