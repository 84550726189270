import React, { Component } from 'react';

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

interface Props {}

interface State {
  installPrompt: null | BeforeInstallPromptEvent;
  show: boolean;
}

class PWAInstall extends Component<Props, State> {
  installButton: React.RefObject<HTMLButtonElement>;
  constructor(props: Props) {
    super(props);
    this.installButton = React.createRef();
    this.state = {
      installPrompt: null,
      show: false,
    };
  }

  componentDidMount(): void {
    window.addEventListener('beforeinstallprompt', this.handlePrompt);
  }

  handlePrompt = (ev: Event) => {
    let event = ev as BeforeInstallPromptEvent;
    event.preventDefault();
    this.setState({
      installPrompt: event,
      show: true,
    });
  };

  handleInstall = () => {
    if (!this.state.installPrompt) {
      return;
    }

    this.state.installPrompt.prompt();

    this.setState({
      installPrompt: null,
      show: false,
    });
  };

  componentWillUnmount(): void {
    window.removeEventListener('beforeinstallprompt', this.handlePrompt);
  }

  render() {
    return (
      <>
        {this.state.show && (
          <button
            id="install"
            ref={this.installButton}
            className="secondary-button"
            onClick={this.handleInstall}
          >
            <span className="fas fa-mobile icon accent-text-green"></span>
            <span className="text">Install Borddo App</span>
          </button>
        )}
      </>
    );
  }
}

export default PWAInstall;
