import React from 'react';
import ExampleTheme from '../../../controls/LexicalControl/themes/LexicalTheme';
import { LexicalEditor } from 'lexical';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import LexicalControl from '../../../controls/LexicalControl/LexicalControl';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import CustomPlugins from '../../../controls/LexicalControl/plugins/CustomPlugins';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import {
  updateComment,
  deleteComment,
} from '../../../../common/api/endpoints/comment';
import { CommentDTO } from '../../../../common/api/dtos/Comment';
import { $convertToMarkdownString } from '@lexical/markdown';
import Button from '../../../controls/Button/Button';
import { CUSTOM_TRANSFORMERS } from '../../../../components/controls/LexicalControl/transformers/CustomTransformers';
import { IBoardCurrentUser } from '../../../../common/interfaces/BoardCurrentUser';
import Dialog from '../../../controls/Dialog/Dialog';
import getTime from '../../../../common/helpers/getTime';
import { Trans, WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import Thumbnail from '../../Thumbnail/Thumbnail';
import { DEFAULT_AVATAR } from '../../../../common/configs/appDefaults';

interface Props extends WithTranslation {
  disabled: boolean;
  boardCard: BoardCard;
  boardUser: IBoardCurrentUser;
  comment: CommentDTO;
  updateComment: (commentData: Partial<CommentDTO>) => void;
  removeComment: (commentData: Partial<CommentDTO>) => void;
  handleDeleteComment?: () => void;
  members: (MemberDTO | InviteeDTO)[];
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];

  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface State {
  comment: string;
  editor: LexicalEditor | null;
  showLexical: boolean;
  cancelComment: boolean;
  showDeletionPrompt: boolean;
}

class UserComment extends React.Component<Props, State> {
  initialEditorConfig: InitialConfigType;
  lexicalRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.lexicalRef = React.createRef();
    this.initialEditorConfig = {
      // The editor theme
      editorState: () => null,
      editable: false,
      theme: ExampleTheme,
      namespace: 'UserComment',
      // Handling of errors during update
      onError(error: Error) {
        throw error;
      },
      // Any custom nodes go here
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
      ],
    };
    this.state = {
      comment: this.props.comment.content,
      editor: null,
      showLexical: false,
      cancelComment: false,
      showDeletionPrompt: false,
    };
  }

  setComment = (value: string) => {
    this.setState(
      {
        comment: value,
      },
      () => {
        this.updateComment();
      },
    );
  };

  setEditor = (editor: LexicalEditor | null) => {
    this.setState({
      editor: editor,
    });
  };

  updateComment = async () => {
    try {
      const comment = await updateComment(
        this.props.comment.id,
        this.state.comment,
      );

      this.props.updateComment(comment);
    } catch (err) {
      console.log(err);
    }
  };

  getMemberAvatar = (id: string) => {
    const member = this.props.members.find((m) => m.id === id);

    return member && member.avatar ? member.avatar : DEFAULT_AVATAR;
  };

  renderAvatar = () => {
    const { t, comment } = this.props;
    if (this.getMemberName(comment.authorId) !== undefined) {
      return (
        <Thumbnail
          classes="size-24 icon"
          avatarData={this.getMemberAvatar(comment.authorId)}
          title={this.getMemberName(comment.authorId)}
        />
      );
    }

    if (this.getMemberName(comment.authorId) !== undefined) {
      return (
        <Thumbnail
          classes="size-24 icon"
          avatarData={this.getMemberAvatar(comment.authorId)}
          title={this.getMemberName(comment.authorId)}
        />
      );
    }

    return (
      <Thumbnail
        classes="size-24 icon"
        avatarData={'unknown'}
        title={t('unknown')}
      />
    );
  };

  handleUpdate = () => {
    this.state.editor &&
      this.state.editor.getEditorState().read(() => {
        const markdown = $convertToMarkdownString(
          CUSTOM_TRANSFORMERS,
        ).replaceAll(/\n{2}/gm, '\n');

        if (markdown !== this.props.comment.content) {
          this.setState(
            {
              comment: markdown,
            },
            () => {
              this.updateComment();
            },
          );
        }
      });
  };

  setShowLexical = (value: boolean) => {
    this.setState(
      {
        showLexical: value,
      },
      () => {
        if (value === false) {
          this.setState({ cancelComment: true });
        }
      },
    );
  };

  setShowDeletionPrompt = (showDeletionPrompt: boolean) => {
    this.setState({
      showDeletionPrompt,
    });
  };

  deleteComment = async (comment: CommentDTO) => {
    try {
      await deleteComment(comment.id);

      let deletedComment = {
        ...comment,
        deletedBy: this.props.boardUser.id,
        deletedAt: new Date().toISOString(),
      };
      this.props.removeComment(deletedComment);
      this.props.handleDeleteComment && this.props.handleDeleteComment();
      this.setShowLexical(false);
    } catch (err) {
      console.log(err);
    }
  };

  getMemberName = (id: string | undefined) => {
    const member = this.props.members.find((m) => m.id === id);

    return member && member.name
      ? member.name
      : member && (member as InviteeDTO).email
      ? (member as InviteeDTO).email
      : undefined;
  };

  render() {
    const { t, comment, boardCard } = this.props;
    const { state } = this;
    return (
      <>
        {!state.showLexical && (
          <>
            <div className="flex-row fill">
              <div className="column pb-2xs">
                <LexicalComposer initialConfig={this.initialEditorConfig}>
                  <div className="lexical-control">
                    <div className="editor-inner card opaque">
                      <RichTextPlugin
                        contentEditable={
                          <ContentEditable className="editor-input soft-disabled" />
                        }
                        placeholder={
                          <div className="editor-placeholder">
                            {t('comment')}
                          </div>
                        }
                        ErrorBoundary={LexicalErrorBoundary}
                      />
                      <CustomPlugins
                        card={boardCard}
                        value={comment.content}
                        updateTrigger={comment.content}
                      />
                    </div>
                  </div>
                </LexicalComposer>
              </div>
            </div>
            {(this.props.boardUser.role === 'owner' ||
              this.props.boardUser.role === 'admin' ||
              this.props.boardUser.id === comment.authorId) &&
              !Boolean(this.props.disabled) && (
                <ul className="control-list-component mb-sm">
                  {this.props.boardUser.id === comment.authorId ? (
                    <li>
                      <Button
                        className="secondary-button lexical-button-trigger"
                        onClick={() => {
                          this.setShowLexical(true);
                        }}
                      >
                        <span className="text">{t('edit')}</span>
                      </Button>
                    </li>
                  ) : null}
                  <li>
                    <Button
                      className="secondary-button"
                      onClick={() => {
                        this.setShowDeletionPrompt(true);
                      }}
                    >
                      <span className="text">{t('delete')}</span>
                    </Button>
                    {state.showDeletionPrompt && (
                      <Dialog
                        title={t('commentDeletion')}
                        message={
                          <>
                            <Trans
                              i18nKey="userComment:commentDeletionConfirmation"
                              values={{
                                avatar: this.renderAvatar(),
                                author: this.getMemberName(comment.authorId),
                                time: getTime(comment.createdAt),
                              }}
                              components={[
                                this.renderAvatar(),
                                <strong key="1"></strong>,
                                <strong key="2"></strong>,
                              ]}
                            />
                          </>
                        }
                        info={
                          <p className="text-sm faint-text">
                            <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
                            <span>{t('operationIrreversible')}</span>
                          </p>
                        }
                        cancelText={t('cancel')}
                        confirmText={t('permanentlyDelete')}
                        onCancel={() => this.setShowDeletionPrompt(false)}
                        onConfirm={() => {
                          this.setShowDeletionPrompt(false);
                          this.deleteComment(this.props.comment);
                        }}
                      />
                    )}
                  </li>
                </ul>
              )}
          </>
        )}
        <div className={`${state.showLexical ? '' : 'hidden'}`}>
          <LexicalControl
            disabled={Boolean(this.props.disabled)}
            className="comment-editor"
            //placeholder="Click here to write a comment"
            card={boardCard}
            handleChange={this.setComment}
            namespace={`user-comment-${boardCard.id}`}
            editor={state.editor}
            setEditor={this.setEditor}
            value={comment.content}
            updateTrigger={comment.content}
            showLexical={state.showLexical}
            setShowLexical={this.setShowLexical}
            buttonTriggered={true}
            cancelDescription={state.cancelComment}
          />
          <ul className="control-list-component pt-2xs">
            <li>
              <Button
                className="primary-button"
                onClick={() => {
                  this.setShowLexical(false);
                  this.handleUpdate();
                }}
              >
                <span className="text">{t('updateComment')}</span>
              </Button>
            </li>
            <li>
              <Button
                className="secondary-button"
                onClick={() => this.setShowLexical(false)}
              >
                <span className="text">{t('cancel')}</span>
              </Button>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('userComment')(UserComment);
