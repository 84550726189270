import React from 'react';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import AppContext from '../../../common/contexts/AppContext';

interface Props {
  directionalButton: IDirectionalButton;
  className: string;
}

interface State {}

export default class DirectionalButton extends React.Component<Props, State> {
  handleClick = () => {
    this.props.directionalButton.onClick();
    if (this.props.directionalButton.direction === 'right') {
      // only set the new id when going forward in a nested context menu
      const direcitonalButtonId = `#${this.props.directionalButton.text
        .toLowerCase()
        .replaceAll(' ', '-')}-directional-button`;
      this.context.setDirectionalButtonId(direcitonalButtonId);
    }
  };

  render() {
    return (
      <button
        disabled={this.props.directionalButton.disabled}
        className={['ghost-button fill', this.props.className].join(' ')}
        onClick={
          !this.props.directionalButton.disabled ? this.handleClick : undefined
        }
        id={`${this.props.directionalButton.text
          .toLowerCase()
          .replaceAll(' ', '-')}-directional-button`}
      >
        {this.props.directionalButton.direction === 'left' && (
          <span
            className={`icon fas fa-angle-${this.props.directionalButton.direction}`}
          ></span>
        )}
        <span className="text">{this.props.directionalButton.text}</span>
        {this.props.directionalButton.direction === 'right' && (
          <span
            className={`icon fas fa-angle-${this.props.directionalButton.direction}`}
          ></span>
        )}
      </button>
    );
  }
}

DirectionalButton.contextType = AppContext;
