import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoginForm from '../../../forms/LoginForm/LoginForm';
import LinkButton from '../../../controls/LinkButton/LinkButton';

export interface Props extends RouteComponentProps {
  unlockApp: () => void;
}

export default class LoginModal extends Component<Props> {
  modalRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.modalRef = React.createRef();
  }

  handleKeyDown = (e: KeyboardEvent) => {
    // TRAP FOCUS
    if (e.key === 'Tab') {
      const modalItems = this.modalRef.current
        ? this.modalRef.current.querySelectorAll(
            'button:not([disabled]), [href], input, select, textarea',
          )
        : [];

      const firstModalItem = modalItems[0] as HTMLElement;
      const lastModalItem = modalItems[modalItems.length - 1] as HTMLElement;
      if (
        e.shiftKey &&
        Array.prototype.indexOf.call(modalItems, document.activeElement) === -1
      ) {
        firstModalItem.focus();
        e.preventDefault();
      } else if (
        !e.shiftKey &&
        Array.prototype.indexOf.call(modalItems, document.activeElement) === -1
      ) {
        firstModalItem.focus();
        e.preventDefault();
      }
      if (!e.shiftKey && document.activeElement === lastModalItem) {
        firstModalItem.focus();
        e.preventDefault();
      } else if (e.shiftKey && document.activeElement === firstModalItem) {
        lastModalItem.focus();
        e.preventDefault();
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    return (
      <div
        className="login-modal login-component login-modal will-transition"
        ref={this.modalRef}
      >
        <div className="login-container">
          <div className="flex-row fill">
            <div className="column">
              <div className="card p-large">
                <div>
                  <img
                    className="logo"
                    src={process.env.REACT_APP_STATIC_LOGO}
                    alt="Borddo"
                  />
                  <p className="normal-case pt-sm">Your session has expired!</p>
                </div>
                <br />
                <LoginForm
                  unlockApp={this.props.unlockApp}
                  history={this.props.history}
                  location={this.props.location}
                />
                <br />
                <LinkButton
                  to="/forgot-password"
                  className="link-button"
                  target="_blank"
                >
                  <span className="text">Forgot Password</span>
                </LinkButton>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
