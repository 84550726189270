import React from 'react';
import ReactDOM from 'react-dom';
import { TransitionContext } from '../../partials/Transition/TransitionEvent';

class Toolbar extends React.Component {
  focusSearchBox = (e: KeyboardEvent) => {
    var filterBox = document.getElementById('filterBox');

    if (e.ctrlKey && e.key.toLowerCase() === 'f' && filterBox) {
      filterBox?.focus();
      e.preventDefault();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.focusSearchBox);
  }

  render() {
    const container = document.getElementById('toolbarControls');
    if (container && !this.context.isExiting) {
      return ReactDOM.createPortal(this.props.children, container);
    }
    return <></>;
  }
}

export default Toolbar;
Toolbar.contextType = TransitionContext;
