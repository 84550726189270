import Joi from 'joi';
import React, { ChangeEvent, Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { changePassword } from '../../../../common/api/endpoints/user';
import { VALID_PASSWORD_REGEX } from '../../../../common/configs/appDefaults';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import Button from '../../../controls/Button/Button';
import TextBox from '../../../controls/TextBox/TextBox';
import PageMessage from '../../../partials/PageMessage/PageMessage';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import LegalFooter from '../../../partials/Legal/LegalFooter';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import AppContext from '../../../../common/contexts/AppContext';
import { checkForgotPasswordToken } from '../../../../common/api/endpoints/token';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {
  status: TRequestStatus;
  errorMessage: string;
  formData: FormData;
  formErrors: FormErrors;
  isPasswordChanged: boolean;
  invalidToken: boolean;
  pageStatus: TRequestStatus;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

interface FormData {
  password: string;
  confirmPassword: string;
}

class ResetPass extends Component<Props, State> {
  static contextType = AppContext;
  formSchema = Joi.object({
    password: Joi.string()
      .required()
      .pattern(VALID_PASSWORD_REGEX)
      .messages(FormErrorMsgs.string),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref('password'))
      .label('Passwords do not match')
      .messages(FormErrorMsgs.string),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      errorMessage: '',
      formData: {
        password: '',
        confirmPassword: '',
      },
      formErrors: {},
      isPasswordChanged: false,
      invalidToken: false,
      pageStatus: 'loading',
    };
  }

  componentDidMount(): void {
    this.checkTokenValidity();
  }

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = this.state.formData;

    this.setState({
      formData: {
        ...formData,
        [event.target.name]: event.target.value,
      },
    });
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors,
      });

      return false;
    }

    return true;
  };

  validateFormField = (field: string) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  updateFormError<K extends keyof FormData>(field: string, value: FormData[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isValid = this.validateForm();
    if (!isValid) return;

    this.setState({
      status: 'loading',
    });

    try {
      await changePassword(
        this.props.match.params.token,
        this.state.formData.password,
      );

      this.setState({
        status: 'success',
        isPasswordChanged: true,
      });
    } catch (error) {
      showErrorNotifications(error, this.context.notifications.setMessages);
      this.setState({
        status: 'error',
      });
    }
  };

  checkTokenValidity = async () => {
    try {
      const { valid } = await checkForgotPasswordToken(
        this.props.match.params.token,
      );

      if (!valid) {
        this.setState({
          invalidToken: true,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        pageStatus: 'idle',
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.pageStatus !== 'loading' &&
          (this.state.isPasswordChanged ? (
            <PageMessage>
              <h1 className="primary-title normalcase pb-xs">Reset Password</h1>
              <br />
              <p>Password changed succesfully!</p>
              <LinkButton
                to="/"
                className="primary-button"
              >
                Login
              </LinkButton>
            </PageMessage>
          ) : this.state.invalidToken ? (
            <PageMessage>
              <h1 className="primary-title normalcase pb-xs">Expired Link</h1>
              <br />
              <p>The link either expired or has already been used.</p>
              <LinkButton
                to="/"
                className="primary-button"
              >
                Login
              </LinkButton>
            </PageMessage>
          ) : (
            <div className="login-component will-transition">
              <div className="login-container">
                <div className="flex-row fill">
                  <div className="column">
                    <div className="card p-large">
                      <div>
                        <img
                          className="logo"
                          src={process.env.REACT_APP_STATIC_LOGO}
                          alt="Borddo"
                        />
                      </div>
                      <br />
                      <ul className="error-list"></ul>
                      <form
                        onSubmit={this.handleSubmit}
                        noValidate={true}
                      >
                        <TextBox
                          label="New Password"
                          type="password"
                          name="password"
                          id="password"
                          onChange={this.handleInput}
                          required={true}
                          br={true}
                          onBlur={(ev) =>
                            this.validateFormField(
                              ev.target.name as keyof FormData,
                            )
                          }
                          error={this.state.formErrors.password}
                        />
                        <TextBox
                          label="Confirm Password"
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          onChange={this.handleInput}
                          required={true}
                          br={true}
                          onBlur={(ev) =>
                            this.validateFormField(
                              ev.target.name as keyof FormData,
                            )
                          }
                          error={this.state.formErrors.confirmPassword}
                        />
                        <Button className="primary-button">
                          <RequestStatus status={this.state.status} />
                          <span className="text">Submit</span>
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="flex-row fill">
                  <div className="column">
                    <LegalFooter />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </React.Fragment>
    );
  }
}

export default ResetPass;
