export const handleClickOutside = (
  ev: Event,
  callback: () => void,
  ref: React.RefObject<any>,
  optionalRef?: React.RefObject<any>,
) => {
  const optionalRefConst = optionalRef
    ? !optionalRef.current?.contains(ev.target as HTMLElement)
    : true;

  if (
    ref &&
    !ref.current?.contains(ev.target as HTMLElement) &&
    optionalRefConst
  ) {
    callback();
  }
};
