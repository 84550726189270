export const VALID_PASSWORD_REGEX =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~])[A-Za-z\d/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~]{8,}/;
export const SESSION_VALIDATE_INTERVAL_MS = 60 * 1000;
export const ANONYMOUS_ROUTES = [
  '/onboarding',
  '/registration',
  '/forgot-password',
  '/reset-password',
  '/resend-confirmation',
]; // routes where the login form should not be displayed
export const SMOOTH_SCROLL_MS = 300; // you may want to wait for the smooth scroll to finish. NOTE: This is an aprox value for that works witht the datepicker
export const DEFAULT_AVATAR = 'ava45.png|#aaaaaa|||none||';
