export type ChecklistItem = {
  id: string; // Alias ID for better reference and searchability
  text: string;
  description: string;
  completed: boolean;
  showDescription: boolean;
  quantity: number;
  requiredQuantity: number;
};

export const getChecklistItems = (userType: string): ChecklistItem[] => {
  switch (userType) {
    case 'solo':
      return [
        {
          id: 'create_first_board',
          text: 'Create Your First Board',
          description: 'Create and open a board where you are an Owner.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'add_cards',
          text: 'Add at least 8 cards',
          description:
            'Populate the board with a minimum of 8 cards representing tasks or ideas by clicking on the Add Card button in a column.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 8,
        },
        {
          id: 'add_card_description',
          text: 'Add card description',
          description:
            'Select any card from your board and click on the Type a description here... input to add a detailed description for the card.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'assign_tag',
          text: 'Assign a tag to a card',
          description:
            'Click on the Assign tags button and assign a tag to a card.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'assign_priority',
          text: 'Assign a priority to a card',
          description:
            "Select any card from your board, click on the Change priority button to change a card's priority.",
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'move_cards_between_columns',
          text: 'Move cards between columns',
          description:
            'Move a card between columns. Make sure there are at least two columns on the board.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
      ];
    case 'teamOwner':
      return [
        {
          id: 'setup_team_board',
          text: 'Set Up Your Team Board',
          description: '',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'invite_board_members',
          text: 'Invite a Board Member',
          description:
            'Go to the homepage, click on Team Settings › Members and invite a new team member by name, email or handle.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'create_cards',
          text: 'Create at least 8 cards',
          description:
            'Populate the board with a minimum of 8 cards representing tasks or ideas by clicking on the Add Card button in a column.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 8,
        },
        {
          id: 'assign_members',
          text: 'Assign members to cards',
          description:
            'Click on the Assign users button and assign a user to a card.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'assign_tag_team',
          text: 'Assign a tag to a card',
          description:
            'Click on the Assign tags button and assign a tag to a card.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'add_comment',
          text: 'Add a comment',
          description:
            'Select any card from your board, click on the Write a comment... input and add a new comment.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'upload_attachment',
          text: 'Upload an Attachment',
          description:
            'Select any card from your board, click on the Attach files button and upload a file on the card.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'move_cards_between_columns_team',
          text: 'Move cards between columns',
          description:
            'Move a card between columns. Make sure there are at least two columns on the board.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
      ];
    case 'invited':
      return [
        {
          id: 'accept_invitation',
          text: 'Join the team',
          description: '',
          completed: false,
          showDescription: false,
          quantity: 1,
          requiredQuantity: 1,
        },
        {
          id: 'open_card',
          text: 'Open a card',
          description: 'Click on any card from the board and open it.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'add_comment_invited',
          text: 'Add a comment',
          description:
            'Select any card from your board, click on the Write a comment... input and add a new comment.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'upload_attachment_invited',
          text: 'Upload an Attachment',
          description:
            'Select any card from your board, click on the Attach files button and upload a file on the card.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'create_card',
          text: 'Create a card',
          description:
            'Click on the Add Card button in a column and create a new card on the board.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
        {
          id: 'move_cards_between_columns_invited',
          text: 'Move cards between columns',
          description:
            'Move a card between columns. Make sure there are at least two columns on the board.',
          completed: false,
          showDescription: false,
          quantity: 0,
          requiredQuantity: 1,
        },
      ];
    default:
      return [];
  }
};
