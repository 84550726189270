import { Component } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import PageMessage from '../../../partials/PageMessage/PageMessage';
import { confirmChangeOwner } from '../../../../common/api/endpoints/board';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { ErrorNotificationPayload } from '../../../../common/helpers/errorNotificationPayload';
import BoardContext, {
  IBoardContext,
} from '../../../../common/contexts/BoardContext';
import { withContextAdapter } from '../../ContextAdapter/withContextAdapter';

interface RouteParams {
  routeBoardId: string;
  token: string;
}

interface ContextProps {
  reloadBoard: () => void;
}

interface ExternalProps extends RouteComponentProps<RouteParams> {}

interface State {
  confirmStatus: TRequestStatus;
  message: string;
  title: string;
}

interface Props extends ExternalProps, ContextProps {}

class ConfirmOwnerChange extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmStatus: 'loading',
      message: '',
      title: 'Change Owner Confirmation',
    };
  }

  componentDidMount(): void {
    this.confirmChangeOwner();
  }

  confirmChangeOwner = async () => {
    try {
      await confirmChangeOwner(
        this.props.match.params.routeBoardId,
        this.props.match.params.token,
      );
      this.setState({
        message: 'Board ownership changed succesfully!',
        confirmStatus: 'success',
      });
      this.props.reloadBoard();
    } catch (err) {
      const errors = Array.isArray(err) ? err : [err];
      if (errors[0] instanceof ErrorNotificationPayload) {
        this.setState({
          message: errors[0].validationCode
            ? (errors[0].path !== null ? errors[0].path : '') +
              errors[0].validationCode
            : errors[0].message
            ? errors[0].message
            : '',
          confirmStatus: 'error',
          title:
            errors[0].validationCode &&
            errors[0].validationCode === 'request.valid_token'
              ? 'Expired Link'
              : 'Change Owner Confirmation',
        });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.confirmStatus && (
          <PageMessage>
            <h1 className="primary-title normalcase pb-xs">
              {this.state.title}
            </h1>
            <p>{this.state.message}</p>
            <ul className="control-list-component flex-h-center">
              <li>
                <NavLink
                  to={`/board/${this.props.match.params.routeBoardId}/view`}
                  className={'secondary-button'}
                >
                  Board Page
                </NavLink>
              </li>
            </ul>
          </PageMessage>
        )}
      </>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  ConfirmOwnerChange,
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      reloadBoard: ctx.reloadBoard,
    };
  },
);
