import React, { Component, MouseEvent, FocusEvent } from 'react';
import { ErrorNotificationPayload } from '../../../common/helpers/errorNotificationPayload';
import { SuccessNotificationPayload } from '../../../common/helpers/successNotificationPayload';
import { InfoNotificationPayload } from '../../../common/helpers/infoNotificationPayload';
import i18n from '../../../i18n';

interface Props {
  id: number;
  notificationData:
    | ErrorNotificationPayload
    | SuccessNotificationPayload
    | InfoNotificationPayload;
  onDestroy: () => void;
}

interface State {
  isExiting: boolean;
  touchStart: number | null;
  touchEnd: number | null;
}

class Notification extends Component<Props, State> {
  private timerId: NodeJS.Timeout | null = null;
  private exitTimerId: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isExiting: false,
      touchStart: null,
      touchEnd: null,
    };
  }

  componentDidMount() {
    this.setSelfDestruct();
  }

  setSelfDestruct() {
    // Set a timer to destroy the component after 5 seconds if it is not paused or an error notification
    const { notificationData } = this.props;
    if (!(notificationData instanceof ErrorNotificationPayload)) {
      this.timerId = setTimeout(() => {
        this.destroyNotification();
      }, 5000);
    }
  }

  clearSelfDestruct() {
    // Clear the timer when the component is destroyed
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  componentWillUnmount() {
    this.clearSelfDestruct();
  }

  handleMouseEnter = () => {
    if (this.timerId) {
      this.clearSelfDestruct();
    }
  };

  handleMouseLeave = () => {
    this.setSelfDestruct();
  };

  handleCloseClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Destroy the component when the user clicks the close button
    event.preventDefault();
    this.destroyNotification();
  };

  handleFocus = (event: FocusEvent<HTMLButtonElement | HTMLDivElement>) => {
    this.clearSelfDestruct();
  };

  handleBlur = (event: FocusEvent<HTMLButtonElement | HTMLDivElement>) => {
    this.setSelfDestruct();
  };

  handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    this.setState({
      touchEnd: null,
      touchStart: e.targetTouches[0].clientX,
    });
  };

  handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    this.setState({
      touchEnd: e.targetTouches[0].clientX,
    });
  };

  handleTouchEnd = () => {
    if (!this.state.touchStart || !this.state.touchEnd) return;

    const minDistance = 50;
    const distance = this.state.touchStart - this.state.touchEnd;
    const isLeftSwipe = distance > minDistance;
    const isRightSwipe = distance < -minDistance;

    if (isLeftSwipe || isRightSwipe) this.destroyNotification();
  };

  destroyNotification = () => {
    // Call the parent component's callback function to destroy this notification
    this.setState(
      {
        isExiting: true,
      },
      () => {
        setTimeout(() => {
          this.props.onDestroy();
        }, 200);
      },
    );
  };

  getType = () => {
    const { notificationData } = this.props;

    if (notificationData instanceof ErrorNotificationPayload) {
      return 'error';
    } else if (notificationData instanceof SuccessNotificationPayload) {
      return 'success';
    } else if (notificationData instanceof InfoNotificationPayload) {
      return 'info';
    }
    return null;
  };

  renderBody = () => {
    switch (this.getType()) {
      case 'error':
        return this.renderErrorContent();
      case 'success':
        return this.renderSuccessContent();
      case 'info':
        return this.renderInfoContent();
      default:
        return null;
    }
  };

  renderErrorContent = () => {
    const { notificationData } = this.props;

    if (notificationData instanceof ErrorNotificationPayload) {
      return (
        <div
          onTouchStart={(e) => this.handleTouchStart(e)}
          onTouchEnd={() => this.handleTouchEnd()}
          onTouchMove={(e) => this.handleTouchMove(e)}
        >
          <span className="us-none">
            <small className="fas fa-exclamation-triangle accent-text-red text-v-mid"></small>{' '}
            <span className="text-v-mid">{i18n.t('error')}</span>
          </span>
          <br />
          <p>
            {notificationData.validationCode && (
              <small className="negative-text pe-none">
                {notificationData.validationCode}
              </small>
            )}
          </p>
          {notificationData.validationMessage && (
            <p className="faint-text us-none">
              <small>{notificationData.validationMessage}</small>
            </p>
          )}
          {notificationData.message && (
            <p className="faint-text us-none">
              <small>{notificationData.message}</small>
            </p>
          )}
        </div>
      );
    }
  };

  renderSuccessContent = () => {
    const { notificationData } = this.props;

    if (notificationData instanceof SuccessNotificationPayload) {
      return (
        <div
          style={{
            paddingRight: '30px',
          }}
        >
          <span className="fas fa-check accent-text-green"></span>{' '}
          <span>Success</span>
          <br />
          <p>
            <small className="faint-text">{notificationData.message}</small>
          </p>
        </div>
      );
    }
  };

  renderInfoContent = () => {
    const { notificationData } = this.props;

    if (notificationData instanceof InfoNotificationPayload) {
      return (
        <div
          style={{
            paddingRight: '30px',
          }}
        >
          <span className="fas fa-info-circle"></span>{' '}
          <span className="">Info</span>
          <br />
          <p>
            <small className="faint-text">{notificationData.message}</small>
          </p>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        {this.getType() && (
          <div
            className={`toaster-entry ${
              !this.state.isExiting ? 'reveal-right-1' : 'hide-right-1'
            } card`}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            style={{
              animationDelay: `0.${this.props.id}s`,
            }}
          >
            {this.renderBody()}
            <button
              className="ghost-button top-tight right-tight"
              onClick={this.handleCloseClick}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            >
              <span className="fas fa-times"></span>
            </button>
          </div>
        )}
      </>
    );
  }
}

export default Notification;
