import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route } from 'react-router-dom';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';

const CONTAINER = document.getElementById('root');

if (CONTAINER) {
  const ROOT = createRoot(CONTAINER);

  ROOT.render(
    <BrowserRouter>
      <Route
        path="/"
        component={App}
      />
    </BrowserRouter>,
  );
}

reportWebVitals();
