import React, { Component } from 'react';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Button from '../../../controls/Button/Button';

interface Props extends WithTranslation {
  uxStep: number;
  onBegin: () => void;
  onEditStep: (step: string) => void;
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  organizingWith: 'justMe' | 'team' | undefined;
  choices: {
    teamName: string;
    boardName: string;
    columns: string[];
    tasks: string[];
    emails: string[];
  };
}

class StepSummary extends Component<Props> {
  render() {
    const { t, onBegin, onEditStep, choices } = this.props;

    return (
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-check-circle"></span>
        </div>
        <div className="column large text-left flex-v-center">
          <div>
            <h2>{t('step7.title', { count: this.props.uxStep })}</h2>
            <div className="scrollable-content flex-v-center">
              <div>
                <p>{t('step7.congratulations')}</p>
                <ul className="control-list-component vertical">
                  {this.props.organizingWith === 'team' && (
                    <li className="card fill flex-h-spread flex-v-center">
                      <div className="flex-v-center">
                        <span className="text-2xl icon fas fa-layer-group"></span>{' '}
                        <div className="pl-xs">
                          <span className="text">{t('step7.teamName')}</span>
                          <br />
                          <small>
                            {choices.teamName ? choices.teamName : 'My Team'}
                          </small>
                        </div>
                      </div>
                      <Button
                        className="secondary-button"
                        onClick={() => onEditStep('stepSpace')}
                      >
                        {t('step7.edit')}
                      </Button>
                    </li>
                  )}
                  <li className="card fill flex-h-spread flex-v-center">
                    <div className="flex-v-center">
                      <span className="text-2xl icon fas fa-columns"></span>{' '}
                      <div className="pl-xs">
                        <span className="text">{t('step7.boardName')}</span>
                        <br />
                        <small>
                          {choices.boardName ? choices.boardName : 'My Board'}
                        </small>
                      </div>
                    </div>
                    <Button
                      className="secondary-button"
                      onClick={() => onEditStep('stepBoard')}
                    >
                      {t('step7.edit')}
                    </Button>
                  </li>
                  {this.props.familiarity !== 'expert' && (
                    <>
                      <li className="card fill flex-h-spread flex-v-center">
                        <div className="flex-v-center">
                          <span className="text-2xl icon fas fa-line-columns"></span>{' '}
                          <div className="pl-xs">
                            <span className="text">{t('step7.columns')}</span>
                            <br />
                            <small>
                              {choices.columns.length
                                ? choices.columns.join(', ')
                                : 'My Column'}
                            </small>
                          </div>
                        </div>
                        <Button
                          className="secondary-button"
                          onClick={() => onEditStep('stepColumns')}
                        >
                          {t('step7.edit')}
                        </Button>
                      </li>
                      <li className="card fill flex-h-spread flex-v-center">
                        <div className="flex-v-center">
                          <span className="text-2xl icon fas fa-tasks"></span>{' '}
                          <div className="pl-xs">
                            <span className="text">{t('step7.tasks')}</span>
                            <br />
                            <small>
                              {choices.tasks.length
                                ? choices.tasks.join(', ')
                                : 'None'}
                            </small>
                          </div>
                        </div>
                        <Button
                          className="secondary-button"
                          onClick={() => onEditStep('stepTasks')}
                        >
                          {t('step7.edit')}
                        </Button>
                      </li>
                    </>
                  )}
                  {this.props.organizingWith === 'team' && (
                    <li className="card fill flex-h-spread flex-v-center">
                      <div className="flex-v-center">
                        <span className="text-2xl icon fas fa-users"></span>{' '}
                        <div className="pl-xs">
                          <span className="text">{t('step7.emails')}</span>
                          <br />
                          <small>
                            {choices.emails.length
                              ? choices.emails.join(', ')
                              : 'Just me'}
                          </small>
                        </div>
                      </div>
                      <Button
                        className="secondary-button"
                        onClick={() => onEditStep('stepMembers')}
                      >
                        {t('step7.edit')}
                      </Button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="button-container">
              <Button
                className="primary-button"
                onClick={onBegin}
              >
                {t('step7.begin')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('stepSummary')(StepSummary);
