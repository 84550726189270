import React, { Component } from 'react';

interface Props {
  onClick?:
    | (() => void)
    | ((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onBlur?: (() => void) | ((ev: React.FocusEvent<HTMLElement>) => void);
  className: string;
  disabled?: boolean;
  type?: 'submit' | 'button';
  id?: string;
  tabIndex?: number;
  value?: string;
  higherRef?: React.RefObject<HTMLButtonElement>;
  styles?: React.CSSProperties;
  title?: string;
  status?: 'loading' | 'success' | 'error' | 'idle';
  idleIcon?: React.ReactNode;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
}

class Button extends Component<Props> {
  render() {
    return (
      <>
        <button
          title={this.props.title}
          className={this.props.className}
          id={this.props.id}
          onClick={this.props.onClick}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled}
          type={this.props.type}
          tabIndex={this.props.tabIndex}
          value={this.props.value}
          ref={this.props.higherRef}
          style={this.props.styles}
          onKeyDown={this.props.onKeyDown}
        >
          {this.props.status && this.props.status === 'loading' && (
            <>
              <span className="loader"></span>{' '}
            </>
          )}
          {(!this.props.status || this.props.status === 'idle') &&
            this.props.idleIcon &&
            this.props.idleIcon}
          {this.props.children}
        </button>
      </>
    );
  }
}

export default Button;
