import { createResponseError } from './createResponseError';

const defaultReqInit: RequestInit = {
  mode: 'cors',
  credentials: 'include',
};

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

function createHeaders(): Record<string, string> {
  const headers: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const conId = sessionStorage.getItem('borddo-wsconid');
  if (conId) {
    headers['borddo-wsconid'] = conId;
  }
  return headers;
}

async function baseReq(
  method: Method,
  path: string,
  body?: unknown,
): Promise<Response> {
  const url = `${process.env.REACT_APP_API_BASE_URL}/${path}`;
  const options: RequestInit = {
    ...defaultReqInit,
    headers: createHeaders(),
    method,
  };

  if (body !== undefined) {
    options.body = JSON.stringify(body);
  }

  const result = await fetch(url, options);

  // handle unauthorized error
  if (result.status === 401) {
    // remove session info from local storage
    localStorage.removeItem('session_expiration_ms');

    // Dispatch 'logout' event to let the App component handle the logout
    window.dispatchEvent(new Event('logout'));

    throw await createResponseError(result);
  }

  if (!result.ok) {
    throw await createResponseError(result);
  }

  return result;
}

const requests = {
  async get(path: string) {
    return baseReq('GET', path);
  },
  async post(path: string, body?: unknown) {
    return baseReq('POST', path, body);
  },
  async patch(path: string, body?: unknown) {
    return baseReq('PATCH', path, body);
  },
  async put(path: string, body?: unknown) {
    return baseReq('PUT', path, body);
  },
  async delete(path: string, body?: unknown) {
    return baseReq('DELETE', path, body);
  },
};

export default requests;
