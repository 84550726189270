import { RegisterUserDTO } from '../dtos/User';
import requests from './baseRequest';

export async function register(body: RegisterUserDTO) {
  const result = await requests.post(`register`, body);
  return await result.json();
}

export async function resendConfirmation(email: string) {
  await requests.post(`sendConfirmEmail`, { email });
}

export async function confirmAccount(token: string) {
  await requests.patch(`confirmEmail`, { token });
}
