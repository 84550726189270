import React, { Component } from 'react';
import { flushSync } from 'react-dom';
import { RouteComponentProps } from 'react-router';
import ReactDOM from 'react-dom';
import isDescendant from '../../../common/helpers/isDescendant';

interface Props extends Partial<RouteComponentProps> {}

interface State {
  isOpen: boolean;
  hoveredElement: HTMLElement | null;
  transformStyle: React.CSSProperties | null;
  positionStyle: React.CSSProperties | null;
}

class Tooltip extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      hoveredElement: null,
      transformStyle: null,
      positionStyle: null,
    };
  }

  updateMenuState = (element: HTMLElement | null, menuState?: string) => {
    let ogElement = this.state.hoveredElement;
    // Don't allow react to batch set state operations together. Otherwise,
    // there's a chance that an update which opens the tooltip and one which
    // closes the tooltip will be batched together leaving the `ogElement`
    // for the second update as null. This happens if the updates are only
    // a few milliseconds apart.
    flushSync(() => {
      this.setState(
        {
          hoveredElement: element,
          isOpen: menuState === 'open' ? true : false,
        },
        () => {
          if (menuState === 'close' && ogElement) {
            ogElement.title = ogElement.dataset.title
              ? ogElement.dataset.title
              : '';
          }
        },
      );
    });
  };

  handleClose() {
    this.updateMenuState(null, 'close');
  }

  handleHover(ev: MouseEvent) {
    const target = ev.target as HTMLElement;

    if (
      target === this.state.hoveredElement ||
      isDescendant(document.querySelector('.tooltip-structure'), target)
    ) {
      return;
    }

    if (target.title) {
      if (this.state.hoveredElement !== null) {
        // restore old element
        let ogElement = this.state.hoveredElement;
        ogElement.title = ogElement.dataset.title
          ? ogElement.dataset.title
          : '';
      }

      // handle new element
      this.updateMenuState(target, 'open');
      target.dataset.title = target.title;
      target.title = '';
    } else {
      this.handleClose();
    }
  }

  handleScrollClose(e: Event) {
    if (
      !isDescendant(
        document.querySelector('.tooltip-structure'),
        e.target as HTMLElement,
      )
    ) {
      this.handleClose();
    }
  }

  handleContextButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  computeFixedPosition = (element: HTMLElement) => {
    const contextButtonRect = element.getBoundingClientRect();
    const borderOffset = 1;
    let buttonHeight = element.clientHeight;
    let buttonWidth = element.offsetWidth;
    // we take in account that we have to see the menu AND the button borders (top / bottom) at the same time
    buttonHeight += borderOffset;

    this.setState(
      {
        positionStyle: {
          right: 'auto',
          left: contextButtonRect.left,
          top: contextButtonRect.top + buttonHeight,
        },
      },
      () => {
        // we put this in the callback because we need to make sure we have the correct left & top
        const contextButtonRect = element.getBoundingClientRect();
        let translateY = `translateY(0)`;
        let translateX = `translateX(calc(-100% + ${buttonWidth}px))`;
        let right = 'auto';
        let left = contextButtonRect.left + 'px';

        if (contextButtonRect.left < window.innerWidth / 2) {
          translateX = `translateX(${borderOffset}px)`;
        } else {
          translateX = `translateX(0)`;
          left = 'auto';
          right =
            window.innerWidth -
            contextButtonRect.left -
            contextButtonRect.width +
            'px';
        }

        /*TODO: Consider removing this unless we hit odd cases again,
          the code for when contextButtonRect.left >
          window.innerWidth / 2 takes care of the issue
        */
        // if (relativeParentOffset(this.contextButtonRef.current!)) {
        //   translateX = `translateX(0)`;
        // }

        if (contextButtonRect.top > window.innerHeight / 2) {
          translateY = `translateY(calc(-100% - ${buttonHeight - 2}px))`;
        }

        this.setState({
          positionStyle: {
            ...this.state.positionStyle,
            left,
            right,
          },
          transformStyle: {
            transform: translateX + ' ' + translateY,
          },
        });
      },
    );
  };

  componentDidMount() {
    document.addEventListener('mouseover', (ev) => this.handleHover(ev));
    document.addEventListener('scroll', (e) => this.handleScrollClose(e), true);
    document.addEventListener('click', (e) => this.handleClose(), true);
    document.addEventListener('contextmenu', (e) => this.handleClose(), true);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        if (this.state.hoveredElement !== null) {
          this.computeFixedPosition(this.state.hoveredElement);
        }
      } else {
        this.setState({
          positionStyle: null,
          transformStyle: null,
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseover', (ev) => this.handleHover(ev));
    document.removeEventListener('click', (e) => this.handleClose(), true);
    document.removeEventListener(
      'contextmenu',
      (e) => this.handleClose(),
      true,
    );
    document.removeEventListener(
      'scroll',
      (e) => this.handleScrollClose(e),
      true,
    );

    this.setState({
      positionStyle: null,
      transformStyle: null,
    });
  }

  render() {
    return (
      <>
        {this.state.isOpen &&
          ReactDOM.createPortal(
            <div
              className="tooltip-component"
              style={{
                ...this.state.transformStyle,
                ...this.state.positionStyle,
              }}
            >
              {this.state.hoveredElement?.dataset.title && (
                <div className="tooltip-card open">
                  <small style={{ wordBreak: 'normal' }}>
                    {this.state.hoveredElement?.dataset.title}
                  </small>
                </div>
              )}
            </div>,
            document.querySelector('.tooltip-structure')!,
          )}
      </>
    );
  }
}

export default Tooltip;
