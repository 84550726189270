import React, { useContext } from 'react';

/**
 * Extract values from the context and pass them as props to the wrapped component.
 *
 * The types Q, C, A aren't infered properly so they need to be specified explicitly.
 */
export function withContextAdapter<EP, C, CP>(
  WrappedComponent: React.ComponentType<EP & CP>,
  ctx: React.Context<C>,
  adapt: (ctx: C, props: EP) => CP,
): React.ComponentType<EP> {
  const WrapperComponent: React.ComponentType<EP> = class extends React.Component<EP> {
    static contextType: React.Context<C>;

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...adapt(this.context, this.props)}
        />
      );
    }
  };

  WrapperComponent.contextType = ctx;
  WrapperComponent.displayName = `withContextAdapter(${WrappedComponent.name})`;
  return WrapperComponent;
}

type Adapter<C, CP, P> = {
  ctx: React.Context<C>;
  adapt: (ctx: C, props: P) => CP;
};

export function withContextAdapters<EP, C1, CP1, C2, CP2>(
  WrappedComponent: React.ComponentType<EP & CP1 & CP2>,
  adapter1: Adapter<C1, CP1, EP>,
  adapter2: Adapter<C2, CP2, EP>,
): React.ComponentType<EP> {
  function WrapperComponent(props: EP & React.Attributes) {
    const ctx1 = useContext(adapter1.ctx);
    const ctx2 = useContext(adapter2.ctx);

    return (
      <WrappedComponent
        {...props}
        {...adapter1.adapt(ctx1, props)}
        {...adapter2.adapt(ctx2, props)}
      />
    );
  }

  WrapperComponent.displayName = `withContextAdapters(${WrappedComponent.name})`;
  return WrapperComponent;
}
