import React from 'react';
import { CLEAR_EDITOR_COMMAND, LexicalEditor } from 'lexical';
import LexicalControl from '../../../controls/LexicalControl/LexicalControl';
import { createComment } from '../../../../common/api/endpoints/comment';
import { CommentDTO } from '../../../../common/api/dtos/Comment';
import { CUSTOM_TRANSFORMERS } from '../../../controls/LexicalControl/transformers/CustomTransformers';
import { $convertToMarkdownString } from '@lexical/markdown';
import Button from '../../../controls/Button/Button';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import RequestStatus from '../../RequestStatus/RequestStatus';
import { cn } from '../../../../common/helpers/cn';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  disabled: boolean;
  boardCard: BoardCard;
  addComment: (commentData: CommentDTO) => void;
  handleAddComment?: () => void;
  newCard: Boolean;
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];

  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface State {
  comment: string;
  editor: LexicalEditor | null;
  status: TRequestStatus;
  showLexical: boolean;
}

class AddCardComment extends React.Component<Props, State> {
  lexicalRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.lexicalRef = React.createRef();
    this.state = {
      comment: '',
      editor: null,
      status: 'idle',
      showLexical: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (this.props.newCard) {
      this.setState({
        comment: '',
        showLexical: false,
      });
    }
  }

  setComment = (value: string) => {
    this.setState({
      comment: value,
    });
  };

  setEditor = (editor: LexicalEditor | null) => {
    this.setState({
      editor: editor,
    });
  };

  createComment = async () => {
    this.setState({
      status: 'loading',
    });
    try {
      const newComment = await createComment(
        this.props.boardCard.id,
        this.state.comment,
      );

      this.setState({
        comment: '',
        status: 'success',
      });

      this.props.addComment(newComment);
      this.props.handleAddComment && this.props.handleAddComment();
      this.setShowLexical(false);
    } catch (err) {
      console.log(err);
      this.setState({
        comment: '',
        status: 'error',
      });
    }
  };

  handleCreateComment = () => {
    this.state.editor &&
      this.state.editor.getEditorState().read(() => {
        const markdown = $convertToMarkdownString(
          CUSTOM_TRANSFORMERS,
        ).replaceAll(/\n{2}/gm, '\n');

        this.setState(
          {
            comment: markdown,
          },
          () => {
            this.createComment();
          },
        );
      });
  };

  setShowLexical = (value: boolean) => {
    this.setState(
      {
        showLexical: value,
      },
      () => {
        if (value === false) {
          this.state.editor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
          this.setState({
            status: 'idle',
          });
        }
      },
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="flex-row fill">
          <div className="column pb-2xs">
            <div
              className={cn(
                'lexical-button-trigger',
                `add-comment-${this.props.boardCard.id}`,
                { hidden: this.state.showLexical },
              )}
              style={{
                display: `${this.state.showLexical ? 'none' : 'block'}`,
              }}
            >
              <Button
                className="secondary-button fill text-left flex-h-start"
                onClick={() => this.setShowLexical(true)}
              >
                <span className="faint-text">{t('writeComment')}</span>
              </Button>
            </div>
            <div className={`${this.state.showLexical ? '' : 'hidden'}`}>
              <LexicalControl
                disabled={Boolean(this.props.disabled)}
                placeholder={t('writeComment')}
                card={this.props.boardCard}
                handleChange={this.setComment}
                namespace={`add-comment-${this.props.boardCard.id}`}
                editor={this.state.editor}
                setEditor={this.setEditor}
                value={this.state.comment}
                updateTrigger={this.state.comment}
                showLexical={this.state.showLexical}
                setShowLexical={this.setShowLexical}
                buttonTriggered={true}
              />
            </div>
          </div>
        </div>
        {this.state.showLexical && (
          <ul className="control-list-component">
            <li>
              <Button
                className="primary-button"
                onClick={this.handleCreateComment}
                disabled={this.state.status === 'loading'}
              >
                <RequestStatus status={this.state.status} />
                <span className="text">{t('addComment')}</span>
              </Button>
            </li>
            <li>
              <Button
                className="secondary-button"
                onClick={() => this.setShowLexical(false)}
              >
                <span className="text">{t('cancel')}</span>
              </Button>
            </li>
          </ul>
        )}
      </>
    );
  }
}

export default withStyledTranslation('addCardComment')(AddCardComment);
