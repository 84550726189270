import React from 'react';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import { TContextMenu } from '../../../common/types/ContextMenu';
import PriorityManageMenu from '../../menus/Priority/PriorityManageMenu';
import { withContextAdapter } from '../ContextAdapter/withContextAdapter';
import BoardContext, {
  IBoardContext,
} from '../../../common/contexts/BoardContext';

interface ContextProps {
  priorities: PriorityDTO[];
}
interface ExternalProps {
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedPriority?: (priority: PriorityDTO) => void;
}
interface Props extends ContextProps, ExternalProps {}

class PriorityListUnorderable extends React.Component<Props> {
  render() {
    return (
      <ul
        id="unorderablePriorities"
        className="control-list-component vertical"
      >
        {this.props.priorities.map((priority: PriorityDTO) => {
          return (
            <PriorityManageMenu
              key={priority.id}
              priority={priority}
              setSelectedContext={this.props.setSelectedContext}
              setSelectedPriority={this.props.setSelectedPriority}
            />
          );
        })}
      </ul>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  PriorityListUnorderable,
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      priorities: ctx.board.priorities,
    };
  },
);
