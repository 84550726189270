import React from 'react';
import Button from '../../controls/Button/Button';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { PRIORITY_COLORS } from '../../../common/configs/priority';
import { getColorSymbol } from '../../../common/helpers/getColorSymbol';

interface Props {
  priority: PriorityDTO;
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedPriority?: (priority: PriorityDTO) => void;
}

export default class PriorityManageMenu extends React.Component<Props> {
  handleClick = () => {
    this.props.setSelectedPriority &&
      this.props.setSelectedPriority(this.props.priority);
    this.props.setSelectedContext &&
      this.props.setSelectedContext('editPriority');
  };

  render() {
    const symbol = getColorSymbol(PRIORITY_COLORS, this.props.priority.color);
    return (
      <li className="fill">
        <Button
          className="ghost-button fill flex-h-spread"
          onClick={this.handleClick}
        >
          <span className={`icon accent-text-${this.props.priority.color}`}>
            <span className={`cb-only icon mr-0 ${symbol} `}></span>
            <span className={`cb-none icon fas fa-circle`}></span>
          </span>
          <span className="text">{this.props.priority.name}</span>
          <span className="fas icon fa-pencil"></span>
        </Button>
      </li>
    );
  }
}
