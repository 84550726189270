import React from 'react';
import { PriorityDTO } from '../../../../../common/api/dtos/Priority';
import ContextMenu from '../../../../controls/ContextMenu/ContextMenu';
import { getColorSymbol } from '../../../../../common/helpers/getColorSymbol';
import { PRIORITY_COLORS } from '../../../../../common/configs/priority';
import { TCardData } from '../../../../../common/types/CardData';
import RemovePriorityMenu from './partial/RemovePriorityMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  cardId: string;
  title: string;
  number: number;
  prefix: string;
  description: string;
  selectedCardData: TCardData[];
  priority?: PriorityDTO;
  numberOfAttachments?: number;
  numberOfComments?: number;
}

class BoardCardDescription extends React.Component<Props> {
  isIconBarVisible = () => {
    return (
      (this.props.selectedCardData.indexOf('description') !== -1 &&
        this.props.description) ||
      (this.props.selectedCardData.indexOf('attachment') !== -1 &&
        this.props.numberOfAttachments &&
        this.props.numberOfAttachments > 0) ||
      (this.props.selectedCardData.indexOf('comment') !== -1 &&
        this.props.numberOfComments &&
        this.props.numberOfComments > 0)
    );
  };

  renderIconBar = () => {
    const { t } = this.props;
    return (
      !!this.isIconBarVisible() && (
        <div className="flex-row fill">
          <div className="column pb-0 flex-h-start">
            <ul className="icon-list-component">
              {this.props.selectedCardData.indexOf('description') !== -1 &&
                this.props.description && (
                  <li>
                    <span
                      className="far fa-text"
                      title={t('hasDescription')}
                    ></span>
                  </li>
                )}
              {this.props.numberOfAttachments! >= 1 &&
                this.props.selectedCardData.indexOf('attachment') !== -1 && (
                  <li>
                    <span
                      className="far fa-paperclip"
                      title={t('hasAttachments', {
                        count: this.props.numberOfAttachments,
                      })}
                    ></span>
                  </li>
                )}
              {this.props.selectedCardData.indexOf('comment') !== -1 &&
                this.props.numberOfComments! > 0 && (
                  <li>
                    <span
                      className="fal fa-comment"
                      title={t(`hasComments`, {
                        count: this.props.numberOfComments,
                      })}
                    ></span>
                  </li>
                )}
            </ul>
          </div>
        </div>
      )
    );
  };

  render() {
    const cardId = this.props.prefix
      ? `#${this.props.prefix}-${this.props.number}`
      : `#${this.props.number}`;

    const symbol = getColorSymbol(
      PRIORITY_COLORS,
      this.props.priority ? this.props.priority.color : '',
    );

    return (
      <>
        <div className="flex-row fill">
          <div className="column pb-0 pt-0 flex-v-center">
            {this.props.selectedCardData.indexOf('priority') !== -1 &&
              this.props.priority && (
                <ContextMenu
                  dept={0}
                  contextId={String(this.props.number)}
                  contextMenuClassName="align-h-start"
                  triggerClassDefault="ghost-button"
                  triggerClassActive="secondary-button"
                  isDisabled={Boolean(!this.props.priority)}
                  title={
                    this.props.priority ? this.props.priority.name : 'None'
                  }
                  triggerContent={
                    <div
                      className={`pe-none accent-text-${
                        this.props.priority ? this.props.priority.color : ''
                      }`}
                    >
                      {this.props.priority && (
                        <>
                          <span className={`cb-only ${symbol} pe-none`}></span>
                          <span className="cb-none fas fa-circle pe-none"></span>
                          &nbsp;
                        </>
                      )}
                    </div>
                  }
                  rightClickTrigger={true}
                >
                  <RemovePriorityMenu cardId={this.props.cardId} />
                </ContextMenu>
              )}
            {this.props.selectedCardData.indexOf('cardNumber') !== -1 && (
              <small className="faint-text">{cardId}</small>
            )}
          </div>
        </div>
        <p className="mb-0">{this.props.title}</p>
        {this.renderIconBar()}
      </>
    );
  }
}

export default withStyledTranslation('boardCardDescription')(
  BoardCardDescription,
);
