//Touched by Copilot
import React from 'react';
import Button from '../../../controls/Button/Button';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';

interface ImageZoomerState {
  scale: number;
  left: number;
  top: number;
  fitScale: number; // New state to track if the image is fit to screen
  isPinching: boolean; // New: Indicates if a pinch gesture is happening
  initialPinchDistance: number | null; // New: The initial distance between two fingers during a pinch
  animationInProgress: boolean;
  initialMidpoint: { x: number; y: number } | null;
  ignoreSingleTouch: boolean;
}

interface ImageZoomerProps extends WithTranslation {
  src: string; // Image source URL
  alt?: string; // Alternative text for the image
  goToPrevious: () => void;
  goToNext: () => void;
  introDirection?: 'prev' | 'next' | null;
}

class ImageZoomer extends React.Component<ImageZoomerProps, ImageZoomerState> {
  state: ImageZoomerState = {
    scale: 1,
    left: 50, // Start centered
    top: 50, // Start centered
    fitScale: 1, // Initially, the image is not fit to screen
    isPinching: false,
    initialPinchDistance: null,
    animationInProgress: false,
    initialMidpoint: null,
    ignoreSingleTouch: false,
  };

  private imageRef = React.createRef<HTMLImageElement>();
  private fullScreenRef = React.createRef<HTMLDivElement>();
  private containerRef = React.createRef<HTMLDivElement>();
  private animationFrameId: number | null = null;
  private targetScale: number = 1;
  private maxScale: number = 5; // 500%
  private minScale: number = 1; // 100%
  private startX: number = 0;
  private startY: number = 0;
  private containerResizeObserver: ResizeObserver | null = null;

  constructor(props: ImageZoomerProps) {
    super(props);
  }

  componentDidMount(): void {
    // Calculate and set fitScale as before
    const fitScale = this.calculateFitToScreenScale();

    // Calculate and set the initial scale to ensure the image does not overflow
    const initialScale = this.calculateInitialScale();
    this.minScale = initialScale;
    this.setState(
      {
        scale: initialScale,
        fitScale: fitScale,
      },
      () => {
        this.adjustForBoundaries(); // Adjust boundaries based on the initial scale
      },
    );

    this.containerResizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.handleResize();
      }
    });

    if (this.containerRef.current) {
      this.containerResizeObserver.observe(this.containerRef.current);
    }

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.handleSpacePress);
  }

  calculateInitialScale = (): number => {
    const image = this.imageRef.current;
    const container = this.containerRef.current;
    if (!image || !container) return 1; // Default scale is 1 if no image or container

    const containerRect = container.getBoundingClientRect();
    const imageNaturalWidth = image.naturalWidth;
    const imageNaturalHeight = image.naturalHeight;

    // Calculate scales to fit image width and height within the container
    const widthScale = containerRect.width / imageNaturalWidth;
    const heightScale = containerRect.height / imageNaturalHeight;

    // Use the smallest scale to ensure the image fits within the container without overflowing
    const initialScale = Math.min(widthScale, heightScale, 1); // Ensure scale does not exceed 1
    return initialScale;
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.handleSpacePress); // Add keydown listener
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    if (this.containerResizeObserver && this.containerRef.current) {
      this.containerResizeObserver.unobserve(this.containerRef.current);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ImageZoomerProps>,
    prevState: Readonly<ImageZoomerState>,
    snapshot?: any,
  ): void {
    const initialScale = this.calculateInitialScale();
    this.minScale = initialScale;
    if (prevProps.src !== this.props.src) {
      this.targetScale = initialScale;
      this.setState(
        {
          scale: initialScale,
        },
        () => {
          this.adjustForBoundaries();
          this.setState({
            fitScale: this.calculateFitToScreenScale(),
          });
        },
      );
    }
  }

  handleResize = () => {
    // Calculate and set fitScale as before
    const fitScale = this.calculateFitToScreenScale();

    // Calculate and set the initial scale to ensure the image does not overflow
    const initialScale = this.calculateInitialScale();
    this.minScale = initialScale;

    if (this.minScale < 1 || this.state.scale < this.minScale) {
      this.setState(
        {
          scale: initialScale,
          fitScale: fitScale,
        },
        () => {
          this.adjustForBoundaries(); // Adjust boundaries based on the initial scale
        },
      );
    } else {
      this.setState(
        {
          fitScale: fitScale,
        },
        () => {
          this.adjustForBoundaries(); // Adjust boundaries based on the initial scale
        },
      );
    }
  };

  private handleSpacePress = (event: KeyboardEvent): void => {
    if (event.code === 'Space') {
      event.preventDefault(); // Prevent the default action of the space bar
      this.targetScale =
        this.state.scale === this.minScale ? this.minScale * 2 : this.minScale;
      this.animateZoom();
    }
  };

  private handleDoubleClick = (): void => {
    this.targetScale =
      this.state.scale === this.minScale ? this.minScale * 2 : this.minScale;
    this.animateZoom();
  };

  private lastScrollTime = 0;
  private onWheel = (event: React.WheelEvent<HTMLDivElement>): void => {
    if (event.shiftKey) {
      event.preventDefault(); // Prevent zooming or any other default action

      // Determine the scroll direction
      const { deltaY } = event;
      if (this.state.animationInProgress) return;
      if (deltaY < 0) {
        // Scroll up, go to previous
        this.props.goToPrevious();
      } else if (deltaY > 0) {
        // Scroll down, go to next
        this.props.goToNext();
      }
      return; // Exit the method early since we handled the shift-scroll case
    }
    event.preventDefault();

    const currentTime = performance.now();
    const timeDelta = currentTime - this.lastScrollTime;
    this.lastScrollTime = currentTime;

    // Estimate scroll velocity as deltaY divided by time since last scroll
    // Use a default timeDelta if it's too small to avoid division by zero
    const scrollVelocity = event.deltaY / (timeDelta || 1);

    // Now use scrollVelocity to adjust your zoom factor...
    // This is a basic example; you'd adjust the calculations to fit your zoom logic
    const zoomFactor = 1.1 + Math.abs(scrollVelocity) * 0.05; // Example calculation

    const { deltaY } = event;

    const direction = deltaY < 0 ? 1 : -1; // Determine zoom direction

    // Calculate the new scale, adjusting for zoom direction
    let newScale =
      direction > 0
        ? Math.min(this.state.scale * zoomFactor, this.maxScale)
        : Math.max(this.state.scale / zoomFactor, this.minScale);

    // Logic for centering zoom around the mouse pointer remains the same
    const rect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    // Calculate new left and top to ensure zoom centers on the mouse cursor
    const newLeft =
      this.state.left -
      ((mouseX - this.state.left) * (newScale - this.state.scale)) /
        this.state.scale;
    const newTop =
      this.state.top -
      ((mouseY - this.state.top) * (newScale - this.state.scale)) /
        this.state.scale;

    // Call your method to animate the zoom and adjust for boundaries
    this.animateZoom(newScale, newLeft, newTop);
  };

  private adjustForNewScaleBoundaries = (
    scale: number,
    left: number,
    top: number,
  ) => {
    const image = this.imageRef.current;
    const container = this.containerRef.current;
    if (!image || !container) return { left, top };

    // Assuming container and image dimensions are available
    const containerRect = container.getBoundingClientRect();
    const imageWidth = image.naturalWidth * scale;
    const imageHeight = image.naturalHeight * scale;

    // Adjust left and top to ensure the image stays within bounds
    const maxLeft = 0;
    const maxTop = 0;
    const minLeft = containerRect.width - imageWidth;
    const minTop = containerRect.height - imageHeight;

    const adjustedLeft = Math.max(minLeft, Math.min(left, maxLeft));
    const adjustedTop = Math.max(minTop, Math.min(top, maxTop));

    return {
      left:
        imageWidth > containerRect.width
          ? adjustedLeft
          : (containerRect.width - imageWidth) / 2,
      top:
        imageHeight > containerRect.height
          ? adjustedTop
          : (containerRect.height - imageHeight) / 2,
    };
  };

  private introAnimation = (): string => {
    if (this.props.introDirection === null) {
      return '';
    }
    return this.props.introDirection === 'prev'
      ? 'reveal-right-1'
      : 'reveal-left-1';
  };

  private animateZoom = (
    newScale?: number,
    newLeft?: number,
    newTop?: number,
    callback?: () => void,
  ) => {
    // if (targetScale !== undefined) {
    //   targetScale = Math.max(
    //     this.minScale,
    //     Math.min(targetScale, this.maxScale),
    //   );
    // } else {
    //   targetScale = this.targetScale;
    // }
    // Cancel any ongoing animation
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
    }

    // Mark animation as in progress
    this.setState({ animationInProgress: true });

    const image = this.imageRef.current;
    const container = this.containerRef.current;
    if (!image || !container) {
      this.setState({ animationInProgress: false });
      return; // Exit if no image or container
    }

    // Start from the current state scale and adjust towards targetScale
    if (
      newScale !== undefined &&
      newLeft !== undefined &&
      newTop !== undefined
    ) {
      const boundaryAdjustedValues = this.adjustForNewScaleBoundaries(
        newScale,
        newLeft,
        newTop,
      );
      newLeft = boundaryAdjustedValues.left;
      newTop = boundaryAdjustedValues.top;
    }
    const targetScale = newScale !== undefined ? newScale : this.targetScale;
    const initialScale = this.state.scale;
    const scaleDiff = targetScale - initialScale;

    const containerRect = container.getBoundingClientRect();
    const finalWidth = image.naturalWidth * targetScale;
    const finalHeight = image.naturalHeight * targetScale;
    const finalLeft =
      newLeft !== undefined ? newLeft : (containerRect.width - finalWidth) / 2;
    const finalTop =
      newTop !== undefined ? newTop : (containerRect.height - finalHeight) / 2;

    const initialLeft = this.state.left;
    const initialTop = this.state.top;
    const leftDiff = finalLeft - initialLeft;
    const topDiff = finalTop - initialTop;

    let animationFrame = 0;
    const totalFrames = 24; // Total animation frames for the transition

    const animateStep = () => {
      animationFrame++;
      const progress = animationFrame / totalFrames;
      // Use linear easing for simplicity; consider ease-in-out for smoother transitions
      const easeProgress = progress;

      const currentScale = initialScale + scaleDiff * easeProgress;
      const currentLeft = initialLeft + leftDiff * easeProgress;
      const currentTop = initialTop + topDiff * easeProgress;

      // Update state with current values
      this.setState(
        {
          scale: currentScale,
          left: currentLeft,
          top: currentTop,
        },
        () => {
          if (animationFrame < totalFrames) {
            this.animationFrameId = requestAnimationFrame(animateStep);
          } else {
            this.setState({ animationInProgress: false }, callback);
            this.animationFrameId = null; // Reset the ID after animation completes
          }
        },
      );
    };

    // Start the animation
    this.animationFrameId = requestAnimationFrame(animateStep);
  };

  private onMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    event.preventDefault();
    this.startX = event.clientX;
    this.startY = event.clientY;
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  };

  private onMouseMove = (event: MouseEvent): void => {
    if (event.buttons !== 1) return;

    const dx = event.clientX - this.startX;
    const dy = event.clientY - this.startY;

    this.setState(
      (prevState) => ({
        left: prevState.left + dx,
        top: prevState.top + dy,
      }),
      () => {
        this.adjustForBoundaries();
        this.startX = event.clientX;
        this.startY = event.clientY;
      },
    );
  };

  private onMouseUp = (): void => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  private onTouchStart = (event: React.TouchEvent<HTMLDivElement>): void => {
    if (event.touches.length === 1) {
      const touch = event.touches[0];
      this.startX = touch.clientX; // Record the start X position
      this.startY = touch.clientY; // Record the start Y position
      this.setState({ ignoreSingleTouch: false, isPinching: false }); // Ensure pinch state is reset
    } else if (event.touches.length === 2) {
      const midpoint = this.getMidpointBetweenTouches(event.touches);
      this.setState({
        isPinching: true,
        initialPinchDistance: this.getDistanceBetweenTouches(event.touches),
        initialMidpoint: midpoint,
      });
    }
  };

  // Add this method to the class
  private getMidpointBetweenTouches = (
    touches: React.TouchList,
  ): { x: number; y: number } => {
    const touch1 = touches[0];
    const touch2 = touches[1];
    return {
      x: (touch1.clientX + touch2.clientX) / 2,
      y: (touch1.clientY + touch2.clientY) / 2,
    };
  };
  private onTouchMove = (event: React.TouchEvent): void => {
    if (this.state.ignoreSingleTouch && event.touches.length === 1) {
      return;
    }

    if (this.state.isPinching && event.touches.length === 2) {
      event.preventDefault();

      if (!this.state.initialPinchDistance || !this.state.initialMidpoint)
        return;

      const scaleChange =
        this.getDistanceBetweenTouches(event.touches) /
        this.state.initialPinchDistance;
      let newScale = this.state.scale * scaleChange;

      if (this.state.scale === this.maxScale && newScale > this.maxScale) {
        return; // Ignore the pinch completely if at max scale
      }

      newScale = Math.max(this.minScale, Math.min(newScale, this.maxScale));

      const midpoint = this.getMidpointBetweenTouches(event.touches);
      const containerRect = this.containerRef.current!.getBoundingClientRect();
      const pinchPointRelativeToContainer = {
        x: midpoint.x - containerRect.left,
        y: midpoint.y - containerRect.top,
      };

      const newLeft =
        this.state.left -
        (pinchPointRelativeToContainer.x - this.state.left) * (scaleChange - 1);
      const newTop =
        this.state.top -
        (pinchPointRelativeToContainer.y - this.state.top) * (scaleChange - 1);

      this.setState(
        {
          scale: newScale,
          left: newLeft,
          top: newTop,
          initialPinchDistance: this.getDistanceBetweenTouches(event.touches),
          initialMidpoint: midpoint,
        },
        this.adjustForBoundaries,
      );
    } else if (
      event.touches.length === 1 &&
      !this.state.isPinching &&
      this.state.scale > this.state.fitScale
    ) {
      // Single touch movement logic remains unchanged
      const touch = event.touches[0];
      this.setState(
        (prevState) => ({
          left: prevState.left + (touch.clientX - this.startX),
          top: prevState.top + (touch.clientY - this.startY),
        }),
        () => {
          this.adjustForBoundaries();
          this.startX = touch.clientX;
          this.startY = touch.clientY;
        },
      );
    }
  };

  private onTouchEnd = (event: React.TouchEvent<HTMLDivElement>): void => {
    if (this.state.isPinching) {
      // Ending a pinch gesture
      this.setState(
        {
          isPinching: false,
          initialPinchDistance: null,
          initialMidpoint: null,
          ignoreSingleTouch: true, // Ignore subsequent single touches briefly
        },
        () => {
          setTimeout(() => this.setState({ ignoreSingleTouch: false }), 100);
        },
      );
    } else if (
      !this.state.ignoreSingleTouch &&
      event.changedTouches.length === 1
    ) {
      if (
        this.state.scale > this.state.fitScale ||
        this.state.animationInProgress
      ) {
        return;
      }
      const touch = event.changedTouches[0];
      const endX = touch.clientX;

      if (this.startX - endX > 50) {
        // Swiped left
        this.props.goToNext();
      } else if (this.startX - endX < -50) {
        // Swiped right
        this.props.goToPrevious();
      }
    }
  };

  // Utility function to calculate the distance between two touches
  private getDistanceBetweenTouches = (touches: React.TouchList): number => {
    const touch1 = touches[0];
    const touch2 = touches[1];
    return Math.sqrt(
      Math.pow(touch2.clientX - touch1.clientX, 2) +
        Math.pow(touch2.clientY - touch1.clientY, 2),
    );
  };

  private fitToScreen = (): void => {
    // Update the state to fit the image to screen and mark it as fit to screen
    this.targetScale = this.state.fitScale!;
    this.animateZoom();
  };

  private oneToOne = (): void => {
    // Update the state to fit the image to screen and mark it as fit to screen
    this.targetScale = 1;
    this.animateZoom();
  };

  private calculateFitToScreenScale = (): number => {
    const image = this.imageRef.current;
    const container = this.containerRef.current;
    if (!image || !container) return 1; // Default scale is 1

    const containerRect = container.getBoundingClientRect();
    const imageRect = image.getBoundingClientRect();

    const widthScale =
      containerRect.width / (imageRect.width / this.state.scale);
    const heightScale =
      containerRect.height / (imageRect.height / this.state.scale);
    return Math.min(widthScale, heightScale);
  };

  private adjustForBoundaries = () => {
    const image = this.imageRef.current;
    const container = this.containerRef.current;
    if (!image || !container) return;

    const containerRect = container.getBoundingClientRect();
    const imageRect = image.getBoundingClientRect();

    const scale = this.state.scale;
    const imageScaledWidth = imageRect.width * scale;
    const imageScaledHeight = imageRect.height * scale;

    // Calculate boundaries
    let newLeft = this.state.left;
    let newTop = this.state.top;

    if (imageRect.width > containerRect.width) {
      const maxLeft = 0;
      const minLeft = containerRect.width - imageRect.width;
      newLeft = Math.min(Math.max(this.state.left, minLeft), maxLeft);
    } else {
      // Center the image if container is wider
      newLeft = (containerRect.width - imageRect.width) / 2;
    }

    if (imageRect.height > containerRect.height) {
      const maxTop = 0;
      const minTop = containerRect.height - imageRect.height;
      newTop = Math.min(Math.max(this.state.top, minTop), maxTop);
    } else {
      // Center the image if container is taller
      newTop = (containerRect.height - imageRect.height) / 2;
    }

    this.setState({
      left: newLeft,
      top: newTop,
    });
  };

  private updateScale = (scale: number) => {
    this.targetScale =
      scale < this.minScale
        ? this.minScale
        : scale > this.maxScale
        ? this.maxScale
        : scale;
    this.animateZoom();
  };

  toggleFullscreen = () => {
    // Assuming containerRef is the ref to the element you want in fullscreen
    const element = this.fullScreenRef.current;

    if (!element) return; // Guard clause if the element or ref is not available

    if (!document.fullscreenElement) {
      // Enter fullscreen
      if (element.requestFullscreen) {
        element.requestFullscreen().catch((err) => {
          console.error(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
          );
        });
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((err) => {
          console.error(
            `Error attempting to disable fullscreen mode: ${err.message} (${err.name})`,
          );
        });
      }
    }
  };

  isFullscreen = (): boolean => {
    return document.fullscreenElement != null;
  };

  render() {
    const { scale, left, top } = this.state;
    const { src, alt, t } = this.props;

    const scalePercentage = parseInt((scale * 100).toString());

    const zoomOutDisabled = scale === this.minScale;
    const zoomInDisabled = scale === this.maxScale;
    const fitToScreenDisabled = this.state.fitScale === scale;
    const oneToOneDisabled = scale === 1;
    const fullscreenTitle = this.isFullscreen()
      ? t('exitFullscreen')
      : t('enterFullscreen');

    const containerStyle: React.CSSProperties = {
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      height: '100%',
      cursor: 'grab',
    };

    const imageStyle: React.CSSProperties = {
      cursor: 'grab',
      position: 'absolute',
      transform: `translate(${left}px, ${top}px) scale(${scale})`,
      transformOrigin: '0 0',
    };

    return (
      <div
        className="showcased-image-wrapper"
        ref={this.fullScreenRef}
      >
        <div className="card backdrop top-tight left-tight px-xs py-2xs">
          <ul className="control-list-component">
            <li>
              <small>{scalePercentage}%</small>{' '}
            </li>
            <li>
              <Button
                onClick={() => this.updateScale(Math.round(scale) - 1)}
                disabled={zoomOutDisabled}
                className="ghost-button"
                title={t('zoomOut')}
              >
                <span className="pe-none far fa-search-minus"></span>
              </Button>
            </li>
            <li>
              <Button
                onClick={() => this.updateScale(Math.round(scale) + 1)}
                disabled={zoomInDisabled}
                className="ghost-button"
                title={t('zoomIn')}
              >
                <span className="pe-none far fa-search-plus"></span>
              </Button>
            </li>
            <li>
              <Button
                onClick={this.fitToScreen}
                disabled={fitToScreenDisabled}
                title={t('fitToScreen')}
                className="ghost-button"
              >
                <span className="pe-none fas fa-expand icon"></span>{' '}
              </Button>
            </li>
            <li>
              <Button
                onClick={this.oneToOne}
                disabled={oneToOneDisabled}
                title={t('zoomToActualSize')}
                className="ghost-button"
              >
                <span className="pe-none icon text-700">1:1</span>{' '}
              </Button>
            </li>
            <li>
              <Button
                onClick={this.toggleFullscreen}
                title={fullscreenTitle}
                className="ghost-button"
              >
                <span
                  className={`pe-none icon fas ${
                    this.isFullscreen() ? 'fa-compress-alt' : 'fa-expand-alt'
                  }`}
                ></span>{' '}
              </Button>
            </li>
          </ul>
        </div>
        <div
          ref={this.containerRef}
          className={`${this.introAnimation()}`}
          style={containerStyle}
          onDoubleClick={this.handleDoubleClick}
          onMouseDown={this.onMouseDown}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
          onWheel={this.onWheel}
        >
          <img
            ref={this.imageRef}
            src={src}
            alt={alt}
            style={imageStyle}
          />
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('imageZoomer')(ImageZoomer);
