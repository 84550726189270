import React, { Component } from 'react';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import Button from '../../controls/Button/Button';
import DirectionalButton from '../../controls/DirectionalButton/DirectionalButton';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  handleArchiveCards: () => void;
  totalCards: number;
}

class ColumnArchive extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                text: this.props.directionalButton.text,
                direction: this.props.directionalButton.direction,
                onClick: this.props.directionalButton.onClick,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        <li>
          <hr />
        </li>
        <li>
          <p
            className="text-sm"
            style={{
              maxWidth: '200px',
              whiteSpace: 'normal',
            }}
          >
            {t('bulkOperationArchiveCards')}
          </p>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <Button
            className="secondary-button fill"
            onClick={this.props.handleArchiveCards}
            disabled={this.props.totalCards === 0}
          >
            <span className="icon">
              <span className="fal fa-box"></span>
            </span>
            <span className="text">
              {t('archiveAllCards', { count: this.props.totalCards })}
            </span>
          </Button>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('columnArchive')(ColumnArchive);
