import React from 'react';

interface Props {
  scrollDirection?: 'vertical' | 'horizontal' | 'both';
  vScrollStyle?: React.CSSProperties;
  vScrollClassname?: string;
}

export const TransitionContext = React.createContext({ isExiting: false });

export function withTransitionEvent<P>(
  Component: React.ComponentType<P>,
  props?: Props,
) {
  return class TransitionEventComponent extends React.Component<P, any> {
    private testRef: React.RefObject<HTMLDivElement>;
    private timeout: any;

    constructor(props: P) {
      super(props);
      this.testRef = React.createRef();
      this.state = {
        isExiting: false,
        status: 'animating',
      };
    }

    componentDidMount() {
      this.timeout = setTimeout(() => {
        this.setState({
          status: 'done',
        });
      }, 200);
    }

    componentWillUnmount() {
      clearInterval(this.timeout);
    }

    scrollSelector(
      direction: 'vertical' | 'horizontal' | 'both' | undefined,
    ): string {
      switch (direction) {
        case 'vertical':
          return 'v-scroll';
        case 'horizontal':
          return 'h-scroll';
        case 'both':
          return 'vh-scroll';
        default:
          return '';
      }
    }

    render() {
      let vScrollStyle = props?.vScrollStyle ?? null;
      let scrollClass = this.scrollSelector(props?.scrollDirection);
      return (
        <TransitionContext.Provider value={{ isExiting: this.state.isExiting }}>
          <>
            <div
              className={[
                scrollClass,
                this.state.status === 'animating' ? 'reveal-up-1' : '',
                // !isBoardPage(window.location.pathname) ? 'reveal-up-1' : '',
                `${props?.vScrollClassname}`,
              ].join(' ')}
              style={{ ...vScrollStyle, height: '100%' }}
            >
              <div className="scroll-content">
                <Component {...this.props} />
              </div>
            </div>
          </>
        </TransitionContext.Provider>
      );
    }
  };
}
