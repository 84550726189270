import React from 'react';
import { TagDTO } from '../../../../../common/api/dtos/Tag';
import ContextMenu from '../../../../controls/ContextMenu/ContextMenu';
import TagContext from '../../../../menus/Tag/TagContext';
import { getColorSymbol } from '../../../../../common/helpers/getColorSymbol';
import { TAG_COLORS } from '../../../../../common/configs/tag';
import RemoveTagMenu from './partial/RemoveTagMenu';

interface Props {
  cardId: string;
  tagIds: string[];
  tags: TagDTO[];
  disabled?: boolean;
}

export default class BoardCardTags extends React.Component<Props> {
  render() {
    return (
      <div className="flex-row fill">
        <div className="column py-0">
          <ul className="control-list-component">
            {!this.props.disabled && (
              <li>
                <TagContext
                  disabled={Boolean(this.props.disabled)}
                  contextId={this.props.cardId}
                  selectedTags={this.props.tagIds}
                  amount={this.props.tagIds.length}
                  action={'assign'}
                  cardId={this.props.cardId}
                  contextMenuClassName="align-h-start"
                  triggerClassDefault="ghost-button"
                  triggerClassActive="secondary-button"
                  hideCounter={true}
                  iconClass="fal fa-tags"
                />
              </li>
            )}
            {this.props.tags?.length > 0 &&
              this.props.tags.map((tag) => {
                const symbol = getColorSymbol(TAG_COLORS, tag.color);
                return (
                  <li key={tag.id}>
                    <ContextMenu
                      dept={0}
                      contextId={tag.id}
                      contextMenuClassName="align-h-start"
                      triggerClassDefault="ghost-button"
                      triggerClassActive="secondary-button"
                      isDisabled={this.props.disabled}
                      triggerContent={
                        <>
                          <span
                            className={`cb-none badge-text uppercase accent-${tag.color}`}
                          >
                            {tag.name}
                          </span>
                          <span
                            className={`cb-only flag-text flag-text-${tag.color} uppercase`}
                          >
                            <span
                              className={`${symbol} accent-text-${tag.color}`}
                            ></span>{' '}
                            {tag.name}
                          </span>
                        </>
                      }
                      rightClickTrigger={true}
                    >
                      <RemoveTagMenu
                        cardId={this.props.cardId}
                        tagId={tag.id}
                        tagName={tag.name}
                      />
                    </ContextMenu>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}
