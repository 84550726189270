import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

// Extend the Props interface to include generic props for the WrappedComponent
interface Props extends WithTranslation {
  [key: string]: any; // Use an index signature to accept additional props
}

export const withStyledTranslation =
  (namespace?: string) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    class HOC extends React.Component<P & WithTranslation> {
      // Use generic type P combined with WithTranslation
      translate = (key: string, options: any = {}) => {
        const { t } = this.props;
        const translationOptions = {
          ...options,
          defaultValue: undefined,
          ns: namespace,
        };
        const translation = t(key, translationOptions);
        if (
          typeof translation === 'string' &&
          translation.startsWith('[missing:')
        ) {
          return <span className="accent-text-red">{translation}</span>;
        }
        return translation;
      };

      render() {
        // Use the rest operator to pass through all props
        const { t, ...restProps } = this.props;

        return (
          <WrappedComponent
            {...(restProps as P)} // Cast restProps back to P to satisfy TypeScript
            t={this.translate}
          />
        );
      }
    }

    // Apply the namespace to the `withTranslation` HOC if specified
    return namespace ? withTranslation(namespace)(HOC) : withTranslation()(HOC);
  };
