import React from 'react';
import Button from '../../controls/Button/Button';
import { TContextMenu } from '../../../common/types/ContextMenu';
import DirectionalButton from '../../controls/DirectionalButton/DirectionalButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import BoardContext from '../../../common/contexts/BoardContext';

interface Props extends WithTranslation {
  directionalButton: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  toggleShowConfirmDelete?: () => void;
}

class CardOptions extends React.Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <>
        <li>
          <DirectionalButton
            directionalButton={this.props.directionalButton}
            className={`flex-h-spread`}
          />
        </li>
        <li>
          <Button
            className="ghost-button"
            onClick={this.props.toggleShowConfirmDelete}
            disabled={
              !['admin', 'owner'].includes(this.context.board.user.role)
            }
          >
            <span className="fal fa-trash icon"></span>
            <span className="text">{t('delete')}</span>
          </Button>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('boardArchivedCardFlyout')(CardOptions);
CardOptions.contextType = BoardContext;
