import React, { Component } from 'react';

interface Props {
  width?: string;
  height?: string;
}

export default class ContentLoader extends Component<Props> {
  render() {
    return (
      <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
        <div
          className="placeholder card"
          style={{ height: this.props.height, width: this.props.width }}
        >
          <div className="background"></div>
        </div>
      </div>
    );
  }
}
