import React, { Component, ChangeEvent } from 'react';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Button from '../../../controls/Button/Button';
import TextBox from '../../../controls/TextBox/TextBox';

interface Props extends WithTranslation {
  uxStep: number;
  onNext: (tasks: string[]) => void;
  onSkip: () => void;
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  tasks: string[];
}

interface State {
  tasks: string[];
}

class StepTasks extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialTasks = this.props.tasks.length
      ? [...this.props.tasks, ...Array(3 - this.props.tasks.length).fill('')]
      : ['', '', '']; // Ensure there are always 3 input fields

    this.state = {
      tasks: initialTasks,
    };
  }

  handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const newTasks = [...prevState.tasks];
      newTasks[index] = value;
      return { tasks: newTasks };
    });
  };

  handleNext = () => {
    const nonEmptyTasks = this.state.tasks.filter((task) => task.trim() !== '');
    this.props.onNext(nonEmptyTasks);
  };

  handleSkip = () => {
    this.props.onSkip();
  };

  render() {
    const { t, familiarity } = this.props;
    const { tasks } = this.state;

    return (
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-tasks"></span>
        </div>
        <div className="column large text-left flex-v-center">
          <div>
            <h2>{t('step5.title', { count: this.props.uxStep })}</h2>
            <div className="scrollable-content flex-v-center">
              <div>
                <p>
                  {t(
                    familiarity === 'exploring'
                      ? 'step5.exploring'
                      : 'step5.advanced',
                  )}
                </p>
                <div>
                  {tasks.map((task, index) => (
                    <div
                      key={index}
                      className="input-container"
                    >
                      <TextBox
                        type="text"
                        value={task}
                        onChange={(event) =>
                          this.handleInputChange(index, event)
                        }
                        className={
                          index > 0 &&
                          !tasks[index - 1].trim() &&
                          !tasks[index].trim()
                            ? 'hidden'
                            : 'reveal-up-1'
                        }
                        placeholder={t('step5.placeholder')}
                        id={`task-${index}`}
                        name={`task-${index}`}
                        label=""
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="button-container">
              <Button
                className="ghost-button mr-xs px-sm"
                onClick={this.handleSkip}
              >
                {t('step5.skip')}
              </Button>
              <Button
                className="primary-button"
                onClick={this.handleNext}
                disabled={!tasks.filter((task) => task.trim() !== '').length} // Disable button if there are no non-empty tasks
              >
                {t('step5.next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('stepTasks')(StepTasks);
