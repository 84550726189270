import React from 'react';
import { PriorityDTO } from '../api/dtos/Priority';
import { InviteeDTO, MemberDTO } from '../api/dtos/Member';
import { TagDTO } from '../api/dtos/Tag';
import { ColumnDTO } from '../api/dtos/Column';
import { ArchivedCard } from '../../components/pages/Board/BoardArchive/BoardArchiveCards';
import { MsgWs } from '../../components/pages/Board/BoardProvider/wsHandlers';

interface IBoardCurrentUser {
  id: string;
  role: string;
}

export interface ICard {
  id: string;
  title: string;
  number: number;
  tagIds: string[];
  assigneeIds: string[];
  description: string;
  priorityId: string | null;
  numberOfAttachments: number;
  numberOfComments: number;
}

export interface IColumn extends ColumnDTO {
  cards: ICard[];
  visibleCards: ICard[];
}

export type CardFilterField =
  | 'tag'
  | 'member'
  | 'description'
  | 'cardNumber'
  | 'priority'
  | 'title';
export const CardFilterFields: CardFilterField[] = [
  'title',
  'priority',
  'cardNumber',
  'description',
  'member',
  'tag',
];

export interface IBoard {
  id: string;
  name: string;
  description: string;
  cardNrPrefix: string;
  color: string;
  columnCardLimitState: 'disabled' | 'enabled' | 'enforced';
  pattern: string;
  favorite: boolean;
  columns: IColumn[];
  archivedCards: ArchivedCard[] | null;
  priorities: PriorityDTO[];
  members: (MemberDTO | InviteeDTO)[];
  tags: TagDTO[];
  user: IBoardCurrentUser;
  cardFilter: {
    value: string | null;
    fields: CardFilterField[];
    count: number;
  };
  teamId: string;
  paid: boolean;
}

export interface UpdateColumnData {
  title?: string;
}
export interface UpdateCardData {
  title?: string;
  description?: string;
  priorityId?: string | null;
  assigneeIds?: string[];
  tagIds?: string[];
  numberOfAttachments?: number;
  numberOfComments?: number;
}
export interface UpdatePriorityData {
  name?: string;
  color?: string;
}
export interface UpdateTagData {
  name?: string;
  color?: string;
}
export interface UpdateMemberData {
  role?: string;
}

export interface UpdateBoardSettingsData {
  name?: string;
  description?: string;
  cardNrPrefix?: string;
  color?: string;
  pattern?: string;
  columnCardLimitState?: 'disabled' | 'enabled' | 'enforced';
}

export interface IBoardContext {
  board: IBoard;

  // Ids for which we have requests in progress. These are used on the
  // board details page to sync card and card flyout context menus.
  //
  // Note: these could be moved in a separate context
  loadingMemberIds: string[];
  loadingTagIds: string[];
  activeBoardMembersStatus: string[];
  setLoadingMemberIds: (ids: string[]) => void;
  setLoadingTagIds: (ids: string[]) => void;
  setActiveBoardMembersStatus: (userIds: string[]) => void;

  reloadBoard: () => void;
  toggleFavorite: () => void;
  filterCards: (value: string | null, fields: CardFilterField[]) => void;
  // columns
  addColumn: (item: ColumnDTO) => void;
  updateColumn: (id: string, data: UpdateColumnData) => void;
  removeColumn: (id: string) => void;
  removeCardsFromColumn: (columnId: string, cardIds: string[]) => void;
  moveColumn: (id: string, index: number) => void;
  // cards
  addCard: (columnId: string, item: ICard) => void;
  restoreCard: (columnId: string, item: ICard) => void;
  updateCard: (cardId: string, data: UpdateCardData) => void;
  moveCard: (
    cardId: string,
    srcColId: string,
    srcColIndex: number,
    dstColId: string,
    dstColIndex: number,
  ) => number;
  assignMember: (cardId: string, id: string) => void;
  unassignMember: (cardId: string, id: string) => void;
  assignTag: (cardId: string, id: string) => void;
  unassignTag: (cardId: string, id: string) => void;
  // priorities
  addPriority: (item: PriorityDTO) => void;
  updatePriority: (priorityId: string, data: UpdatePriorityData) => void;
  movePriority: (priorityId: string, index: number) => void;
  removePriority: (priorityId: string) => void;
  // tags
  addTag: (item: TagDTO) => void;
  updateTag: (tagId: string, data: UpdateTagData) => void;
  removeTag: (tagId: string) => void;
  // Members
  addMember: (item: MemberDTO | InviteeDTO) => void;
  updateMember: (id: string, data: UpdateMemberData) => void;
  removeMember: (id: string) => void;
  // Attachments
  addAttachment: (cardId: string) => void;
  removeAttachment: (cardId: string) => void;
  // Comments
  addComment: (cardId: string) => void;
  deleteComment: (cardId: string) => void;

  updateBoardSettings?: (
    boardSettings: UpdateBoardSettingsData,
    callback?: () => void,
  ) => void;

  setCurrentBoardUser?: (member: IBoardCurrentUser) => void;
  setArchived?: () => void;
  setArchivedCards?: (cards: ArchivedCard[] | null) => void;

  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;
}

// Before the board is loaded, the context is undefined.
const BoardContext = React.createContext<IBoardContext>({} as IBoardContext);

export default BoardContext;

export function addColumn(board: IBoard, column: ColumnDTO): IBoard {
  const newColumn = {
    ...column,
    cards: [],
    visibleCards: [],
  };

  return {
    ...board,
    columns: [...board.columns, newColumn],
  };
}

export function removeColumn(board: IBoard, id: string): IBoard {
  const column = [...board.columns].find((column) => column.id === id);
  const cards = column ? [...column.cards] : [];
  cards.forEach(
    (c) =>
      ((c as ArchivedCard).columnId = board.columns[0].id
        ? board.columns[0].id
        : ''),
  );

  const archivedCards =
    board.archivedCards && cards.length > 0
      ? [...board.archivedCards, ...(cards as ArchivedCard[])]
      : board.archivedCards
      ? board.archivedCards
      : cards.length > 0
      ? [...(cards as ArchivedCard[])]
      : null;

  return updateFilteredCardCount({
    ...board,
    columns: board.columns.filter((column) => column.id !== id),
    archivedCards: archivedCards,
  });
}

export function updateColumn(
  board: IBoard,
  id: string,
  data: UpdateColumnData,
): IBoard {
  const [colIndex, column] = getColumnById(board.columns, id);

  // clone and update column
  const newColumns = [...board.columns];
  newColumns[colIndex] = {
    ...column,
    ...data,
  };

  return {
    ...board,
    columns: newColumns,
  };
}

export function removeCardsFromColumn(
  board: IBoard,
  columnId: string,
  cardIds: string[],
): IBoard {
  const [colIndex, column] = getColumnById(board.columns, columnId);

  // clone and update column
  const newColumns = [...board.columns];
  newColumns[colIndex] = {
    ...column,
    cards: column.cards.filter((card) => !cardIds.includes(card.id)),
    visibleCards: column.visibleCards.filter(
      (card) => !cardIds.includes(card.id),
    ),
  };

  const newArchivedCards = column.cards.filter((card) =>
    cardIds.includes(card.id),
  );
  newArchivedCards.forEach((c) => ((c as ArchivedCard).columnId = columnId));

  const archivedCards: ArchivedCard[] | null = board.archivedCards
    ? [...board.archivedCards, ...(newArchivedCards as ArchivedCard[])]
    : null;

  return updateFilteredCardCount({
    ...board,
    columns: newColumns,
    archivedCards: archivedCards,
  });
}

export function moveColumn(
  board: IBoard,
  columnId: string,
  newIndex: number,
): IBoard {
  const [colIndex, column] = getColumnById(board.columns, columnId);

  // clone and update column
  const newColumns = [...board.columns];
  newColumns.splice(colIndex, 1);
  newColumns.splice(newIndex, 0, column);

  return {
    ...board,
    columns: newColumns,
  };
}

export function addCard(board: IBoard, columnId: string, card: ICard): IBoard {
  return addCardInternal(board, columnId, card);
}

export function restoreCard(
  board: IBoard,
  columnId: string,
  card: ICard,
): IBoard {
  const archivedCards: ArchivedCard[] | null = board.archivedCards
    ? [...board.archivedCards].filter((c) => c.id !== card.id)
    : null;

  return addCardInternal(board, columnId, card, 0, archivedCards);
}

/**
 * Add a new card to the given column and position.
 *
 * If `index` is not provided, the default position is the end of the column.
 */
function addCardInternal(
  board: IBoard,
  columnId: string,
  card: ICard,
  index?: number,
  archivedCards?: ArchivedCard[] | null,
): IBoard {
  const [colIndex, column] = getColumnById(board.columns, columnId);
  const newPosition = index === undefined ? column.cards.length : index;

  // clone and update column
  const newColumns = [...board.columns];
  const newCards = [...column.cards];
  newCards.splice(newPosition, 0, card);

  // apply filter to card if necessary
  let visibleCards = column.visibleCards;
  const filterFn = filterCardFn(board);
  if (filterFn(card)) {
    visibleCards = newCards.filter(filterFn);
  }

  newColumns[colIndex] = {
    ...column,
    cards: newCards,
    visibleCards,
  };

  return updateFilteredCardCount({
    ...board,
    columns: newColumns,
    archivedCards: archivedCards
      ? archivedCards
      : board.archivedCards
      ? board.archivedCards
      : null,
  });
}

export function updateCard(
  board: IBoard,
  cardId: string,
  data: UpdateCardData,
): IBoard {
  return updateCardInternal(board, cardId, (card) => {
    return {
      ...card,
      ...data,
    };
  });
}

export function assignMember(
  board: IBoard,
  cardId: string,
  assigneeId: string,
) {
  return updateCardInternal(board, cardId, (card) => {
    return {
      ...card,
      assigneeIds: [...card.assigneeIds, assigneeId],
    };
  });
}

export function unassignMember(
  board: IBoard,
  cardId: string,
  assigneeId: string,
) {
  return updateCardInternal(board, cardId, (card) => {
    return {
      ...card,
      assigneeIds: card.assigneeIds.filter((id) => id !== assigneeId),
    };
  });
}

export function assignTag(board: IBoard, cardId: string, assigneeId: string) {
  return updateCardInternal(board, cardId, (card) => {
    return {
      ...card,
      tagIds: [...card.tagIds!, assigneeId],
    };
  });
}

export function unassignTag(board: IBoard, cardId: string, assigneeId: string) {
  return updateCardInternal(board, cardId, (card) => {
    return {
      ...card,
      tagIds: card.tagIds!.filter((id) => id !== assigneeId),
    };
  });
}

export function addAttachment(board: IBoard, cardId: string) {
  return updateCardInternal(board, cardId, (card) => {
    card.numberOfAttachments = card.numberOfAttachments || 0;
    return {
      ...card,
      numberOfAttachments: card.numberOfAttachments! + 1,
    };
  });
}

export function removeAttachment(board: IBoard, cardId: string) {
  return updateCardInternal(board, cardId, (card) => {
    card.numberOfAttachments = card.numberOfAttachments || 0;
    return {
      ...card,
      numberOfAttachments: card.numberOfAttachments! - 1,
    };
  });
}

export function addComment(board: IBoard, cardId: string) {
  return updateCardInternal(board, cardId, (card) => {
    card.numberOfComments = card.numberOfComments || 0;
    return {
      ...card,
      numberOfComments: card.numberOfComments! + 1,
    };
  });
}

export function deleteComment(board: IBoard, cardId: string) {
  return updateCardInternal(board, cardId, (card) => {
    card.numberOfComments = card.numberOfComments || 0;
    return {
      ...card,
      numberOfComments: card.numberOfComments! - 1,
    };
  });
}

function updateCardInternal(
  board: IBoard,
  cardId: string,
  fn: (card: ICard) => ICard,
): IBoard {
  const [colIndex, column, cardIndex, card] = findCard(board, cardId);

  // clone and update cards
  const newCards = [...column.cards];
  newCards[cardIndex] = fn(card);

  // apply filter to updated card if necessary
  let visibleCards = column.visibleCards;
  if (column.visibleCards.some((c) => c.id === cardId)) {
    const filterFn = filterCardFn(board);
    visibleCards = column.visibleCards
      .map((card) =>
        card.id === newCards[cardIndex].id ? newCards[cardIndex] : card,
      )
      .filter(filterFn);
  }

  // clone and update column
  const newColumns = [...board.columns];
  newColumns[colIndex] = {
    ...column,
    cards: newCards,
    visibleCards,
  };

  return updateFilteredCardCount({
    ...board,
    columns: newColumns,
  });
}

function findCard(
  board: IBoard,
  cardId: string,
): [number, IColumn, number, ICard] {
  // todo (dragos): benchmark and check if this needs to be optimized
  //
  // Easy optimization implementation is to map card id to column id; downside
  // is that it increase code complexity due to the need to manage the map
  for (let colIndex = 0; colIndex < board.columns.length; colIndex++) {
    const column = board.columns[colIndex];
    for (let cardIndex = 0; cardIndex < column.cards.length; cardIndex++) {
      const card = column.cards[cardIndex];
      if (card.id === cardId) {
        return [colIndex, column, cardIndex, card];
      }
    }
  }
  throw new Error(`Could not find card with id ${cardId}`);
}

export function moveCard(
  board: IBoard,
  cardId: string,
  srcColId: string,
  srcColIndex: number,
  dstColId: string,
  dstColIndex: number,
): [IBoard, number] {
  let newColumns: IColumn[] = [];

  const [srcColumnIndex, srcColumn] = getColumnById(board.columns, srcColId);
  const [dstColumnIndex, dstColumn] = getColumnById(board.columns, dstColId);

  // get card index and object; the index is from the whole list of cards, not just the visible list of cards
  const srcCardIndex = srcColumn.cards.findIndex((card) => card.id === cardId);
  // get card index and object; the index is from the visible list of cards
  const srcCardIndexVisible = srcColumn.visibleCards.findIndex(
    (card) => card.id === cardId,
  );

  if (srcCardIndex === -1)
    throw new Error(
      `Expected to find card with id ${cardId} in column with id ${srcColumn.id}`,
    );
  const draggedCard = srcColumn.cards[srcCardIndex];

  // Clone source and destination cards
  const srcNewCards = Array.from(srcColumn.cards);
  const srcNewVisibleCards = Array.from(srcColumn.visibleCards);

  let dstNewCards;
  let dstNewVisibleCards;

  // Use the same arrays if the we're targeting the same column
  if (dstColId === srcColId) {
    dstNewCards = srcNewCards;
    dstNewVisibleCards = srcNewVisibleCards;
  } else {
    dstNewCards = Array.from(dstColumn.cards);
    dstNewVisibleCards = Array.from(dstColumn.visibleCards);
  }

  // Remove card from source
  srcNewCards.splice(srcCardIndex, 1);
  srcNewVisibleCards.splice(srcCardIndexVisible, 1);

  // Find neighbours based on visible cards
  const prevNeighbourVisible = dstNewVisibleCards[dstColIndex - 1];
  const nextNeighbourVisible = dstNewVisibleCards[dstColIndex];

  // prioritize position after previous neighbour, followed by position after
  // next neighbour, followed by start of the list
  const destIndex = prevNeighbourVisible
    ? // if the dragged card is on the same column and the dragged card index is smaller than the previous neighbour index
      // we use the index of previous neighbour because the dragged card was already removed from the column
      // otherwise we are adding the card after the previous neighbour
      dstColId === srcColId &&
      srcCardIndexVisible <
        dstColumn.cards.findIndex((c) => c.id === prevNeighbourVisible.id)
      ? dstColumn.cards.findIndex((c) => c.id === prevNeighbourVisible.id)
      : dstColumn.cards.findIndex((c) => c.id === prevNeighbourVisible.id) + 1
    : nextNeighbourVisible
    ? dstColumn.cards.findIndex((c) => c.id === nextNeighbourVisible.id)
    : 0;

  const destIndexVisible = prevNeighbourVisible
    ? dstColId === srcColId &&
      srcCardIndexVisible <
        dstColumn.visibleCards.findIndex(
          (c) => c.id === prevNeighbourVisible.id,
        )
      ? dstColumn.visibleCards.findIndex(
          (c) => c.id === prevNeighbourVisible.id,
        )
      : dstColumn.visibleCards.findIndex(
          (c) => c.id === prevNeighbourVisible.id,
        ) + 1
    : nextNeighbourVisible
    ? dstColumn.visibleCards.findIndex((c) => c.id === nextNeighbourVisible.id)
    : 0;

  dstNewCards.splice(destIndex, 0, draggedCard);
  dstNewVisibleCards.splice(destIndexVisible, 0, draggedCard);

  newColumns = Array.from(board.columns);
  newColumns[srcColumnIndex] = {
    ...srcColumn,
    cards: srcNewCards,
    visibleCards: srcNewVisibleCards,
  };
  newColumns[dstColumnIndex] = {
    ...dstColumn,
    cards: dstNewCards,
    visibleCards: dstNewVisibleCards,
  };
  let newIndex;
  if (dstNewCards.length > dstNewVisibleCards.length) {
    newIndex = destIndexVisible;
  } else {
    newIndex = destIndex;
  }

  return [
    {
      ...board,
      columns: newColumns,
    },
    newIndex,
  ];
}

function getColumnById(
  columns: IColumn[],
  columnId: string,
): [number, IColumn] {
  const columnIndex = columns.findIndex((column) => column.id === columnId);
  if (columnIndex === -1) {
    throw new Error(`Expected to find column with id ${columnId}`);
  }

  return [columnIndex, columns[columnIndex]];
}

export function filterCards(
  board: IBoard,
  value: string | null,
  fields: CardFilterField[],
): IBoard {
  let countFilteredCards = 0;
  const newColumns = [...board.columns];
  const filterFn = filterCardFn({
    ...board,
    cardFilter: {
      ...board.cardFilter,
      value,
      fields,
    },
  });

  // filter and count visible cards
  for (let index = 0; index < newColumns.length; index++) {
    const col = newColumns[index];
    newColumns[index] = {
      ...newColumns[index],
      visibleCards: col.cards.filter(filterFn),
    };
    countFilteredCards += newColumns[index].visibleCards.length;
  }

  if (window.location.href.includes('board-archived-cards')) {
    if (value) {
      localStorage.setItem(
        `boardFilterArchiveCardsValue-${board.id}-${board.user.id}`,
        value,
      );
    } else
      localStorage.removeItem(
        `boardFilterArchiveCardsValue-${board.id}-${board.user.id}`,
      );

    localStorage.setItem(
      `boardFilterArchiveCardsFields-${board.id}-${board.user.id}`,
      JSON.stringify(fields),
    );
  } else {
    if (value) {
      localStorage.setItem(
        `boardFilterValue-${board.id}-${board.user.id}`,
        value,
      );
    } else
      localStorage.removeItem(`boardFilterValue-${board.id}-${board.user.id}`);

    localStorage.setItem(
      `boardFilterFields-${board.id}-${board.user.id}`,
      JSON.stringify(fields),
    );
  }

  return {
    ...board,
    columns: newColumns,
    cardFilter: {
      value,
      fields,
      count: countFilteredCards,
    },
  };
}

function filterCardFn(board: IBoard): (card: ICard) => boolean {
  const value = board.cardFilter.value;
  const fields = board.cardFilter.fields;

  if (value === null) {
    return (_) => true;
  }

  const filters: string[] | null | undefined =
    value?.match(/(?:[^\s"]+|"[^"]*")+/g);
  let newFilters = filters?.map((el: string) => {
    return toLowerNormalized(el).replaceAll('"', '');
  });

  const normValue = toLowerNormalized(value);
  let checks: Array<(_: ICard) => boolean> = [];

  if (fields.includes('title')) {
    newFilters?.forEach((filter) => {
      checks.push((card: ICard) => {
        return toLowerNormalized(card.title).includes(filter);
      });
    });
  }

  if (fields.includes('priority')) {
    const map: Record<string, { name: string; color: string }> = {};
    board.priorities.forEach((priority) => {
      map[priority.id] = {
        name: priority.name,
        color: priority.color,
      };
    });

    newFilters?.forEach((filter) => {
      checks.push((card: ICard) => {
        const name = toLowerNormalized(
          card.priorityId ? map[card.priorityId].name : 'None',
        );
        return name.includes(filter);
      });
    });
  }

  if (fields.includes('cardNumber')) {
    newFilters?.forEach((filter) => {
      checks.push((card: ICard) => {
        return toLowerNormalized(
          `#${board.cardNrPrefix}-${card.number}`,
        ).includes(filter);
      });
    });
  }

  if (fields.includes('description')) {
    newFilters?.forEach((filter) => {
      checks.push((card: ICard) => {
        return toLowerNormalized(card.description).includes(filter);
      });
    });
  }

  if (fields.includes('member')) {
    const map: Record<
      string,
      { name?: string; email?: string; role?: string }
    > = {};
    board.members.forEach((member) => {
      map[member.id] = {
        name: member.name,
        role: member.role,
      };
      if (member.hasOwnProperty('email')) {
        map[member.id].email = (member as InviteeDTO).email;
      }
    });

    newFilters?.forEach((filter) => {
      checks.push((card: ICard) => {
        return card.assigneeIds.some((assigneeId) => {
          const name = toLowerNormalized(map[assigneeId].name || '');
          const email = toLowerNormalized(map[assigneeId].email || '');
          const role = toLowerNormalized(map[assigneeId].role || '');
          return (
            name.includes(filter) ||
            email.includes(filter) ||
            role.includes(filter)
          );
        });
      });
    });
  }

  if (fields.includes('tag')) {
    const map: Record<string, { name: string; color: string }> = {};
    board.tags.forEach((tag) => {
      map[tag.id] = {
        name: tag.name,
        color: tag.color,
      };
    });

    newFilters?.forEach((filter) => {
      checks.push((card: ICard) => {
        return card.tagIds.some((tagId) => {
          const name = toLowerNormalized(map[tagId].name);
          return name.includes(filter);
        });
      });
    });
  }

  return (card: ICard) => checks.some((fn) => fn(card));
}

function toLowerNormalized(str: string): string {
  return str
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();
}

export function clearFilterCards(board: IBoard): IBoard {
  const newColumns = [...board.columns];

  // filter and count visible cards
  for (const col of newColumns) {
    col.visibleCards = col.cards;
  }

  return {
    ...board,
    columns: newColumns,
    cardFilter: {
      ...board.cardFilter,
      value: null,
    },
  };
}

function updateFilteredCardCount(board: IBoard): IBoard {
  if (board.cardFilter !== null) {
    const filteredCardCount = countVisibleCards(board.columns);

    // create a new board object if the filtered card count changed
    if (board.cardFilter.count !== filteredCardCount) {
      return {
        ...board,
        cardFilter: {
          ...board.cardFilter,
          count: filteredCardCount,
        },
      };
    }
    return board;
  }
  return board;
}

function countVisibleCards(columns: IColumn[]): number {
  return columns
    .map((column) => column.visibleCards.length)
    .reduce((a, b) => a + b, 0);
}

export function addPriority(board: IBoard, priority: PriorityDTO): IBoard {
  return {
    ...board,
    priorities: [...board.priorities, priority],
  };
}

export function updatePriority(
  board: IBoard,
  priorityId: string,
  data: UpdatePriorityData,
): IBoard {
  const [index, priority] = getPriorityById(board.priorities, priorityId);

  // clone and update priorities
  const newPriorities = [...board.priorities];
  newPriorities[index] = {
    ...priority,
    ...data,
  };

  return {
    ...board,
    priorities: newPriorities,
  };
}

export function movePriority(
  board: IBoard,
  priorityId: string,
  newIndex: number,
): IBoard {
  const [index, priority] = getPriorityById(board.priorities, priorityId);

  // clone and update column
  const newPriorities = [...board.priorities];
  newPriorities.splice(index, 1);
  newPriorities.splice(newIndex, 0, priority);

  return {
    ...board,
    priorities: newPriorities,
  };
}

export function removePriority(board: IBoard, priorityId: string): IBoard {
  // Remove the priority for all the cards in the board. This is the guaranteed
  // side-effect on the server as well.
  //
  // We only change the `cards` or `columns` properties if we removed the priority
  // from a card.

  let removeFromCards = (cards: ICard[]) => {
    let foundInCards = false;
    let newCards = cards.map((card) => {
      if (card.priorityId === priorityId) {
        foundInCards = true;
        return {
          ...card,
          priorityId: null,
        };
      }
      return card;
    });
    return foundInCards ? newCards : cards;
  };

  return {
    ...board,
    columns: removeEntityFromColumns(board.columns, removeFromCards),
    priorities: board.priorities.filter(
      (priority) => priority.id !== priorityId,
    ),
  };
}

function getPriorityById(
  items: PriorityDTO[],
  id: string,
): [number, PriorityDTO] {
  const index = items.findIndex((column) => column.id === id);
  if (index === -1) {
    throw new Error(`Expected to find priority with id ${id}`);
  }

  return [index, items[index]];
}

export function addTag(board: IBoard, item: TagDTO): IBoard {
  return {
    ...board,
    tags: [...board.tags, item],
  };
}

export function updateTag(
  board: IBoard,
  tagId: string,
  data: UpdateTagData,
): IBoard {
  const [index, tag] = getTagById(board.tags, tagId);

  // clone and update tags
  const newTags = [...board.tags];
  newTags[index] = {
    ...tag,
    ...data,
  };

  return {
    ...board,
    tags: newTags,
  };
}

export function removeTag(board: IBoard, tagId: string): IBoard {
  // Remove the tag from all the cards in the board. This is the guaranteed
  // side-effect on the server as well.
  //
  // We only change the `cards` or `columns` properties if we removed the priority
  // from a card.
  let removeFromCards = (cards: ICard[]) => {
    let foundInCards = false;
    let newCards = cards.map((card) => {
      const newTags = card.tagIds.filter((id) => id !== tagId);

      if (card.tagIds.length !== newTags.length) {
        foundInCards = true;
        return {
          ...card,
          tagIds: newTags,
        };
      }
      return card;
    });
    return foundInCards ? newCards : cards;
  };

  return {
    ...board,
    columns: removeEntityFromColumns(board.columns, removeFromCards),
    tags: board.tags.filter((item) => item.id !== tagId),
  };
}

function removeEntityFromColumns(
  columns: IColumn[],
  removeFromCards: (cards: ICard[]) => ICard[],
): IColumn[] {
  let foundInColumns = false;
  const newColumns = columns.map((column) => {
    const newCards = removeFromCards(column.cards);
    const newVisibleCards = removeFromCards(column.visibleCards);

    const differentCards = newCards !== column.cards;
    const differentVisibleCards = newVisibleCards !== column.visibleCards;

    if (differentCards || differentVisibleCards) {
      foundInColumns = true;
      return {
        ...column,
        cards: differentCards ? newCards : column.cards,
        visibleCards: differentVisibleCards
          ? newVisibleCards
          : column.visibleCards,
      };
    }
    return column;
  });

  return foundInColumns ? newColumns : columns;
}

function getTagById(items: TagDTO[], id: string): [number, TagDTO] {
  const index = items.findIndex((item) => item.id === id);
  if (index === -1) throw new Error(`Expected to find tag with id ${id}`);
  return [index, items[index]];
}

export function addMember(board: IBoard, item: MemberDTO | InviteeDTO): IBoard {
  const updatedItem = {
    ...item,
    invited: true,
  };
  // TODO: sort function to match the sort made on boardLoad
  const sortedMembers = [...board.members, updatedItem];
  return {
    ...board,
    members: sortedMembers,
  };
}
export function updateMember(
  board: IBoard,
  id: string,
  data: UpdateMemberData,
): IBoard {
  const [index, tag] = getMemberById(board.members, id);

  // clone and update tags
  const newMembers = [...board.members];
  newMembers[index] = {
    ...tag,
    ...data,
  };

  return {
    ...board,
    members: newMembers,
    user: {
      ...board.user,
      role: data.role && board.user.id === id ? data.role : board.user.role,
    },
  };
}
export function removeMember(board: IBoard, assigneeId: string): IBoard {
  let removeFromCards = (cards: ICard[]) => {
    let foundInCards = false;
    let newCards = cards.map((card) => {
      const newAssignees = card.assigneeIds.filter((id) => id !== assigneeId);

      if (card.assigneeIds.length !== newAssignees.length) {
        foundInCards = true;
        return {
          ...card,
          assigneeIds: newAssignees,
        };
      }
      return card;
    });
    return foundInCards ? newCards : cards;
  };

  return {
    ...board,
    columns: removeEntityFromColumns(board.columns, removeFromCards),
    members: board.members.filter((item) => item.id !== assigneeId),
  };
}

function getMemberById(
  items: (MemberDTO | InviteeDTO)[],
  id: string,
): [number, MemberDTO | InviteeDTO] {
  const index = items.findIndex((item) => item.id === id);
  if (index === -1) throw new Error(`Expected to find member with id ${id}`);
  return [index, items[index]];
}

export function updateBoardSettings(
  board: IBoard,
  newBoardSettings: UpdateBoardSettingsData,
  callback?: () => void,
): IBoard {
  newBoardSettings.color &&
    board.id &&
    localStorage.setItem(
      board.id,
      JSON.stringify({
        name: board.name,
        color: newBoardSettings.color,
      }),
    );

  return {
    ...board,
    ...newBoardSettings,
  };
}

export function changeBoardOwner(board: IBoard, newOwnerId: string): IBoard {
  const updatedMembers = board.members
    .map((m) => ({
      ...m,
      role:
        m.id === newOwnerId ? 'owner' : m.role === 'owner' ? 'admin' : m.role,
    }))
    .filter((m) => !m.hasOwnProperty('invited'));
  const boardInvitees = board.members.filter((m) =>
    m.hasOwnProperty('invited'),
  );
  const [newOwnerIndex, newOwnerData] = getMemberById(
    updatedMembers,
    newOwnerId,
  );
  const updatedUser = {
    ...board.user,
    role:
      board.user.id === newOwnerId
        ? 'owner'
        : board.user.role === 'owner'
        ? 'admin'
        : board.user.role,
  };
  const sortedMembers = [
    newOwnerData,
    ...boardInvitees,
    ...updatedMembers.filter((m) => m.role !== 'owner'),
  ];

  return {
    ...board,
    members: sortedMembers,
    user: updatedUser,
  };
}

export function updateInvitee(board: IBoard, id: string): IBoard {
  const updatedMembers = [...board.members];
  const member = updatedMembers.find((m) => m.id === id);

  if (member && 'invited' in member) {
    delete member.invited;
  }

  return {
    ...board,
    members: updatedMembers,
  };
}

export function deleteArchivedCard(board: IBoard, id: string): IBoard {
  const newArchivedCards = board.archivedCards
    ? [...board.archivedCards].filter((c) => c.id !== id)
    : null;
  return {
    ...board,
    archivedCards: newArchivedCards,
  };
}
