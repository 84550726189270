import { CommentDTO, UpdateCommentDTO } from '../dtos/Comment';
import requests from './baseRequest';

export async function createComment(
  cardId: string,
  content: string,
): Promise<CommentDTO> {
  const result = await requests.post(`card/${cardId}/comment`, {
    content: content,
  });
  return await result.json();
}

export async function updateComment(
  commentId: string,
  content: string,
): Promise<UpdateCommentDTO> {
  const result = await requests.patch(`comment/${commentId}`, {
    content: content,
  });
  return await result.json();
}

export async function deleteComment(id: string): Promise<void> {
  await requests.delete(`comment/${id}`);
}
