import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useRef } from 'react';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from '@lexical/markdown';
import React from 'react';
import { CLEAR_HISTORY_COMMAND, LexicalEditor } from 'lexical';
import { CUSTOM_TRANSFORMERS } from '../transformers/CustomTransformers';

interface Props {
  card: { id: string; description: string };
  handleChange?: (value: string, cardId?: string) => void;
  setEditor?: (editor: LexicalEditor) => void;
  handleClose?: () => void;
  value: string;
  updateTrigger?: string;
  prevEditor?: LexicalEditor | null;
}

export default function CustomPlugins(props: Props): React.ReactElement {
  const [editor] = useLexicalComposerContext();
  const prevPropsRef = useRef<Props | null>(null);

  // lexical editor updates on each card.id change
  // because lexical has it's own state, we need this when we change cards without closing the flyout
  useEffect(() => {
    // save card description if the flyout card is changed while editing
    if (
      prevPropsRef.current &&
      prevPropsRef.current?.card.id !== props.card.id &&
      props.prevEditor
    ) {
      if (props.prevEditor?.isEditable()) {
        props.prevEditor &&
          props.prevEditor.getEditorState().read(() => {
            const markdown = $convertToMarkdownString(
              CUSTOM_TRANSFORMERS,
            ).replaceAll(/\n{2}/gm, '\n');

            // we need to send the previous card id to saveCard function in order to save the description for the previous card.
            props.handleChange &&
              props.handleChange(markdown, prevPropsRef.current?.card.id);
          });
      }
    }

    if (editor) {
      // setting the lexical editor in BoardCardFlyout.tsx so we can access editor methods (we need useLexicalComposerContext hook to access the editor) in parent components
      // editor will be used (read, update, conversions) in BoardCardFlyout.tsx and LexicalControl.tsx
      props.setEditor && props.setEditor(editor);

      // update lexical state with the new selected card description
      updateValue(editor);

      prevPropsRef.current = props;
      return () => {
        // remove editor history (UNDO, REDO) on card change
        editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
      };
    }
  }, [props.card.id]);

  useEffect(() => {
    if (editor) {
      // update lexical state with the new selected card description
      // avoid updating the description twice when card id changes
      // card id change will also trigger a description update
      if (
        prevPropsRef.current &&
        prevPropsRef.current.card.id === props.card.id &&
        prevPropsRef.current.updateTrigger !== props.updateTrigger
      ) {
        updateValue(editor);
      }
    }
  }, [props.updateTrigger]);

  const updateValue = (editor: LexicalEditor) => {
    editor.update(() => {
      if (props.value !== '') {
        $convertFromMarkdownString(props.value, CUSTOM_TRANSFORMERS);
      } else {
        $convertFromMarkdownString('');
      }
    });
  };

  return <></>;
}
