import React from 'react';
import LinkButton from '../../controls/LinkButton/LinkButton';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {}

class NewMenu extends React.Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <>
        <li>
          <LinkButton
            to="/board-add"
            className="ghost-button"
          >
            <div style={{ maxWidth: '250px', whiteSpace: 'normal' }}>
              <span className="icon fas fa-plus-circle"></span>
              <span className="text">{t('board')}</span>
              <br />
              <span className="faint-text pt-xs">{t('boardDescription')}</span>
            </div>
          </LinkButton>
        </li>
        <li>
          <LinkButton
            to="/team-add"
            className="ghost-button"
          >
            <div style={{ maxWidth: '250px', whiteSpace: 'normal' }}>
              <span className="icon fas fa-plus-circle"></span>
              <span className="text">{t('team')}</span>
              <br />
              <span className="faint-text pt-xs">{t('teamDescription')}</span>
            </div>
          </LinkButton>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('newMenu')(NewMenu);
