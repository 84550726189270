import { Component } from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';

export interface Props {
  status: TRequestStatus;
}

interface State {
  successExpired: boolean;
}

class RequestStatus extends Component<Props, State> {
  timeoutID: null | NodeJS.Timeout = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      successExpired: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.status !== prevProps.status) {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
        this.timeoutID = null;
      }

      this.setState({
        successExpired: false,
      });

      if (this.props.status === 'success') {
        // Set a new timeout
        this.timeoutID = setTimeout(() => {
          this.setState({
            successExpired: true,
          });
          // Clear the timeout ID after it's executed
          this.timeoutID = null;
        }, 2000);
      }
    }
  }

  render() {
    return (
      <span
        className={`
        icon
        status
        ${
          this.props.status !== 'idle' && !this.state.successExpired
            ? ''
            : 'collapsed'
        }
      `}
      >
        {this.props.status === 'loading' && <span className="loader"></span>}
        {this.props.status === 'success' && !this.state.successExpired && (
          <span className="success-icon fas fa-check"></span>
        )}
        {this.props.status === 'error' && (
          <span className="error-icon fas fa-exclamation-triangle"></span>
        )}
      </span>
    );
  }
}

export default RequestStatus;
