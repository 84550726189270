import React from 'react';

interface Props {
  connectionStatus: 'lost' | 'reconnecting' | 'online' | null;
}

export class ConnectionNotification extends React.Component<Props> {
  render() {
    switch (this.props.connectionStatus) {
      case 'lost':
        return <ConnectionLostText />;
      case 'reconnecting':
        return <ReconnectingText />;
      case 'online':
        return <OnlineText />;
      default:
        return null;
    }
  }
}

function ConnectionLostText() {
  return (
    <div className="card bottom left opaque">
      <small>
        <span className="fas fa-circle accent-text-red pr-xs"></span>
        <span>Connection lost</span>
      </small>
    </div>
  );
}

function ReconnectingText() {
  return (
    <div className="card bottom left opaque">
      <small>
        <span className="fas fa-circle accent-text-yellow pr-xs"></span>
        <span>Reconnecting...</span>
      </small>
    </div>
  );
}

function OnlineText() {
  return (
    <div className="card bottom left opaque">
      <small>
        <span className="fas fa-circle accent-text-green pr-xs"></span>
        <span>Back online</span>
      </small>
    </div>
  );
}
