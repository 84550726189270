import React, { Component } from 'react';
import Dialog from '../../controls/Dialog/Dialog';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import ctrlDetect from '../../../common/helpers/ctrlDetect';

interface Props extends WithTranslation {
  onClose: () => void;
}

class HelpDialog extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <Dialog
        size="large"
        title={t('shortcuts')}
        cancelText={t('close')}
        onCancel={() => this.props.onClose()}
        onConfirm={() => {}}
      >
        <div className="tableview-component my-sm us-none">
          <table>
            <thead>
              <tr>
                <th style={{ width: '1px' }}>{t('key')}</th>
                <th>{t('action')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="uncounted">
                <td>
                  <div className="inline-block card px-xs py-xs text-sm text-500 mr-xs">
                    ESC
                  </div>
                </td>
                <td>
                  <ul className="control-list-component vertical">
                    <li>
                      <span>{t('cancel_prompt')}</span>
                    </li>
                    <li>
                      <span>{t('close_modal')}</span>
                    </li>
                    <li>
                      <span>{t('close_card_flyout')}</span>
                    </li>
                    <li>
                      <span>{t('close_context_menu')}</span>
                    </li>
                    <li>
                      <span>{t('close_dropdown')}</span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="uncounted">
                <td>
                  <div className="inline-block card px-xs py-xs text-sm text-500 mr-xs">
                    /
                  </div>
                </td>
                <td>
                  <span>{t('view_shortcuts')}</span>
                </td>
              </tr>
              <tr className="uncounted">
                <td>
                  <div className="inline-block card px-xs py-xs text-sm text-500 mr-xs">
                    {ctrlDetect()}+V
                  </div>
                </td>
                <td>
                  <span>{t('paste_files')}</span>
                </td>
              </tr>
              <tr className="uncounted">
                <td>
                  <div className="inline-block card px-xs py-xs text-sm text-500 mr-xs">
                    {ctrlDetect()}+F
                  </div>
                </td>
                <td>
                  <span>{t('filter')}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Dialog>
    );
  }
}

export default withStyledTranslation('helpDialog')(HelpDialog);
