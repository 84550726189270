import React from 'react';
import { TContextMenu } from '../../../common/types/ContextMenu';
import DirectionalButton from '../../controls/DirectionalButton/DirectionalButton';
import AppContext, {
  IAppContext,
  NotificationMessage,
} from '../../../common/contexts/AppContext';
import LinkButton from '../../controls/LinkButton/LinkButton';
import Button from '../../controls/Button/Button';
import { setFavoriteBoard } from '../../../common/api/endpoints/board';
import { showErrorNotifications } from '../../../common/helpers/showNotifications';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import BoardContext, {
  IBoardContext,
} from '../../../common/contexts/BoardContext';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  isMember: boolean;
}
interface ExternalProps {
  favorite?: boolean;
  routeBoardId: string;
  setSelectedContext: (context: TContextMenu) => void;
  toggleFavorite: () => void;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

interface State {
  favoriteStatus: TRequestStatus;
}

class BoardOperationsMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      favoriteStatus: 'idle',
    };
  }

  setFavorite = async () => {
    this.setState({
      favoriteStatus: 'loading',
    });

    try {
      await setFavoriteBoard(this.props.routeBoardId, !this.props.favorite);
      this.props.toggleFavorite();

      this.setState({
        favoriteStatus: 'success',
      });
    } catch (error) {
      showErrorNotifications(error, this.props.setMessages);
    } finally {
      this.setState({
        favoriteStatus: 'idle',
      });
    }
  };

  render() {
    const { t } = this.props;
    const favoriteClassname = this.props.favorite ? 'fas' : 'far';
    return (
      <>
        <li>
          <LinkButton
            className="ghost-button"
            to={`/board/${this.props.routeBoardId}/board-archived-cards`}
            activeClassName="active disabled"
          >
            <span className="icon">
              <span className="fas fa-credit-card-blank"></span>
            </span>
            <span className="text">{t('archivedCards')}</span>
          </LinkButton>
        </li>
        <li>
          <LinkButton
            className={`ghost-button ${this.props.isMember ? 'disabled' : ''}`}
            to={`/board/${this.props.routeBoardId}/board-settings`}
            activeClassName="active disabled"
          >
            <span className="icon">
              <span className="fal fa-cog"></span>
            </span>
            <span className="text">{t('boardSettings')}</span>
          </LinkButton>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <div className="context-menu-wrapper fill align-h-start">
            <DirectionalButton
              directionalButton={{
                text: t('managePriorities'),
                direction: 'right',
                onClick: () =>
                  this.props.setSelectedContext('managePriorities'),
              }}
              className={`flex-h-spread ${
                this.props.isMember ? 'disabled' : ''
              }`}
            />
          </div>
        </li>
        <li>
          <div className="context-menu-wrapper fill align-h-start">
            <DirectionalButton
              directionalButton={{
                text: t('manageTags'),
                direction: 'right',
                onClick: () => this.props.setSelectedContext('TagManageMenus'),
              }}
              className={`flex-h-spread ${
                this.props.isMember ? 'disabled' : ''
              }`}
            />
          </div>
        </li>
        <li>
          <div className="context-menu-wrapper fill align-h-start">
            <DirectionalButton
              directionalButton={{
                text: t('leaveBoard'),
                direction: 'right',
                onClick: () => this.props.setSelectedContext('leaveBoard'),
              }}
              className={`flex-h-spread`}
            />
          </div>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <Button
            className="secondary-button fill"
            onClick={this.setFavorite}
            disabled={this.state.favoriteStatus === 'loading'}
          >
            <span className={`icon ${favoriteClassname} fa-star`}></span>
            <span className="text">
              {this.props.favorite ? t('unfavoriteBoard') : t('favoriteBoard')}
            </span>
          </Button>
        </li>
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext) => {
    return {
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      isMember: ctx.board.user.role === 'member',
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('boardOperationsMenu')(BoardOperationsMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
