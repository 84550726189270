import { UserAccountSettingsDTO } from '../dtos/User';
import requests from './baseRequest';

interface RequestBody {
  theme?: string;
  showPatterns?: boolean;
  reducedMotion?: boolean;
  colorBlindHelpers?: boolean;
}

export async function getUserSettings(): Promise<UserAccountSettingsDTO> {
  const result = await requests.get('self/settings');
  return await result.json();
}

export async function updateUserSettings(
  body: RequestBody,
): Promise<UserAccountSettingsDTO> {
  const result = await requests.patch('self/settings', body);
  return await result.json();
}
