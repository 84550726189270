import { LexicalEditor } from 'lexical';
import React, { Component } from 'react';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { deleteCard, restoreCard } from '../../../../common/api/endpoints/card';
import { addColumn } from '../../../../common/api/endpoints/column';
import AppContext, {
  IAppContext,
  NotificationMessage,
} from '../../../../common/contexts/AppContext';
import BoardContext, {
  IBoardContext,
  ICard,
} from '../../../../common/contexts/BoardContext';
import { IRestoreColumn } from '../../../../common/interfaces/RestoreColumn';
import Button from '../../../controls/Button/Button';
import ComboBox from '../../../controls/ComboBox/ComboBox';
import { withContextAdapters } from '../../ContextAdapter/withContextAdapter';
import BoardCardFlyoutTemplate from './BoardCardFlyoutTemplate';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import { IBoardCurrentUser } from '../../../../common/interfaces/BoardCurrentUser';
import { ActivityDTO } from '../../../../common/api/dtos/Activity';
import { CommentDTO } from '../../../../common/api/dtos/Comment';
import { MsgWs } from '../../../pages/Board/BoardProvider/wsHandlers';
import Dialog from '../../../controls/Dialog/Dialog';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import ContextMenu from '../../../controls/ContextMenu/ContextMenu';
import DirectionalButton from '../../../controls/DirectionalButton/DirectionalButton';
import RestoreCardContext from '../../../menus/Archive/RestoreCardContext';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  boardId: string;
  cardNrPrefix: string;
  tags: TagDTO[];
  priorities: PriorityDTO[];
  members: (MemberDTO | InviteeDTO)[];
  addColumn: (item: ColumnDTO) => void;
  restoreCard: (columnId: string, card: ICard) => void;
  boardUser: IBoardCurrentUser;
  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;
}
interface ExternalProps {
  onClose: () => void;

  boardCard: BoardCard;
  columnId: string | null;

  removeCard: (cardId: string) => void;
  restoreColumns?: IRestoreColumn[];
  columnTitles: Partial<ColumnDTO>[];
}
interface Props
  extends AppContextProps,
    ExternalProps,
    BoardContextProps,
    WithTranslation {}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  tagIds: string[];
  assigneeIds: string[];
  description: string;
  priorityId: string | null;
  numberOfAttachments: number;
  numberOfComments: number;
}

interface FormData {
  restoreColumnId: string;
}

interface State {
  serverErrors: string[];
  showConfirmDelete: boolean;
  formData: FormData;
  updatingCard: boolean;
  showAddcomment: boolean;
  descriptionEditor: LexicalEditor | null;
  groupedActivityItems: (ActivityDTO | CommentDTO)[];
  nextPage: string | null;
  rawActivityItems: (ActivityDTO | CommentDTO)[];
}

class BoardArchivedCardFlyout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      serverErrors: [],
      showConfirmDelete: false,
      updatingCard: false,
      showAddcomment: false,
      formData: {
        restoreColumnId:
          // if the archive card has an origin column, set that as the default selected restore column
          // else, select the first one in the restore columns list
          // we also make sure the `restoreColumns` exists in order to handle the case when we are not on the `Archive Cards` page
          this.props.columnId
            ? this.props.columnId
            : this.props.restoreColumns
            ? (this.props.restoreColumns[0]?.id as string)
            : '',
      },
      descriptionEditor: null,
      groupedActivityItems: [],
      nextPage: null,
      rawActivityItems: [],
    };
  }

  setEditor = (editor: any) => {
    this.setState({
      descriptionEditor: null,
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.boardCard !== this.props.boardCard) {
      this.initFyoutCard();
    }
  }

  componentDidMount() {
    this.initFyoutCard();
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      const isContextMenuOpened = Boolean(
        document.querySelector('.context-menu-component'),
      );
      const isImageViewerOpened = Boolean(
        document.querySelector('.image-viewer-component'),
      );
      const isDialogOpened = Boolean(
        document.querySelector('.dialog-component'),
      );
      // prevent closing the flyout if a context menu is opened
      // let the context menu close first
      if (isContextMenuOpened || isImageViewerOpened || isDialogOpened) return;
      this.props.onClose();
    }
  };

  initFyoutCard = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        restoreColumnId: this.props.columnId
          ? this.props.columnId
          : this.props.restoreColumns
          ? (this.props.restoreColumns[0]?.id as string)
          : '',
      },
      showConfirmDelete: false,
    });
  };

  handleCloseButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.onClose();
    }
  };

  toggleShowConfirmDelete = () => {
    this.setState((prevState) => {
      return {
        showConfirmDelete: !prevState.showConfirmDelete,
      };
    });
  };

  handleDeleteCard = async () => {
    try {
      await deleteCard(this.props.boardCard.id);
      this.props.removeCard(this.props.boardCard.id);
      this.props.onClose();
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      this.setState({
        serverErrors: error,
      });
    }
  };

  handleRestoreCard = async () => {
    try {
      this.setState({
        updatingCard: true,
      });

      if (this.state.formData.restoreColumnId !== undefined) {
        await restoreCard(
          this.props.boardCard.id!,
          this.state.formData.restoreColumnId,
        );
        this.props.restoreCard(
          this.state.formData.restoreColumnId,
          this.props.boardCard,
        );
      } else {
        const column = await addColumn(this.props.boardId!, 'My List');
        await restoreCard(this.props.boardCard.id!, column.id);

        this.props.addColumn(column);
        this.props.restoreCard(column.id, this.props.boardCard);
      }

      this.props.removeCard(this.props.boardCard.id);

      this.setState({
        updatingCard: false,
      });
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      this.setState({
        serverErrors: error,
      });
    }
  };

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      },
    });
  }

  setRestoreColumnId = (
    _: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<Element>,
    restoreColumnId: number | string | IRestoreColumn | undefined,
  ) => this.updateForm('restoreColumnId', String(restoreColumnId));

  render() {
    const { t } = this.props;
    const restoreColumns: IRestoreColumn[] | undefined =
      this.props.restoreColumns !== undefined &&
      this.props.restoreColumns!.length > 0
        ? this.props.restoreColumns!
        : [{ id: null, title: t('myList') }];
    const restoreColumnId =
      this.state.formData.restoreColumnId !== undefined
        ? this.state.formData.restoreColumnId
        : null;

    return (
      <BoardCardFlyoutTemplate
        disabled={true}
        cardNrPrefix={this.props.cardNrPrefix}
        boardCard={this.props.boardCard}
        tags={this.props.tags}
        priorities={this.props.priorities}
        members={this.props.members}
        serverErrors={this.state.serverErrors}
        descriptionEditor={null}
        handleFlyoutClose={this.props.onClose}
        handleCloseButtonKeyDown={this.handleCloseButtonKeyDown}
        setMessages={this.props.setMessages}
        setDescriptionEditor={this.setEditor}
        columnTitles={this.props.columnTitles}
        boardUser={this.props.boardUser}
        addWsListener={this.props.addWsListener}
        removeWsListener={this.props.removeWsListener}
      >
        <>
          <li className="flex-h-end-self initiate-delete">
            <RestoreCardContext
              restoreColumnId={restoreColumnId}
              restoreColumns={restoreColumns}
              setRestoreColumnId={this.setRestoreColumnId}
              updatingCard={this.state.updatingCard}
              handleRestoreCard={this.handleRestoreCard}
              triggerClassDefault="ghost-button"
              triggerClassActive="secondary-button"
              contextMenuClassName="align-h-start"
              toggleShowConfirmDelete={this.toggleShowConfirmDelete}
            />
            {this.state.showConfirmDelete && (
              <>
                <Dialog
                  title="Delete card"
                  message="Permanently delete this card?"
                  onConfirm={this.handleDeleteCard}
                  onCancel={this.toggleShowConfirmDelete}
                  confirmText={t('deleteNow')}
                  cancelText={t('no')}
                />
              </>
            )}
          </li>
        </>
      </BoardCardFlyoutTemplate>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      boardId: ctx.board.id,
      cardNrPrefix: ctx.board.cardNrPrefix,
      tags: ctx.board.tags,
      priorities: ctx.board.priorities,
      members: ctx.board.members,
      addColumn: ctx.addColumn,
      restoreCard: ctx.restoreCard,
      boardUser: ctx.board.user,
      addWsListener: ctx.addWsListener,
      removeWsListener: ctx.removeWsListener,
    };
  },
};
export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('boardArchivedCardFlyout')(BoardArchivedCardFlyout),
  AppContextAdapter,
  BoardContextAdapter,
);
