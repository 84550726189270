import { CardDTO } from '../dtos/Card';
import { ColumnDTO } from '../dtos/Column';
import requests from './baseRequest';

export async function addColumn(id: string, title: string): Promise<ColumnDTO> {
  const result = await requests.post(`board/${id}/column`, { title });
  return await result.json();
}

export async function updateColumn(
  id: string,
  data: {
    title?: string;
    cardLimit?: number;
  },
): Promise<Omit<ColumnDTO, 'cards'>> {
  const result = await requests.patch(`column/${id}`, data);
  return await result.json();
}

export async function deleteColumn(id: string) {
  const result = await requests.delete(`column/${id}`);
  return await result.json();
}

export async function moveColumn(id: string, index: number) {
  await requests.patch(`column/${id}/move`, { index });
}

export async function archiveAllCards(
  id: string,
): Promise<{ cards: CardDTO[] }> {
  const result = await requests.patch(`column/${id}/archiveCards`);
  return await result.json();
}
