import React from 'react';
import LinkButton from '../../controls/LinkButton/LinkButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import Button from '../../controls/Button/Button';
import HelpDialog from '../../partials/Legal/HelpDialog';

interface Props extends WithTranslation {
  onOpenHelpDialog: () => void;
}

class LegalMenu extends React.Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <>
        <li className="hidden-mobile">
          <Button
            className="ghost-button"
            id="shortcuts"
            onClick={this.props.onOpenHelpDialog}
          >
            <span className="far fa-keyboard icon"></span>
            <span className="text">{t('Shortcuts')}</span>
          </Button>
        </li>
        <li>
          <LinkButton
            to="/how-to"
            target="_blank"
            isRoute={false}
            className="ghost-button"
            id="howTo"
          >
            <span className="far fa-external-link icon"></span>
            <span className="text">{t('howTo')}</span>
          </LinkButton>
        </li>
        <li>
          <LinkButton
            to={`${process.env.REACT_APP_DOCS_BASE_URL}/terms-of-service`}
            target="_blank"
            isRoute={false}
            className="ghost-button"
            id="termsContextMenuButton"
          >
            <span className="far fa-external-link icon"></span>
            <span className="text">{t('termsOfService')}</span>
          </LinkButton>
        </li>
        <li>
          <LinkButton
            to={`${process.env.REACT_APP_DOCS_BASE_URL}/privacy-policy`}
            target="_blank"
            isRoute={false}
            className="ghost-button"
            id="privacyContextMenuButton"
          >
            <span className="far fa-external-link icon"></span>
            <span className="text">{t('privacyPolicy')}</span>
          </LinkButton>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('legalMenu')(LegalMenu);
