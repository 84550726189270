import React, { Component } from 'react';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Button from '../../../controls/Button/Button';

interface Props extends WithTranslation {
  uxStep: number;
  onNext: (boardName: string) => void;
  onPrev: () => void;
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  organizingWith: 'justMe' | 'team' | undefined;
  goal: 'personal' | 'work' | undefined;
  boardName: string;
}

interface State {
  boardName: string;
}

class StepBoard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      boardName: this.props.boardName ? this.props.boardName : '',
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ boardName: event.target.value });
  };

  handleNext = () => {
    const { onNext } = this.props;
    onNext(this.state.boardName);
  };

  renderHeading = () => {
    const { t, familiarity, organizingWith, goal } = this.props;

    if (organizingWith === 'justMe' && goal === 'personal') {
      if (familiarity === 'exploring') {
        return t('step3.personal.exploring');
      } else if (familiarity === 'usedBefore') {
        return t('step3.personal.usedBefore');
      } else if (familiarity === 'expert') {
        return t('step3.personal.expert');
      }
    } else if (organizingWith === 'team' && goal === 'personal') {
      if (familiarity === 'exploring') {
        return t('step3.teamPersonal.exploring');
      } else if (familiarity === 'usedBefore') {
        return t('step3.teamPersonal.usedBefore');
      } else if (familiarity === 'expert') {
        return t('step3.teamPersonal.expert');
      }
    } else if (organizingWith === 'justMe' && goal === 'work') {
      if (familiarity === 'exploring') {
        return t('step3.work.exploring');
      } else if (familiarity === 'usedBefore') {
        return t('step3.work.usedBefore');
      } else if (familiarity === 'expert') {
        return t('step3.work.expert');
      }
    } else if (organizingWith === 'team' && goal === 'work') {
      if (familiarity === 'exploring') {
        return t('step3.teamWork.exploring');
      } else if (familiarity === 'usedBefore') {
        return t('step3.teamWork.usedBefore');
      } else if (familiarity === 'expert') {
        return t('step3.teamWork.expert');
      }
    }
    return t('step3.headingDefault');
  };

  render() {
    const { t, onPrev } = this.props;
    const { boardName } = this.state;

    return (
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-columns"></span>
        </div>
        <div className="column large text-left flex-v-center">
          <div>
            <h2>{t('step3.title', { count: this.props.uxStep })}</h2>
            <div className="scrollable-content flex-v-center">
              <div>
                <p>{this.renderHeading()}</p>
                <input
                  type="text"
                  value={boardName}
                  onChange={this.handleInputChange}
                  placeholder={t('step3.placeholder')}
                  maxLength={50}
                />
              </div>
            </div>
            <div className="button-container">
              <Button
                className="primary-button"
                disabled={!boardName}
                onClick={this.handleNext}
              >
                {t('step3.next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('stepBoard')(StepBoard);
