import { OnboardDTO } from '../api/dtos/User';

type TOnboarding = {
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  organizingWith: 'justMe' | 'team' | undefined;
  peopleCount: number;
  goal: 'personal' | 'work' | undefined;
  boardName: string;
  columns: string[];
  tasks: string[];
  emails: string[];
};

export function normalizeOnboardingData(onboarding: OnboardDTO): TOnboarding {
  return {
    familiarity:
      onboarding.question1 === 'a'
        ? 'exploring'
        : onboarding.question1 === 'b'
        ? 'usedBefore'
        : onboarding.question1 === 'c'
        ? 'expert'
        : undefined,
    organizingWith:
      onboarding.question2 === 'a'
        ? 'justMe'
        : onboarding.question2 === 'b'
        ? 'team'
        : undefined,
    goal:
      onboarding.question3 === 'a'
        ? 'personal'
        : onboarding.question3 === 'b'
        ? 'work'
        : undefined,
    peopleCount: onboarding.teamMemberCount || 2,
    boardName: onboarding.boardName || '',
    columns: onboarding.columnNames || [],
    tasks: onboarding.cardNames || [],
    emails: onboarding.teamMemberEmails || [],
  };
}
