import { Component } from 'react';
import Accordion from '../../controls/Accordion/Accordion';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { TeamDTO } from '../../../common/api/dtos/Team';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import { deleteTeam } from '../../../common/api/endpoints/team';
import { Trans, WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  data: TeamDTO | null;
  fetchTeam: () => Promise<void>;
  status: TRequestStatus;
  history: any;
}

interface State {
  deleteStatus: TRequestStatus;
  canceled: string;
}
export class TeamPlan extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      deleteStatus: 'idle',
      canceled: this.props.data?.subscription.cancelAt || '',
    };
  }

  handleSubmit = async () => {
    if (this.props.data) {
      try {
        this.setState({
          deleteStatus: 'loading',
        });

        await deleteTeam(this.props.data?.id);

        setTimeout(() => {
          this.props.fetchTeam();

          this.setState({
            deleteStatus: 'success',
            canceled: 'canceled',
          });
        }, 300);
      } catch (err) {
        this.setState({
          deleteStatus: 'error',
        });
        console.error(err);
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="flex-row fill">
          <div className="column py-0">
            <Accordion
              accordionSlug="plan-and-billing"
              isOpen={true}
              iconClasses="far fa-coins icon"
              title={t('planBillingTitle')}
              subheading={t('manageSubscriptionSubheading')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
              loading={this.props.status === 'loading'}
              history={this.props.history}
            >
              {this.props.data?.subscription.deleteAt ? (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component vertical">
                    <li>
                      <span>{t('currentPlanStatusText')}</span>
                    </li>
                    <li>
                      <ul className="control-list-component">
                        <li>
                          <span className="badge-text accent-blue">
                            <span className="text-sm">{t('teamText')}</span>
                          </span>
                        </li>
                        <li>
                          <span className="flag-text accent-red">
                            <span className="text-sm">{t('expiredText')}</span>
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="pt-xs">
                      <div className="flex-row">
                        <div className="column medium">
                          <span className="fas accent-text-red fa-exclamation-circle"></span>
                          <span>
                            <Trans
                              i18nKey="teamPlan:teamScheduledForDeletionText"
                              values={{
                                deletionDate: dayjsHelper(
                                  this.props.data?.subscription.deleteAt,
                                ).format('MMMM Do, YYYY'),
                              }}
                              components={{ strong: <strong /> }}
                            />
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="pt-xs">
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/team/${this.props.data?.id}/checkout`}
                      >
                        <button
                          className="secondary-button"
                          disabled={!Boolean(this.props.data?.owned)}
                        >
                          <span className="text">
                            {t('reactivateSubscriptionText')}
                          </span>
                          <span className="far fa-external-link icon"></span>
                        </button>
                      </form>
                    </li>
                  </ul>
                </div>
              ) : this.state.canceled ||
                this.props.data?.subscription.cancelAt ? (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component vertical">
                    <li>
                      <span>{t('currentPlanStatusText')}</span>
                    </li>
                    <li>
                      <ul className="control-list-component">
                        <li>
                          <span className="badge-text accent-blue">
                            <span className="text-sm">{t('teamText')}</span>
                          </span>
                        </li>
                        <li>
                          <span className="flag-text accent-yellow">
                            <span className="text-sm">
                              {t('cancelledText')}
                            </span>
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="pt-xs">
                      <div className="flex-row">
                        <div className="column medium">
                          <span className="fas accent-text-yellow fa-info-circle"></span>
                          <span>
                            <Trans
                              i18nKey="teamPlan:planWillExpireText"
                              values={{
                                expirationDate: dayjsHelper(
                                  this.props.data?.subscription.cancelAt,
                                ).format('MMMM Do, YYYY'),
                              }}
                              components={{ strong: <strong /> }}
                            />
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/team/${this.props.data?.id}/changeBilling`}
                      >
                        <button
                          className="secondary-button"
                          disabled={!Boolean(this.props.data?.owned)}
                        >
                          <span className="text">
                            {t('manageSubscriptionText')}
                          </span>
                          <span className="far fa-external-link icon"></span>
                        </button>
                      </form>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component vertical">
                    <li>
                      <span>{t('currentPlanStatusText')}</span>
                    </li>
                    <li>
                      <ul className="control-list-component">
                        <li>
                          <span className="badge-text accent-blue">
                            <span className="text-sm">{t('teamText')}</span>
                          </span>
                        </li>
                        <li>
                          <span className="flag-text accent-green">
                            <span className="text-sm">{t('activeText')}</span>
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="pt-xs">
                      <div className="flex-row">
                        <div className="column medium">
                          <span className="fas accent-text-green fa-info-circle"></span>{' '}
                          <span>{t('manageBoardPlanText')}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/team/${this.props.data?.id}/changeBilling`}
                      >
                        <button
                          className="secondary-button"
                          disabled={!Boolean(this.props.data?.owned)}
                        >
                          <span className="text">
                            {t('manageSubscriptionText')}
                          </span>
                          <span className="far fa-external-link icon"></span>
                        </button>
                      </form>
                    </li>
                  </ul>
                </div>
              )}
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('teamPlan')(TeamPlan);
