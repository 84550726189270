import { InviteeDTO, MemberDTO } from '../dtos/Member';
import requests from './baseRequest';

export async function searchBoardUsers(
  boardId: string,
  query: string,
): Promise<any> {
  const result = await requests.get(
    `board/${boardId}/inviteSearch?value=${query}`,
  );
  if (result.status === 204) {
    return { isValidHandle: true };
  }
  return await result.json();
}

export async function inviteMemberToBoard(
  boardId: string,
  teamUserId: string,
): Promise<InviteeDTO> {
  const result = await requests.post(`board/${boardId}/invite`, {
    teamUserId,
  });
  return await result.json();
}

export async function updateMemberRole(
  boardUserId: string,
  role: string,
): Promise<MemberDTO> {
  const result = await requests.patch(`boardUser/${boardUserId}`, { role });
  return await result.json();
}

export async function removeMemberFromBoard(
  boardUserId: string,
): Promise<void> {
  await requests.delete(`boardUser/${boardUserId}`);
}

export async function leaveBoard(boardId: string): Promise<void> {
  await requests.patch(`board/${boardId}/leave`);
}
