import React from 'react';
import Button from '../../controls/Button/Button';
import { UserDTO } from '../../../common/api/dtos/User';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import LinkButton from '../../controls/LinkButton/LinkButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  handleLogout: () => void;
  loggedUser?: UserDTO;
}

class UserMenu extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <li>
          <div className="flex-row tight squeeze">
            <div className="column flex-v-center">
              <LinkButton
                to={'/account/general/avatar'}
                className="image-link"
              >
                <Thumbnail
                  classes="thumbnail size-32"
                  avatarData={this.props.loggedUser?.avatar}
                  title={this.props.loggedUser?.name}
                />
              </LinkButton>
            </div>
            <div className="column flex-v-center">
              <span className="text">
                {this.props.loggedUser?.name} <br />
              </span>
            </div>
          </div>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <LinkButton
            to="/account"
            className="ghost-button"
            id="accountContextMenuButton"
            activeClassName="active disabled"
          >
            <span className="icon">
              <span className="far fa-user"></span>
            </span>
            <span className="text">{t('account')}</span>
          </LinkButton>
        </li>
        <li>
          <LinkButton
            to="/settings"
            className="ghost-button"
            id="settingsContextMenuButton"
            activeClassName="active disabled"
          >
            <span className="icon">
              <span className="fal fa-cog"></span>
            </span>
            <span className="text">{t('appSettings')}</span>
          </LinkButton>
        </li>
        <li>
          <Button
            className="ghost-button"
            onClick={() => this.props.handleLogout()}
            id="logOutContextMenuButton"
          >
            <span className="icon">
              <span className="fas fa-power-off"></span>
            </span>
            <span className="text">{t('logOut')}</span>
          </Button>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('userMenu')(UserMenu);
