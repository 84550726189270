import { RouteComponentProps } from 'react-router-dom';
import { createSlug } from './createSlug';

// (dragos): I didn't know how else to get the type for the history and location
// from react-router-dom, so I had to do it this way.
interface Props extends RouteComponentProps {}

export function addCardToUrl(
  props: Props,
  card: { number: number; id: string; title: string },
  cardPrefix: string,
) {
  const { pathname } = props.history.location;
  const cardId = pathname.indexOf('/view/');

  let urlBase: string;
  if (cardId === -1) {
    urlBase = pathname.replace('/view', '');
  } else {
    urlBase = pathname.substring(0, cardId);
  }

  const newUrl = `${urlBase}/view/card/${card.id}/${createSlug(
    cardPrefix.toUpperCase() + '-' + card.number + '-' + card.title,
  )}`;
  props.history.push(newUrl);
}

export function removeCardFromUrl(props: Props, boardName: string) {
  const pathString = props.location.pathname.includes('board-archived-cards')
    ? '/view/card/'
    : '/card/';
  const { pathname } = props.history.location;
  const cardId = pathname.indexOf(pathString);
  let urlBase: string;

  if (cardId === -1) {
    urlBase = pathname;
  } else {
    urlBase = pathname.substring(0, cardId);
  }

  const newURL = props.location.pathname.includes('board-archived-cards')
    ? `${urlBase}`
    : `${urlBase}/${createSlug(boardName || '')}`;
  props.history.push(newURL);
}
