import { ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import {
  $createLineBreakNode,
  $createParagraphNode,
  LineBreakNode,
  ParagraphNode,
} from 'lexical';
import {
  TextMatchTransformer,
  TextFormatTransformer,
  ElementTransformer,
} from '@lexical/markdown/MarkdownTransformers';
import { TRANSFORMERS } from '@lexical/markdown';

/**
 * LEXICAL MARKDOWN PACKAGE LINK: https://lexical.dev/docs/packages/lexical-markdown
 * transformers are used for markdown conversion
 * we need to write custom transformers to overwrite the ones used by lexical's markdown package
 */

// trasformers imported from lexical will remove new lines on markdown conversion
// force lexical to add a paragraph node when there is an empty line
export const LINE_BREAK_FIX: ElementTransformer = {
  dependencies: [ParagraphNode],
  export: (node) => {
    return null;
  },
  regExp: /^$/,
  replace: (parentNode, children, _, isImport) => {
    if (isImport && children.length === 1) {
      children[0].replace($createParagraphNode());
    }
  },
  type: 'element',
};

export const TEXT_ALIGNMENT: TextMatchTransformer = {
  dependencies: [ParagraphNode, HeadingNode, ListNode],
  export: (node: any, exportChildren, exportFormat) => {
    const serializedNode = exportFormat(node, node.getTextContent(node));
    const nodeParent = node.getParent();

    if (node.getType() === 'linebreak') {
      return null;
    }

    switch (nodeParent.__format) {
      case 1:
        return `[align=1]${serializedNode}`;
      case 2:
        return `[align=2]${serializedNode}`;
      case 3:
        return `[align=3]${serializedNode}`;
      case 4:
        return `[align=4]${serializedNode}`;
      default:
        return serializedNode;
    }
  },
  importRegExp: /^\[align=(\d+)\]/,
  regExp: /^\[align=(\d+)\]/,
  replace: (textNode) => {
    const regex = /^\[align=(\d+)\]/;
    const match = textNode.getTextContent().match(regex);
    const alignment = match ? Number(match[1]) : null;
    const parentNode = textNode.getParent();
    textNode.setTextContent(
      `${textNode.getTextContent().replace(/^\[align=(\d+)\]/, '')}`,
    );

    switch (alignment) {
      case 1:
        parentNode?.setFormat('left');
        break;
      case 2:
        parentNode?.setFormat('center');
        break;
      case 3:
        parentNode?.setFormat('right');
        break;
      case 4:
        parentNode?.setFormat('justify');
        break;
    }
  },
  trigger: '',
  type: 'text-match',
};

export const UNDERLINE: TextFormatTransformer = {
  format: ['underline'],
  intraword: false,
  tag: '_',
  type: 'text-format',
};

// trasformers imported from lexical will remove line breaks on markdown conversion
// force lexical to create a line break node
export const BR: TextMatchTransformer = {
  dependencies: [LineBreakNode],
  export: (node) => {
    return null;
  },
  importRegExp: /<br\s?\/?>/,
  regExp: /<br\s?\/?>/,
  replace: (textNode) => {
    const parentNode = textNode.getParent();
    if (parentNode && parentNode.getChildrenSize() === 1) {
      textNode.remove();
    } else {
      textNode.replace($createLineBreakNode());
    }
  },
  trigger: '',
  type: 'text-match',
};

// create a new array and add the custom transformers
// this will be used with convert functions
export const CUSTOM_TRANSFORMERS = [
  ...TRANSFORMERS,
  TEXT_ALIGNMENT,
  UNDERLINE,
  LINE_BREAK_FIX,
  BR,
];
