import React from 'react';
import { ErrorNotificationPayload } from '../helpers/errorNotificationPayload';
import { SuccessNotificationPayload } from '../helpers/successNotificationPayload';
import { InfoNotificationPayload } from '../helpers/infoNotificationPayload';
import { UserAccountSettingsDTO, UserDTO } from '../api/dtos/User';
import { IClientData } from '../interfaces/ClientData';

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface AccountSettings extends UserAccountSettingsDTO {}
export interface LocalSettings extends Nullable<UserAccountSettingsDTO> {}

export interface LoggedUser extends UserDTO {
  /**
   * Properties which have a `null` value are not present in the local storage.
   */
  localSettings: LocalSettings;

  setAccountSettings: (settings: Partial<AccountSettings>) => Promise<void>;
  setLocalSettings: (settings: Partial<LocalSettings>) => void;
}

export type NotificationMessage =
  | SuccessNotificationPayload
  | InfoNotificationPayload
  | ErrorNotificationPayload;

export interface IAppContext {
  color?: string;
  highContrast?: boolean;
  theme?: string;
  showPatterns?: boolean;
  pattern?: string;
  hour?: string;
  language?: string;
  loggedUser?: LoggedUser;
  appSettings: UserAccountSettingsDTO;
  notifications: {
    messages: NotificationMessage[];
    setMessages?: (
      messages: NotificationMessage | NotificationMessage[],
    ) => void;
  };
  setLoggedUser?: (user: UserDTO) => void;
  directionalButtonId?: string;
  setDirectionalButtonId?: (id: string) => void;
  updateLoggedUser?: (newUserData: Partial<UserDTO>) => void;
  setIsDragging?: (value: boolean) => void;
  updateClientData?: (clientData: Partial<IClientData>) => void;
}

const AppContext = React.createContext<IAppContext>({
  notifications: {
    messages: [],
  },
  appSettings: computeAppSettings(),
  updateLoggedUser: (newUserData: Partial<UserDTO>) => {
    (AppContext as any).current.setLoggedUser?.((prevLoggedUser: UserDTO) => ({
      ...prevLoggedUser,
      ...newUserData,
    }));
  },
});

export default AppContext;

/**
 * Create a new context object with updated account and app settings.
 */
export function updateAccountSettings(
  ctx: IAppContext,
  settings: Partial<UserAccountSettingsDTO>,
): IAppContext {
  if (!ctx.loggedUser) {
    return ctx;
  }

  const loggedUser = {
    ...ctx.loggedUser,
    accountSettings: {
      ...ctx.loggedUser.accountSettings,
      ...settings,
    },
  };

  return {
    ...ctx,
    loggedUser,
    appSettings: computeAppSettings(loggedUser),
  };
}

/**
 * Create a new context object with updated local and app settings, as well as updating the local storage.
 */
export function updateLocalSettings(
  ctx: IAppContext,
  settings: Partial<Nullable<UserAccountSettingsDTO>>,
): IAppContext {
  if (!ctx.loggedUser) {
    return ctx;
  }
  const userId = ctx.loggedUser.id;

  // iterate through all the properties of the settings object and either store or remove them from the local storage
  for (const key in settings) {
    if (Object.prototype.hasOwnProperty.call(settings, key)) {
      const value = (settings as Record<string, unknown>)[key];

      if (value === null) {
        localStorage.removeItem(userId + '-' + key);
      } else {
        if (typeof value === 'object') {
          localStorage.setItem(userId + '-experiments', JSON.stringify(value));
        } else {
          localStorage.setItem(userId + '-' + key, String(value));
        }
      }
    }
  }

  const loggedUser = {
    ...ctx.loggedUser,
    localSettings: {
      ...ctx.loggedUser.localSettings,
      ...settings,
    },
  };

  return {
    ...ctx,
    loggedUser,
    appSettings: computeAppSettings(loggedUser),
  };
}

/**
 * Compute the local settings based on the provided user id and the content in the local storage.
 */

function getDefaultExperiments() {
  return {
    imageBoardThumbs: false,
    detachableFlyout: false,
    showTranslucency: true,
  };
}

export function computeLocalSettings(
  userId: string,
): Nullable<UserAccountSettingsDTO> {
  const experiments =
    mapNonNull(localStorage.getItem(userId + '-experiments'), (v) => {
      const userExperiments = JSON.parse(v);
      return { ...getDefaultExperiments(), ...(userExperiments[userId] || {}) };
    }) || getDefaultExperiments();

  return {
    language: localStorage.getItem(userId + '-language'),
    timeFormat: Number(localStorage.getItem(userId + '-timeFormat')),
    theme: localStorage.getItem(userId + '-theme'),
    highContrast: mapNonNull(
      localStorage.getItem(userId + '-highContrast'),
      (v) => v === 'true',
    ),
    showPatterns: null,
    reducedMotion: mapNonNull(
      localStorage.getItem(userId + '-reducedMotion'),
      (v) => v === 'true',
    ),
    colorBlindHelpers: mapNonNull(
      localStorage.getItem(userId + '-colorBlindHelpers'),
      (v) => v === 'true',
    ),
    experiments: experiments,
  };
}

/**
 * Compute the application settings based on the existence of a user, the settings stored
 * locally on the device and the settings stored on the server.
 */
export function computeAppSettings(
  loggedUser?: LoggedUser,
): UserAccountSettingsDTO {
  const defaultSettings = {
    theme: 'light',
    highContrast: false,
    showPatterns: false,
    reducedMotion: false,
    colorBlindHelpers: false,
    timeFormat: 12,
    language: 'en_US',
    experiments: getDefaultExperiments(),
  };

  // A user is needed in order for account or local settings to exist. As
  // such, in case there is no user we return the default settings.
  if (!loggedUser) {
    return defaultSettings;
  }

  const userId = loggedUser.id;
  const localSettings = computeLocalSettings(userId);
  const accountSettings = loggedUser.accountSettings;

  // Return app settings while prioritizing the local settings over the account settings.
  return {
    theme: localSettings.theme ?? accountSettings.theme,
    highContrast: localSettings.highContrast ?? accountSettings.highContrast,
    showPatterns: false,
    reducedMotion: localSettings.reducedMotion ?? accountSettings.reducedMotion,
    colorBlindHelpers:
      localSettings.colorBlindHelpers ??
      accountSettings.colorBlindHelpers ??
      false,
    language: localSettings.language ?? accountSettings.language ?? 'en_US',
    timeFormat: localSettings.timeFormat ?? accountSettings.timeFormat ?? '12',
  };
}

function mapNonNull<T, P>(value: T | null, fn: (val: T) => P): P | null {
  if (value === null) {
    return null;
  }
  return fn(value);
}
