export class ErrorNotificationPayload {
  id: string;
  code: number | string;
  path: string | null;
  validationCode?: string;
  validationMessage?: string;
  message?: string;

  constructor(
    code: number | string,
    path: string | null,
    validationCode?: string,
    validationMessage?: string,
    message?: string,
  ) {
    // TODO: need unique id for the React.Component key prop, Date might not be enough if users spam errors really fast
    this.id = crypto.randomUUID();
    this.code = code;
    this.path = path;
    this.validationCode = validationCode;
    this.validationMessage = validationMessage;
    this.message = message;
  }
}
