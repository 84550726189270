import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoginForm from '../../../forms/LoginForm/LoginForm';
import LegalFooter from '../../../partials/Legal/LegalFooter';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';

export interface Props extends RouteComponentProps, WithTranslation {
  unlockApp: () => void;
}

class Login extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <div className="login-component will-transition">
        <div className="login-container fill">
          <div className="flex-row fill">
            <div className="column">
              <div className="special-card p-large fade-in-1">
                <div
                  className="oobe-content px-sm py-md"
                  style={{
                    minHeight: '0',
                  }}
                >
                  <div>
                    <img
                      className="thumbnail large radius px-sm"
                      style={{
                        borderRadius: '5px',
                        background:
                          'radial-gradient(circle at bottom right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 85%)',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      }}
                      src={'/assets/borddo_onboarding.svg'}
                      alt="Borddo"
                    />
                  </div>
                  <br />
                  <LoginForm
                    unlockApp={this.props.unlockApp}
                    history={this.props.history}
                    location={this.props.location}
                  />
                  <br />
                  <LinkButton
                    to="/forgot-password"
                    className="link-button"
                  >
                    <span className="text">{t('forgotPassword')}</span>
                  </LinkButton>
                  <br />
                  <br />
                  <LinkButton
                    to="/registration"
                    className="link-button"
                  >
                    <span className="text">{t('noAccount')}</span>
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row fill">
            <div className="column">
              <LegalFooter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('login')(Login);
