import React, { Component } from 'react';
import Accordion from '../../controls/Accordion/Accordion';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import AppContext from '../../../common/contexts/AppContext';
import { TeamDTO } from '../../../common/api/dtos/Team';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import { listTeams } from '../../../common/api/endpoints/team'; // Import the listTeams function

interface Props extends WithTranslation {
  history: any;
}

interface State {
  teams: TeamDTO[];
  status: TRequestStatus;
  serverErrors: string[];
  loadingMore: boolean;
  nextPage: string | null;
}

class Subscriptions extends Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      teams: [],
      status: 'loading',
      serverErrors: [],
      loadingMore: false,
      nextPage: null,
    };
  }

  componentDidMount() {
    this.fetchAllTeams();

    window.addEventListener('scroll', this.handleScroll); // Add scroll listener
    window.addEventListener('resize', this.handleResize); // Add resize listener

    // Check if we need to load more teams on mount
    this.checkIfMoreContentNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up scroll listener
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  }

  checkIfMoreContentNeeded = () => {
    if (
      document.documentElement.scrollHeight - 100 <= window.innerHeight &&
      this.state.nextPage
    ) {
      this.fetchMoreTeams();
    }
  };

  handleResize = () => {
    this.checkIfMoreContentNeeded(); // Trigger content check on resize
  };

  handleScroll = () => {
    const { loadingMore, nextPage } = this.state;

    if (loadingMore || !nextPage) return;

    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      this.fetchMoreTeams();
    }
  };

  fetchMoreTeams = async () => {
    const { nextPage, teams } = this.state;

    this.setState({ loadingMore: true });

    try {
      const { teams: newTeams, nextPage: newNextPage } = await listTeams(
        1,
        nextPage,
      );

      this.setState(
        (prevState) => ({
          teams: [...prevState.teams, ...newTeams],
          loadingMore: false,
          nextPage: newNextPage,
        }),
        () => {
          // Check if more content is needed after updating state
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        loadingMore: false,
        serverErrors: [err as string],
      });
    }
  };

  fetchAllTeams = async () => {
    this.setState({ status: 'loading' });
    try {
      const { teams, nextPage } = await listTeams(1);

      this.setState(
        {
          teams,
          status: 'success',
          nextPage,
        },
        () => {
          // Check if more content is needed after the first fetch
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        status: 'error',
        serverErrors: [err as string],
      });
    }
  };

  renderPlan(plan: 'free' | 'pro') {
    switch (plan) {
      case 'pro':
        return (
          <>
            <span className="badge-text accent-yellow">
              <span className="black-text text-sm">PRO</span>
            </span>
          </>
        );
      case 'free':
      default:
        return (
          <>
            <span className="badge-text accent-green">
              <span className="black-text text-sm">FREE</span>
            </span>
          </>
        );
    }
  }

  render() {
    const { t } = this.props;
    const { teams, status, serverErrors, loadingMore } = this.state;

    let subscribed =
      !!this.context.loggedUser!.subscription &&
      (this.context.loggedUser!.subscription.deleteAt == null ||
        new Date(this.context.loggedUser!.subscription.deleteAt) > new Date());

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-2xs">
            <Accordion
              accordionSlug="personal-plan"
              isOpen={true}
              iconClasses="fal fa-stars icon"
              title={t('personalPlan.title')}
              subheading={t('personalPlan.subheading')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
              loading={status === 'loading'}
              history={this.props.history}
            >
              <div className="accordion-row py-sm">
                <ul className="control-list-component vertical">
                  <li>{t('personalPlan.boardsPlanLabel')}</li>
                  <li>{this.renderPlan(subscribed ? 'pro' : 'free')}</li>
                  <li className="pt-xs">
                    {!subscribed ? (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/upgrade`}
                      >
                        <button className="secondary-button">
                          <span className="text">
                            {t('personalPlan.upgradeButton')}
                          </span>
                          <span className="far fa-external-link icon"></span>
                        </button>
                      </form>
                    ) : (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/changeBilling`}
                      >
                        <button className="secondary-button">
                          <span className="text">
                            {t('personalPlan.manageSubscriptionButton')}
                          </span>
                          <span className="far fa-external-link icon"></span>
                        </button>
                      </form>
                    )}
                  </li>
                </ul>
              </div>
            </Accordion>
            <Accordion
              accordionSlug="team-plans"
              isOpen={true}
              iconClasses="fal fa-users icon"
              title={t('teamPlans.title')}
              subheading={t('teamPlans.subheading')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
              loading={status === 'loading'}
              history={this.props.history}
            >
              {!teams.filter((team) => team.owned).length && (
                <div className="accordion-row py-sm">
                  <p className="faint-text">{t('teamPlans.noTeamsMessage')}</p>
                </div>
              )}

              {teams
                .filter((team) => team.owned)
                .map((team, index) => (
                  <div
                    key={index}
                    className="accordion-row py-sm"
                  >
                    <ul className="control-list-component vertical">
                      <li>
                        <span>
                          {t('teamPlans.planStatusLabel', {
                            teamName: team.name,
                          })}
                        </span>
                      </li>
                      <li>
                        <ul className="control-list-component">
                          <li>
                            <span className="badge-text accent-blue">
                              <span className="text-sm">
                                {t('teamPlans.teamBadge')}
                              </span>
                            </span>
                          </li>
                          <li>
                            {team.subscription.deleteAt ? (
                              <span className="flag-text accent-red">
                                <span className="text-sm">
                                  {t('teamPlans.expiredBadge')}
                                </span>
                              </span>
                            ) : team.subscription.cancelAt ? (
                              <span className="flag-text accent-yellow">
                                <span className="text-sm">
                                  {t('teamPlans.cancelledBadge')}
                                </span>
                              </span>
                            ) : (
                              <span className="flag-text accent-green">
                                <span className="text-sm">
                                  {t('teamPlans.activeBadge')}
                                </span>
                              </span>
                            )}
                          </li>
                        </ul>
                      </li>
                      <li className="pt-xs">
                        <div className="flex-row">
                          <div className="column medium">
                            {team.subscription.deleteAt ? (
                              <>
                                <span className="fas accent-text-red fa-exclamation-circle"></span>
                                <span>
                                  {t('teamPlans.expirationWarning', {
                                    deleteDate: dayjsHelper(
                                      team.subscription.deleteAt,
                                    ).format('MMMM Do, YYYY'),
                                  })}
                                </span>
                              </>
                            ) : team.subscription.cancelAt ? (
                              <>
                                <span className="fas accent-text-yellow fa-info-circle"></span>
                                <span>
                                  {t('teamPlans.cancellationNotice', {
                                    cancelDate: dayjsHelper(
                                      team.subscription.cancelAt,
                                    ).format('MMMM Do, YYYY'),
                                  })}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="fas accent-text-green fa-info-circle"></span>
                                <span>{t('teamPlans.activePlanNotice')}</span>
                              </>
                            )}
                          </div>
                        </div>
                      </li>
                      <li>
                        <form
                          target="_blank"
                          method="POST"
                          action={`${process.env.REACT_APP_API_BASE_URL}/team/${team.id}/changeBilling`}
                        >
                          <button className="secondary-button">
                            <span className="text">
                              {t('teamPlans.manageSubscriptionButton')}
                            </span>
                            <span className="far fa-external-link icon"></span>
                          </button>
                        </form>
                      </li>
                    </ul>
                  </div>
                ))}
            </Accordion>
          </div>
        </div>
        {/* Infinite Scroll Loader */}
        {loadingMore && <div className="loader"></div>}
      </>
    );
  }
}

export default withStyledTranslation('subscriptions')(Subscriptions);
Subscriptions.contextType = AppContext;
