import React from 'react';
import {
  DraggableProvided,
  DraggableStateSnapshot,
} from '../../../../../vendors/wavemyth/react-beautiful-dnd/src';

interface Props {
  className?: string;

  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  attributes?: any;
  listeners?: any;
}

interface State {}

export default class BoardColumnHeader extends React.Component<Props, State> {
  render() {
    return (
      <div
        className="flex-row list-drag-head no-reflow"
        {...this.props.provided?.dragHandleProps}
        {...this.props.attributes}
        {...this.props.listeners}
      >
        {this.props.children}
      </div>
    );
  }
}
