import React, { Component } from 'react';
import AppContext from '../../../common/contexts/AppContext';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Toolbar from '../../partials/Toolbar/Toolbar';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import { withTransitionEvent } from '../../partials/Transition/TransitionEvent';
import {
  getQuickAccess,
  listBoards,
} from '../../../common/api/endpoints/board';
import { IBoardListQuery } from '../../../common/interfaces/BoardListQuery';
import { BoardItemDTO } from '../../../common/api/dtos/Board';
import BoardListingCard from '../../partials/board/BoardListing/BoardListingCard';
import ErrorList from '../../error/ErrorList/ErrorList';
import LinkButton from '../../controls/LinkButton/LinkButton';
import { NavLink } from 'react-router-dom';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import NewMenu from '../../menus/New/NewMenu';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import QuickAccessDroppable from '../../partials/QuickAccess/QuickAccessDroppable';
import PWAInstall from '../../partials/PWA/PWAInstall';
import { listTeams } from '../../../common/api/endpoints/team';
import { TeamDTO } from '../../../common/api/dtos/Team';
import Button from '../../controls/Button/Button';
import ContentLoader from '../../partials/ContentLoader/ContentLoader';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import BoardArchive from '../Board/BoardArchive/BoardArchive';
import { cn } from '../../../common/helpers/cn';
import WelcomeDialog from '../../partials/Onboarding/WelcomeDialog';

export interface Props extends WithTranslation {}
interface State {
  status: TRequestStatus;
  statusQuickAccess: TRequestStatus;
  boards: {
    allBoards: BoardItemDTO[];
    favorite: BoardItemDTO[];
  };
  loadingBoards: boolean[];
  quickAccessBoards: BoardItemDTO[];
  teams: TeamDTO[];
  serverErrors: string[];
  reorderQuickAccess: boolean;
  showArchive: {
    personal: boolean;
    teams: { [teamId: string]: boolean };
  };
  page: string;
  loadingMore: boolean;
  nextPage: string | null;
}

class Home extends Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'loading',
      statusQuickAccess: 'loading',
      boards: {
        allBoards: [],
        favorite: [],
      },
      loadingBoards: [],
      quickAccessBoards: [],
      teams: [],
      serverErrors: [],
      reorderQuickAccess: false,
      showArchive: {
        personal: false,
        teams: {},
      },
      page: '',
      loadingMore: false,
      nextPage: null,
    };
  }

  componentDidMount() {
    this.fetchAllBoards();
    this.fetchAllTeams();
    this.fetchQuickAccess();

    window.addEventListener('scroll', this.handleScroll); // Add scroll listener
    window.addEventListener('resize', this.handleResize); // Add resize listener

    // Check if we need to load more teams on mount
    this.checkIfMoreContentNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up scroll listener
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  }

  checkIfMoreContentNeeded = () => {
    if (
      document.documentElement.scrollHeight - 100 <= window.innerHeight &&
      this.state.nextPage
    ) {
      this.fetchMoreTeams();
    }
  };

  handleResize = () => {
    this.checkIfMoreContentNeeded(); // Trigger content check on resize
  };

  handleScroll = () => {
    const { loadingMore, nextPage } = this.state;

    if (loadingMore || !nextPage) return;

    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      this.fetchMoreTeams();
    }

    this.checkIfMoreContentNeeded(); // Optional: ensures the check runs on every scroll
  };

  fetchMoreTeams = async () => {
    const { nextPage, teams } = this.state;

    this.setState({ loadingMore: true });

    try {
      const { teams: newTeams, nextPage: newNextPage } = await listTeams(
        1,
        this.state.nextPage,
      );

      this.setState(
        (prevState) => ({
          teams: [...prevState.teams, ...newTeams],
          loadingMore: false,
          loadingBoards: Array([...prevState.teams, ...newTeams].length).fill(
            true,
          ),
          nextPage: newNextPage,
        }),
        () => {
          // Check if more content is needed after updating state
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        loadingMore: false,
        serverErrors: [err as string],
      });
    }
  };

  fetchAllTeams = async () => {
    this.setState({ status: 'loading' });
    try {
      const { teams, nextPage } = await listTeams(1);

      this.setState(
        {
          teams,
          loadingBoards: Array(teams.length).fill(true),
          nextPage,
        },
        () => {
          // Check if more content is needed after the first fetch
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      console.log(err);
    }
  };

  fetchQuickAccess = async () => {
    try {
      this.setState({ statusQuickAccess: 'loading' });
      const { boards } = await getQuickAccess();
      this.setState({
        quickAccessBoards: boards,
        statusQuickAccess: 'success',
      });
    } catch (err) {
      this.setState({
        statusQuickAccess: 'error',
        serverErrors: [err as string],
      });
    }
  };

  fetchBoards = async (query: IBoardListQuery) => {
    this.setState({ status: 'loading' });
    try {
      const { boards } = await listBoards(query);
      this.setState((prevState) => ({
        status: 'success',
        boards: { ...prevState.boards, [query.name]: boards },
      }));
    } catch (err) {
      this.setState({ status: 'error', serverErrors: [err as string] });
    }
  };

  fetchAllBoards() {
    this.fetchBoards({
      name: 'allBoards',
      params: [{ param: 'archived', value: false }],
    });
    this.fetchBoards({
      name: 'favorite',
      params: [
        { param: 'favorite', value: true },
        { param: 'archived', value: false },
      ],
    });
  }

  renderCreateBoard = (disabled: boolean, team?: TeamDTO) => {
    const { t } = this.props;
    return !disabled ? (
      <LinkButton
        to={team ? `/board-add/${team.id}/${team.name}` : '/board-add'}
        className="card-button"
      >
        <div className="thumbnail medium mr-sm no-bg">
          <span className="fad fa-layer-plus faint-text text-3xl"></span>
        </div>
        <div className="flex-row fill">
          <div className="column py-0">
            <h3 className="primary-title normalcase multiline-ellipsis l1 pr-md">
              {t('createBoard')}
            </h3>
          </div>
        </div>
      </LinkButton>
    ) : (
      <div className="card-button disabled">
        <div className="thumbnail medium mr-sm no-bg">
          <span className="fad fa-layer-plus faint-text text-3xl"></span>
        </div>
        <div className="flex-row fill">
          <div className="column py-0">
            <h3 className="primary-title normalcase multiline-ellipsis l1 pr-md">
              {t('createBoard')}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  renderNoBoards = () => (
    <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
      <div className="card">
        <div className="content py-xs">
          <p>{this.props.t('noBoards')}</p>
        </div>
      </div>
    </div>
  );

  renderUtilityTeamBar = (team: TeamDTO) => {
    const { t } = this.props;
    return (
      <div className="column py-2xs">
        <ul className="control-list-component flex-h-end">
          <li>
            <ContextMenu
              dept={0}
              title={t('teamOptions')}
              triggerContent={<span className="fas fa-ellipsis-h"></span>}
              triggerClassDefault="ghost-button"
              triggerClassActive="secondary-button"
            >
              {(team.role === 'admin' || team.role === 'owner') && (
                <>
                  <li
                    className={cn(
                      this.state.showArchive.teams[team.id] ? 'active' : '',
                    )}
                  >
                    <Button
                      className="ghost-button"
                      onClick={() => this.toggleArchiveView(team.id)}
                    >
                      <span className="fal fa-archive icon"></span>
                      <span className="text">{t('archivedBoards')}</span>
                    </Button>
                  </li>
                  <li>
                    <hr />
                  </li>
                  <li>
                    <NavLink
                      to={`/team/${team.id}/general`}
                      className="ghost-button"
                    >
                      <span className="fal fa-cog icon"></span>
                      <span className="text">{t('settings')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/team/${team.id}/team-members`}
                      className="ghost-button"
                    >
                      <span className="fal fa-users icon"></span>
                      <span className="text">{t('members')}</span>
                      <span className="badge-text">{team.memberCount}</span>
                    </NavLink>
                  </li>
                </>
              )}
              {team.role !== 'owner' && (
                <>
                  <li>
                    <NavLink
                      to={`/account/membership`}
                      className="ghost-button"
                    >
                      <span className="fal fa-id-badge icon"></span>
                      <span className="text">{t('membership')}</span>
                    </NavLink>
                  </li>
                </>
              )}
            </ContextMenu>
          </li>
        </ul>
      </div>
    );
  };

  renderSubscriptionStatus = (team: TeamDTO) => {
    const { t } = this.props;
    if (team.subscription.deleteAt && team.owned) {
      return (
        <div className="flex-row fill">
          <div className="column px-0 pt-xs flex-v-stretch">
            <div className="flex-v-center">
              <span className="fas px-xs accent-text-red fa-exclamation-circle text-2xl"></span>
              <div>
                <p className="text-sm">
                  {t('planExpired', {
                    teamName: team.name,
                    deleteAt: dayjsHelper(team.subscription.deleteAt).format(
                      'MMMM Do, YYYY',
                    ),
                  })}
                  <br />
                  {t('avoidDeletionPrefix')}{' '}
                  <NavLink
                    to={`/team/${team.id}/plan-and-billing`}
                    className={'link-button'}
                  >
                    <span className="text">{t('avoidDeletionMid')}</span>
                  </NavLink>{' '}
                  {t('avoidDeletionSuffix')}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (team.subscription.cancelAt && team.owned) {
      return (
        <div className="flex-row fill">
          <div className="column px-0 pt-xs flex-v-stretch">
            <div className="flex-v-center">
              <span className="fas px-xs accent-text-yellow fa-exclamation-circle text-2xl"></span>
              <div>
                <p className="text-sm">
                  The plan for <strong>{team.name}</strong> expires{' '}
                  <strong>
                    {dayjsHelper(team.subscription.cancelAt).format(
                      'MMMM Do, YYYY',
                    )}
                  </strong>
                  .<br />
                  {t('avoidGracePrefix')}{' '}
                  <NavLink
                    to={`/team/${team.id}/plan-and-billing`}
                    className={'link-button'}
                  >
                    <span className="text">{t('avoidGraceMid')}</span>
                  </NavLink>{' '}
                  {t('avoidGraceSuffix')}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  toggleArchiveView = (teamId?: string | null) => {
    if (teamId === null) {
      this.setState((prevState) => ({
        showArchive: {
          ...prevState.showArchive,
          personal: !prevState.showArchive.personal,
        },
      }));
    } else {
      this.setState((prevState) => ({
        showArchive: {
          ...prevState.showArchive,
          teams: {
            ...prevState.showArchive.teams,
            [teamId ?? '']: !prevState.showArchive.teams[teamId ?? ''],
          },
        },
      }));
    }
  };

  // renderWelcomeDialog for invitees with no boards
  renderWelcomeDialog = () => {
    const { teams, status, boards } = this.state;
    if (
      teams.length !== 0 &&
      status !== 'loading' &&
      teams.every(
        (team) =>
          boards.allBoards.filter((board) => board.teamId === team.id)
            .length === 0,
      )
    ) {
      return <WelcomeDialog noBoards={true} />;
    }
    return null;
  };

  render() {
    const { t } = this.props;
    const {
      boards,
      teams,
      status,
      serverErrors,
      quickAccessBoards,
      showArchive,
      loadingMore,
      nextPage,
    } = this.state;
    const filteredPersonalBoards = boards.allBoards.filter(
      (board) => board.teamId === null,
    );

    return (
      <>
        <Toolbar>
          <div className="form-group">
            <ContextMenu
              dept={0}
              triggerContent={
                <>
                  <span>{t('new')}</span>
                  <span className="fas fa-chevron-circle-down icon"></span>
                </>
              }
              triggerClassDefault="primary-button"
              triggerClassActive="primary-button"
            >
              <NewMenu />
            </ContextMenu>
          </div>
        </Toolbar>
        {this.renderWelcomeDialog()}
        <div className="flex-row fill">
          <div className="column pt-0">
            <div className="flex-row">
              <div className="column small pb-0">
                <div className="flex-row squeeze no-reflow">
                  <div className="column pb-0">
                    <LinkButton
                      to="/account/general/avatar"
                      className="image-link"
                    >
                      <Thumbnail
                        classes="thumbnail size-64"
                        avatarData={this.context.loggedUser!.avatar}
                        title={this.context.loggedUser!.name}
                      />
                    </LinkButton>
                  </div>
                  <div className="column pb-0 small flex-v-center">
                    <div>
                      <small>
                        {t('greeting', { name: this.context.loggedUser!.name })}
                      </small>
                      <br />
                      <small className="faint-text">
                        {t('welcomeMessage')}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column pb-0 large flex-v-center flex-h-end">
                <PWAInstall />
              </div>
            </div>
            <div className="flex-row">
              <div className="column small">
                <div style={{ position: 'sticky', top: '46px' }}>
                  <hr />
                  <div className="flex-row fill flex-v-center flex-h-spread">
                    <div className="column py-xs">
                      <h2 className="primary-title h3 normalcase">
                        <img
                          alt="starred boards icon"
                          className="icon"
                          src={`${process.env.REACT_APP_ICONS_PATH}/starred-boards.svg`}
                        />{' '}
                        <span>{t('quickAccess')}</span>
                      </h2>
                    </div>
                  </div>
                  <div className="flex-row card-list-component fill">
                    {quickAccessBoards.length ? (
                      <div className="column px-xs pt-0 pb-0">
                        <QuickAccessDroppable elements={quickAccessBoards} />
                      </div>
                    ) : (
                      <div className="column flex-v-stretch">
                        <div className="card">
                          <div className="content py-xs">
                            <p>{t('quickAccessNoBoards')}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="column large pt-0">
                <div className="flex-row fill">
                  <div className="column">
                    <hr />
                    <div className="flex-row no-reflow fill flex-v-center flex-h-spread">
                      <div className="column py-2xs">
                        <h2 className="primary-title h3 normalcase">
                          <img
                            alt="boards icon"
                            className="icon"
                            src={`${process.env.REACT_APP_ICONS_PATH}/boards.svg`}
                          />{' '}
                          <span>{t('personal')}</span>
                          <ErrorList errors={serverErrors} />
                        </h2>
                      </div>
                      <div className="column py-2xs">
                        <ul className="control-list-component flex-h-end">
                          <li>
                            <ContextMenu
                              dept={0}
                              title={t('personalOptions')}
                              triggerContent={
                                <span className="fas fa-ellipsis-h"></span>
                              }
                              triggerClassDefault="ghost-button"
                              triggerClassActive="secondary-button"
                            >
                              <li
                                className={cn(
                                  this.state.showArchive.personal
                                    ? 'active'
                                    : '',
                                )}
                              >
                                <Button
                                  className="ghost-button"
                                  onClick={() => this.toggleArchiveView(null)}
                                >
                                  <span className="fal fa-archive icon"></span>
                                  <span className="text">
                                    {t('archivedBoards')}
                                  </span>
                                </Button>
                              </li>
                              <li>
                                <hr />
                              </li>
                              <li>
                                {this.context.loggedUser?.subscription ==
                                null ? (
                                  <LinkButton
                                    to="/account/plans"
                                    className="ghost-button"
                                  >
                                    <span className="fas fa-rocket-launch icon accent-text"></span>
                                    <span className="text">
                                      {t('upgradePlan')}
                                    </span>
                                  </LinkButton>
                                ) : (
                                  <LinkButton
                                    to="/account/plans"
                                    className="ghost-button"
                                  >
                                    <span className="fas fa-rocket-launch icon"></span>
                                    <span className="text">
                                      {t('managePlan')}
                                    </span>
                                  </LinkButton>
                                )}
                              </li>
                            </ContextMenu>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      {status === 'loading' ? (
                        <div className="flex-row card-list-component">
                          <ContentLoader height="98px" />
                        </div>
                      ) : showArchive.personal ? (
                        <BoardArchive />
                      ) : (
                        <div className="flex-row card-list-component fade-in-grow">
                          {filteredPersonalBoards.length !== 0 &&
                            filteredPersonalBoards.map((board) => (
                              <div
                                key={board.id}
                                className="column px-2xs pt-xs pb-0 flex-v-stretch"
                              >
                                <BoardListingCard
                                  thumbnail={board.thumbnail}
                                  id={board.id}
                                  name={board.name}
                                  color={board.color}
                                  favorite={board.favorite}
                                  archived={board.archived}
                                  description={board.description}
                                />
                              </div>
                            ))}
                          <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
                            {this.renderCreateBoard(false)}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {teams.map((team, index) => (
                  <div
                    className="flex-row fill"
                    key={team.id}
                  >
                    <div className="column">
                      <div className="flex-row no-reflow fill flex-v-center flex-h-spread">
                        <div className="column py-2xs">
                          <h2 className="primary-title h3 normalcase">
                            <img
                              alt={t('teamIcon')}
                              className="icon"
                              src={`${process.env.REACT_APP_ICONS_PATH}/boards.svg`}
                            />{' '}
                            <span>{team.name}</span>
                            <ErrorList errors={serverErrors} />
                          </h2>
                        </div>
                        {this.renderUtilityTeamBar(team)}
                      </div>
                      {this.renderSubscriptionStatus(team)}
                      <div>
                        {showArchive.teams[team.id] ? (
                          <BoardArchive teamId={team.id} />
                        ) : !this.state.loadingBoards[index] ? (
                          <div className="flex-row card-list-component">
                            <ContentLoader height="98px" />
                          </div>
                        ) : (
                          <div className="flex-row card-list-component fade-in-grow">
                            {this.state.boards.allBoards
                              .filter((board) => board.teamId === team.id)
                              .map((board) => (
                                <div
                                  key={board.id}
                                  className="column px-2xs pt-xs pb-0 flex-v-stretch"
                                >
                                  <BoardListingCard
                                    thumbnail={board.thumbnail}
                                    id={board.id}
                                    name={board.name}
                                    color={board.color}
                                    favorite={board.favorite}
                                    archived={board.archived}
                                    description={board.description}
                                  />
                                </div>
                              ))}
                            {this.state.loadingBoards[index] &&
                              this.state.boards.allBoards.filter(
                                (board) => board.teamId === team.id,
                              ).length === 0 &&
                              this.renderNoBoards()}
                            {this.state.loadingBoards[index] &&
                              this.state.boards.allBoards.filter(
                                (board) => board.teamId === team.id,
                              ).length >= 0 && (
                                <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
                                  {this.renderCreateBoard(
                                    !(
                                      team.role === 'admin' ||
                                      team.role === 'owner'
                                    ),
                                    team,
                                  )}
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* End of teams.map */}
                {loadingMore && nextPage && <div className="loader"></div>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('home')(withTransitionEvent(Home));
Home.contextType = AppContext;
